import React, { useContext, useEffect, useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress';

import PngIcons from '../../../icons/png.icon';
import CustomTextField from '../../../components/CustomTextField';
import SettingHeader from '../../../components/setting/SettingHeader';
import { PassService, TransactionService , BrandService, CampaignService} from '../../../services';
import { Brand, ColorScheme, PassDesign, Transactions } from '../../../enums';
import { useHistory } from 'react-router-dom';
import { BrandContext } from '../../../context/Brand.context';
import Redemption from './Redemption';
import { SvgIcons } from '../../../icons';
import * as localForage from 'localforage'
import CustomTable from '../../../components/customTable/CustomTable';
import Loader from '../../../components/Loader';
import CustomModal from '../../../components/CustomModal';
import {ClickOutside, utils} from '../../../utils'
import CustomButton from '../../../components/CustomButton';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import CampaignsDropDown from '../../../components/CampaignsDropDown';
import CustomSelect from '../../../components/CustomSelect';
import {getContryCodes} from '../../../utils/Options'

import { useFormik } from 'formik';
import { PassVld } from '../../../validation';
import CustomTableV2 from '../../../components/CustomTableV2';
import { LayoutContext } from '../../../context/Layout.context';
import CustomSearch from '../../../components/CustomSearch';
import CameraScanner from './CameraScanner';
import CustomDrawer from '../../../components/CustomDrawer';

export default function BarcodeScanner(props) {

    const [pass, setPass]                             = useState()
    const [loader, setLoader]                         = useState(false)
    const [loaderMain, setLoaderMain]                 = useState(false)
    const [apiError, setApiError]                     = useState('')
    const [transaction, setTransaction]               = useState([]);
    const [filteredData, setFilteredData]             = useState();
    const [transactionApiData, setTransactionApiData] = useState([]);
    const [result, setResult]                         = useState([]);
    const [resultV2, setResultV2]                     = useState([]);
    const [passCode ,setPassCode]                     = useState();
    const [show, setShow]                             = useState({
        customerModal       : false,
        subscriberDropdown  : false,
        addCustomer         : false,
    })
    const [showDrawer, setShowDrawer] = useState(false);
    const brand                       = useContext(BrandContext);
    const history                     = useHistory()
    
    
    const pushArr = (arr, body) => {
        arr.forEach((v,index) => {
            if(v.transactionType !== Transactions.TransactionTypes.SIGNUP_POINT){
            body.push([
                {
                    value     : v.customerName || '-',
                    align     : 'left',
                    className : ''
                },
                {
                    value     : v.data[v.data.passType].amount,
                    align     : 'left',
                    className : ''
                },
                {
                    value     : new Date(v.created).toLocaleDateString() + '  ' + new Date(v.created).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                    align     : 'left',
                    date      : v.created,
                    className : ''
                },
            ])}
        });

        return body
    }
    
    const pushArrV2 = (arr, body) => {
        arr.forEach((v,index) => {
            if(v.transactionType !== Transactions.TransactionTypes.SIGNUP_POINT){
                body.push({
                    id          : v.data._id,
                    value       : v,
                    CustomerName: v.customerName ? utils.capitalizeAll(v.customerName)                                                                                               : '-',
                    Amount      : v.data[v.data.passType].amount,
                    RedeemAt    : new Date(v.created).toLocaleDateString() + '  ' + new Date(v.created).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                })
            }
        })

        return body
    }

    const getTransaction = async() => {
        const user = await localForage.getItem('user')
        const query = {employerId: user._id, brandId: brand.brandType === Brand.BrandTypes.SUB_BRAND ? brand.parentBrandId : brand._id, delete: false}
        const {error, response} = await TransactionService.GetQueriedTransactions({query})

        let body   = []
        let arr    = []
        let bodyV2 = []
        let arrV2  = []

        if(response){
            
            arrV2 = pushArrV2(response.data, bodyV2)
            setResultV2(arrV2)
            setFilteredData(arrV2)

            setTransactionApiData(response.data)
            arr = pushArr(response.data, body)

            arr.sort(function(a, b) {
                var keyA = new Date(a[2].date).valueOf(),
                    keyB = new Date(b[2].date).valueOf();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });
            setResult(arr)
            setTransaction(arr)
        }
        // setLoaderMain(false)
        return
    }
    
    const onLoad = async () => {
        if(props.location.state && props.location.state.passData.passCode)
        {
            setPassCode(props.location.state.passData.passCode)
        }
        await getTransaction()
    }

    useEffect(onLoad,[]);

    const layout = useContext(LayoutContext)

    React.useEffect(()=>{
        layout.setLayout({edit : false, title: 'Barcode Scanner'})
      },[])

    const handleQrCode = async (e, passCode) => {
        if(e.target.value.length === 8){
            setLoader(true)
            const query = {passCode: e ? e.target.value : passCode, designType: PassDesign.DesignTypes.APPLE}
            const {error, response} = await PassService.GetPassJSON({query})
            setLoader(false)
            if(response)
            {
                if(brand.brandType === Brand.BrandTypes.SUB_BRAND && brand.parentBrandId !== response.data.brandId) return setApiError('you have not access to redeemed this pass')
                if(brand.brandType !== Brand.BrandTypes.SUB_BRAND && brand._id !== response.data.brandId) return setApiError('you have not access to redeemed this pass')
                setPass(response.data)             
                // const path = window.location.pathname.split('/')
                // path.pop()
                // history.push({pathname: path.join('/')+'/redemption', state: response.data})
            }
            error && setApiError(error.message)

        }
    }

    function createData({customerName, amount, redeemedAt}) {
        return { 
            props: {
                name     : 'rowProps',
                class    : '',
                // id       : id,
                checkbox : false
            },
            customerName: {
                label : customerName,
                class : 'capitalize',
            }, 
            amount: {
                label : amount,
                class : '',
            }, 
            redeemedAt: {
                label : redeemedAt,
                class : '',
            }, 
        };
    }   

    const createDateFunc = (time) => {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let date = new Date(time);
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() +' ('+ days[date.getDay()] +')';
    }

    // for(let value of transactionApiData){
    // }

    const transactionData =  transactionApiData.map((value, idx) => createData({
        customerName: value.customerName,
        amount      : value.data[value.data.passType].amount + brand.currency.symbol,
        redeemedAt  : createDateFunc(value.created),
    }))

    const handleSearch = (e) => {
        const result = resultV2.filter(item =>
            item.CustomerName.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredData(result)
    } 


    const goToCameraScanner = () => {
        let path = history.location.pathname.split('/', -1);
        path.pop();
        history.push(path.join('/')+'/camerascan')
    }
    
    const handleChange = (value) => {
        if(value)
        {
            setTransactionApiData(prevState => [...prevState, value ])
        }
    }

    const columns = [
        { field: 'CustomerName', headerName: 'Customer Name', flex: 3},
        { field: 'Amount', headerName: 'Amount', flex: 2},
        { field: 'RedeemAt', headerName: 'Redeem At', flex: 3},
    ];


    return (
        <div id='barcode'>
       
            {
                loaderMain ?
                <Loader width={180} height={180} />
                :
                !pass ?
                <div className='row w-100 ml_0 mr_0'>
                    <div className="col-md-12  d-flex justify-content-center">
                      <div className="text-center w-40">
                            <div className="">
                                <CameraScanner />
                            </div>
                            <CustomDrawer
                                component       = {<TransactionTableComponent 
                                    show            = {show}
                                    handleSearch    = {handleSearch}
                                    result          = {result}
                                    filteredData    = {filteredData}
                                    transactionData = {transactionData}
                                    columns         = {columns}
                                />}
                                anchor        = "right"
                                open          = {showDrawer}
                                setShowDrawer = {setShowDrawer}
                            />

                            <div className='w-100 text-center passDiv mt_32'>
                                    <CustomTextField 
                                        className   = "w-100"
                                        type        = "text"
                                        label       = "Customer Passcode"
                                        height      = {'62px'}
                                        // icon        = {<div></div>}
                                        position    = "start"
                                        value       = {passCode}
                                        inputLabel  = "w-100 ml_10"
                                        onChange    = {handleQrCode}
                                        icon        = {loader ? <CircularProgress size={20} color={'inherit'}/> : apiError ? <SvgIcons.Error /> : ''}
                                        autoFocus
                                    />
                                <div className="error mt_5 ml_0">{apiError}</div>
                            </div>

                            <div className="mt_24 Body14R color-neutral100">
                                ----------- OR -----------
                            </div>

                            <div className="mt_24">
                                <CustomButton 
                                    onClick   = {()=>setShow({...show, customerModal: true})}
                                    className = "w-100"
                                    btntext   = "Search Customer"
                                    icon      = {<SvgIcons.AddCustomerIcon/>}
                                    height    = {'55px'}
                                />
                            </div>

                            <div className={`color-themeColor Link14M cp mt_32 mb_24` } onClick={()=>setShowDrawer(true)}>
                                Transition History
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={!pass ? ' col-6 mt_0 pr_0 pl_0 bg-color-cF7F7F7 qr-code-div' :'col-6 pr_0 mt_0 pl_0 qr-code-div'}>
                    { !pass ? 
                        <div className="position-relative">
                            {/* <div className='w-70 passDiv'>
                                    <CustomTextField 
                                        className   = "w-100"
                                        type        = "text"
                                        placeholder = "Enter Pass Code"
                                        label       = "Pass Code"
                                        value       = {passCode}
                                        inputLabel  = "w-100 ml_10"
                                        onChange    = {handleQrCode}
                                        icon        = {loader ? <CircularProgress size={20} color={'inherit'}/> : apiError ? <SvgIcons.Error /> : ''}
                                        position    = 'end'
                                        autoFocus
                                    />
                                <div className="error mt_5 ml_0">{apiError}</div>
                            </div> */}
                            {/* <div className="d-flex addAudienceIcon">
                                <div className="cameraIcon middle mr_4 cp" onClick={goToCameraScanner}>
                                    <SvgIcons.RedeemCameraIcon/>
                                </div>
                                <div className="customerIcon middle cp" onClick={()=>setShow({...show, customerModal: true})}>
                                    <SvgIcons.AddCustomerIcon/>
                                </div>
                            </div> */}
                            <div>
                                {/* <img src={PngIcons.PlaceHolderImage} alt='' width='100%' height='100%'/> */}
                            </div>
                        </div>
                        : 
                        <Redemption passData={pass} getNewRedemption={handleChange} />
                    }
                </div>  
                
            }
            <CustomModal
                open      = {show.customerModal}
                minWidth  = {'550px'}
                onClose   = {()=>setShow({...show, customerModal: false})}
                component = {<SearchCustomerComponent  show={show} setShow={setShow}  handleQrCode= {handleQrCode}
                setPass = {setPass} onClose = {()=>setShow({...show, customerModal : false})}
                />}
            />
        </div>
    )
}

const SearchCustomerComponent = ({show, setShow, onClose, passCode, setPass}) => {

    const [filteredCustomers, setFilteredCustomer] = useState()    
    const [customers, setCustomers] = useState([])
    const [campaigns, setCampaigns] = useState([])

    const [selected, setSelected] = useState({
        customer : '',
        campaign : {}
    })
    const brand   = useContext(BrandContext)

    const handleUserSearch = e => {
        let allCustomer = customers;

        const filteredCustomer = allCustomer.filter(customer => {
                return customer.user?.email.toLowerCase().includes(e.target.value)
        })

        if(e.target.value == "")setFilteredCustomer([])
        else setFilteredCustomer([...filteredCustomer])
        
    }

    const handleSelectedCustomer = async (customer) => {
        setShow({...show, processing: customer.user._id})
        const pass         = await getPass(customer)
        setShow({...show, processing: '', subscriberDropdown: false})
        onClose();
        // const transactions = pass && await getTransactions(customer, pass._id)
        
        // pass && setSelected({...selected, customer: {pass, ...customer, transactions}})
    }

    const getPass = async (customer) => {
        let query = {campaignId: selected.campaign._id, brandId: (brand.brandType === Brand.BrandTypes.SUB_BRAND) ? brand.parentBrandId : brand._id, userId: customer.user._id, delete: false}
        const {error, response} = await PassService.GetQueriedPass({toaster: true, query})
        if(error) return
        query = {passCode: response.data[0].passCode, designType: PassDesign.DesignTypes.APPLE}
        const passJson = await PassService.GetPassJSON({query})
        setShow({...show, customerModal : false })
        setPass(passJson.response.data)
        // await handleQrCode(undefined, response.data[0].passCode)
        return response.data[0]
    }

    const getTransactions = async (customer, passId) => {
        const query = {passId: passId}
        const {error, response} = await TransactionService.GetQueriedTransactions({query})
        if(error) return
        return response.data
    }

    const getSubscribers = async () => {
        let customers = await localForage.getItem('totalcustomers');
        if(!customers)
        {
            const query = {_id: (brand.brandType === Brand.BrandTypes.SUB_BRAND) ? brand.parentBrandId : brand._id}
            const {error, response} = await BrandService.GetSubscribers({toaster: false, query})
            if(error) return;
            if(response) customers = response.data
            response && localForage.setItem('totalcustomers', customers)
        }
        customers && setCustomers(customers)
    }


    const getCampaigns = async () => {
        const query = {
            brandId: (brand.brandType === Brand.BrandTypes.SUB_BRAND) ? brand.parentBrandId : brand._id,
            campaignType: 5
        }
        const {error, response} = await CampaignService.GetQueriedCampaign({toaster: false, query})
        if(error) return
        
        setCampaigns(response.data)
        setSelected({...selected, campaign: response.data[0]})
    }

    const onLoad = () => {
        getSubscribers()
        getCampaigns()
    }

    useEffect(()=>{
        onLoad();
    },[])

    return(
        <div className="w-100 position-relative">
            <div className="d-flex space-between mb_16">
                <div className="Body14R fw-6 mt_8">
                    Customer
                </div>
                <div>
                    <CustomButton 
                        btntext   = {'Cancel'}
                        onClick   = {onClose}
                        varient   = "secondary"
                        className = "mr_8"
                    />
                    <CustomButton 
                        btntext         = "Add Customer"
                        border          = "0px"
                        onClick         = {()=>setShow({...show, addCustomer : true})}
                        hover           = {ColorSchemeCode.white}
                        backgroundColor = {ColorSchemeCode.white}
                        color           = {ColorSchemeCode.propertySectionTextColor}
                    />
                </div>


                {
                    show.addCustomer && 
                    <CustomModal 
                        component = {<AddCustomerComponent 
                                        onClose     = {()=>setShow({...show, addCustomer : false})}
                                        show        = {show}
                                        setShow     = {setShow}
                                        selected    = {selected}
                                        setSelected = {setSelected}
                                        campaigns   = {campaigns}
                                    />} 
                        open     = {show.addCustomer}
                        title    = "Add Customer"
                        minWidth = {'600px'}
                />
                }

            </div>
            <ClickOutside onClick={()=>show.subscriberDropdown && setShow({...show, subscriberDropdown: false})}>
                <CustomTextField 
                    placeholder = {'Search a customer by email'}
                    className   = {'w-100 searchField'}
                    icon        = {<SvgIcons.SearchIcon/>}
                    position    = {'start'}
                    onChange    = {handleUserSearch}
                    // onClick     = {()=>setShow({...show, subscriberDropdown: !show.subscriberDropdown})}
                />
                <SubscriberList 
                    customers  = {filteredCustomers}
                    onSelect   = {handleSelectedCustomer}
                    processing = {show.processing}
                />
            </ClickOutside>
            {/* <div className="d-flex row-reverse mt_24 justify-flex-end">
                    <CustomButton 
                        btntext = {'Cancel'}
                        onClick = {onClose}
                        varient = "secondary"
                    />
            </div> */}

        </div>
    )
}

const SubscriberList = ({customers, onSelect, processing}) => {
    return (
        <>
        {customers?.length > 0 ? <div className="subscriber-dropdown border borderRadius-4 mt_16">
            {customers && customers.map((customer, idx)=>(
                <div key={idx} onClick={()=>onSelect(customer)} className="singleSubscriber d-flex align-items-center cp">
                    <div className="cp">
                        {customer.user.avatar ?
                            <img src={customer.user.avatar} width="24" /> : 
                            utils.getAvatar({
                                firstName : customer.user.firstName,
                                lastName  : customer.user.lastName,
                                width     : '24px',
                                heigth    : '24px'
                            })
                        }
                    </div>
                    <div className="ml_12">
                        <div className="color-neutral80">{customer.user.email}</div>
                        <div className="fs-12">{customer.user.firstName} {customer.user.lastName}</div>
                    </div>
                    {processing === customer.user._id && <div className="w-fit-content ml_auto"><CircularProgress size={20} color={'inherit'}/></div>}
                </div>
            ))}
        </div>
            :
            <div className="">
                
            </div>
    }
        </>
    )
}

const AddCustomerComponent = ({onClose, show, setShow, selected, setSelected, campaigns}) => {

    const initState = {
        firstName   : '',
        email       : '',
        lastName    : '',
        phone       : '',
        birthday    : ''
    }

    const [loader , setLoader] = useState(false);

    const brand = useContext(BrandContext)

    const handleSubmit = async() => {
        setLoader(true);
        let payload = {
            firstName    : formik.values.firstName,
            email        : formik.values.email,
            campaignCode : selected.campaign.campaignCode,
            brandId      : selected.campaign.brandId,
            lastName     : formik.values.lastName,
            phone        : formik.values.phone,
            birthday     : new Date(formik.values.birthday).valueOf()
        }

        const headers = {'x-api-key': brand.apiKey}
        
        const {response, error} = await PassService.Create({toaster:true, payload, headers})

        setLoader(false)

        setShow({...show, addCustomer : false, customerModal : false})

    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : PassVld.Create,
    })

    
    return(
        <div className="addCustomerComponent" id="customModal">
            <form onSubmit={formik.handleSubmit}>
                <div className="modalComponent">
                    <div className="customLabel mt_16">
                        Select Campaign
                    </div>
                    <div className="campaign-dropdown w-50 mt_0 position-relative mt_8">
                        <ClickOutside onClick={e=>show.addCustomerDropDown && setShow({...show, addCustomerDropDown: false})}>
                            <div onClick={()=>setShow({...show, addCustomerDropDown: !show.addCustomerDropDown})}>
                                <span>{selected.campaign.campaignName}</span>
                                {<span className="float-right"><SvgIcons.DropDownTriangleIcon height="12px" width="10px" color={ColorSchemeCode.c828282} /></span>}
                            </div>
                            {show.addCustomerDropDown && <CampaignsDropDown 
                                title        = {'Campaigns'}
                                campaignList = {campaigns}
                                onSelect     = {campaign=>{setSelected({...selected, campaign}); setShow({...show, addCustomerDropDown: false})}}
                            />}
                        </ClickOutside>
                    </div>
                    <div className="mt_32 w-100">
                        <CustomTextField 
                            label            = "First Name"
                            className        = "w-100"
                            labelPaddingLeft = "0px"
                            name             = "firstName"
                            value            = {formik.values.firstName}
                            onChange         = {formik.handleChange}
                            inputProps       = {{ onFocus: formik.handleBlur }}
                            error            = {formik.touched.firstName && formik.errors.firstName}
                            helperText       = {formik.touched.firstName && formik.errors.firstName && formik.errors.firstName}
                        />
                    </div>
                    <div className="mt_32 w-100">
                        <CustomTextField 
                            label            = "Last Name"
                            className        = "w-100"
                            labelPaddingLeft = "0px"
                            name             = "lastName"
                            value            = {formik.values.lastName}
                            onChange         = {formik.handleChange}
                            inputProps       = {{ onFocus: formik.handleBlur }}
                            error            = {formik.touched.lastName && formik.errors.lastName}
                            helperText       = {formik.touched.lastName && formik.errors.lastName && formik.errors.lastName}
                        />
                    </div>
                    <div className="phoneNumber d-flex mt_32">
                        <div className="mt_16 col-9 pl_0 pr_0">
                            <CustomTextField 
                                type             = "tel"
                                className        = "w-100"
                                labelPaddingLeft = "0px"
                                name             = "phone"
                                value            = {formik.values.phone}
                                onChange         = {formik.handleChange}
                                inputProps       = {{ onFocus: formik.handleBlur }}
                                error            = {formik.touched.phone && formik.errors.phone}
                                helperText       = {formik.touched.phone && formik.errors.phone && formik.errors.phone}
                                />  
                        </div>
                    </div>
                    <div className="mt_32 w-100">
                        <CustomTextField 
                            label            = "Email Address"
                            className        = "w-100"
                            labelPaddingLeft = "0px"
                            name             = "email"
                            value            = {formik.values.email}
                            onChange         = {formik.handleChange}
                            inputProps       = {{ onFocus: formik.handleBlur }}
                            error            = {formik.touched.email && formik.errors.email}
                            helperText       = {formik.touched.email && formik.errors.email && formik.errors.email}
                            />
                    </div>
                    <div className="mt_32 w-100">
                        <div className="customLabel mb_8">
                            Birthday
                        </div>
                        {/* <CustomDatePicker  */}
                        <CustomTextField 
                            fontSize   = "14px"
                            className  = "w-100"
                            type       = "date"
                            name       = "birthday"
                            value      = {formik.values.birthday}
                            onChange   = {formik.handleChange}
                            inputProps = {{ onFocus: formik.handleBlur }}
                            error      = {formik.touched.birthday && formik.errors.birthday}
                            helperText = {formik.touched.birthday && formik.errors.birthday && formik.errors.birthday}
                        />
                    </div>
                </div>
            <div className="d-flex justify-flex-end modalButton">
                <div className="mr_8">
                    <CustomButton 
                        btntext         = "Cancel"
                        // height          = "40px"
                        backgroundColor = {ColorSchemeCode.white}
                        color           = {ColorSchemeCode.c000000}
                        hover           = {ColorSchemeCode.white}
                        focus           = {ColorSchemeCode.white}  
                        onClick         = {onClose}                      
                    />
                </div>
                <div>
                    <CustomButton 
                        btntext = "Add"
                        // height  = "40px"
                        type    = "submit"
                        icon      = {loader && <CircularProgress size={20} color={'inherit'}/>}
                    />
                </div>

            </div>
            </form>
        </div>
    )
}

const TransactionTableComponent = ({show,result, handleSearch,filteredData,transactionData,columns}) =>{
    return (
        <div className={`transactionCol border-left position-fixed bg-color-white`} >
        <div className="Body14R fs-14 fw-5 color-c000000">
            Recent Transitions
        </div>

        <div className="mt_16">
            <CustomSearch
                className   = "w-100 mb_16"
                placeholder = "Search by customer name"
                icon        = {<SvgIcons.SearchIcon/>}
                position    = {'start'}
                onChange    = {(e)=>handleSearch(e)}
                />
                {result.length > 0 ? 
            <div>
                 {/* <CustomTable
                    dataRows     = {filteredData || transactionData}
                    headerRow    = {tableHeadings}
                    tableShadow  = {'none'}
                    stickyHeader = {true}
                    pagination   = {true}
                /> */}
                <CustomTableV2
                    pageSize      = {50}
                    height        = {'80vh'}
                    title         = {'Transactions'}
                    checkbox      = {false}
                    rows          = {filteredData || transactionData}
                    columns       = {columns}
                    deleteAll     = {false}
                    handleRefresh = {()=>console.log('resfresh')}
                    onRowClick    = {(value)=>console.log(value.row.value)}
                    />
            </div>
        :

        <div className="h-100 fw-4 middle">
            No Transition Made
        </div>
    }
        </div>

    </div>
    )
}