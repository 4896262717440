// UnsubscribeButton.js
import axios from 'axios';
import React from 'react';
// import { useParams } from 'react-router-dom';
// import './UnsubscribeButton.css';
import { useLocation } from 'react-router-dom';
import env from './config';

const UnsubscribeButton = () => {
    // const { email } = useParams();

    const query = new URLSearchParams(useLocation().search);
    const email = query.get('email');


    const handleUnsubscribe = async() => {
        console.log(email)
        try {
            // const email = "user@example.com"; // Make sure to get this from a secure source or input
            axios.get(env.API_URL+'/v4/pass/suppressions/?email='+email)
            .then(response => {
                console.log('Data:', response);
                // alert(response.data); 
                alert("You've unsubscribed from all emails!"); 
                setTimeout(() => {
                    window.close('','_parent','')
                }, 3000);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        } catch (error) {
            console.error("An error occurred:", error.response ? error.response.data.message : error.message);
            alert("Failed to unsubscribe. Please try again.");
        }
        
        // Place your API call here to handle the unsubscription.
    };

    

    return (
        <div className="unsubscribe-container mt_20">
            <h2>Unsubscribe from All Emails</h2>
            <p>If you no longer wish to receive emails from us, click the button below.</p>
            <button className="unsubscribe-button" onClick={handleUnsubscribe}>
                Unsubscribe from All Emails
            </button>
        </div>
    );
};

export default UnsubscribeButton;
