import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomSelect from '../../components/CustomSelect';
import CustomTextField from '../../components/CustomTextField';
import { LocationService, UserService } from '../../services';
import { useParams } from 'react-router-dom'
import { Options } from '../../utils';
import { CountryCode } from '../../enums';

function LandingPageContact() {
    const [state, setState] = useState({
        phone       : '',
    })

    const params = useParams();
    const history = useHistory();

    return (
        <div style={{height: '90vh'}} className="middle">
            <div className="p_24">
                <div className="Heading20M color-GeneralBlack mb_24 text-align-center">Enter phone number</div>
                <div className="row">
                    <CustomTextField
                        value     = {state.phone}
                        onChange  = {(e)=>setState({...state, phone: e.target.value})}
                        label     = "Phone"
                        className = "ml_4"
                    />
                </div>
                <CustomButton
                    btntext   = "Next"
                    className = "w-100 mt_24"
                    disabled  = {(!state.phone) ? true : false}
                    onClick   = {async()=>{
                        const {response, error} = await UserService.UpdatePublic({payload: {_id: history.location.state.result.userId, phone: state.phone}})
                        if(response) history.push({pathname: '/page/'+params.growthtoolId+'/listing', state: {...history.location.state}})
                    }}
                />
            </div>
        </div>
    )
}

export default LandingPageContact
