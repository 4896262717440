import * as yup from 'yup';
import { User } from '../enums'

const Create = yup.object({
    fullName    : yup.string().required('This field is required'),
    email       : yup.string().email().required(),
    subbrand    : yup.string(),
    phone       : yup.string().matches(/^\d{1,10}$/),
    role        : yup.string().required().oneOf(Object.values(User.Roles))
})


export {
    Create,
}