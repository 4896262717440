// import React, { useState } from 'react';
// import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
// import env from '../config';

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [amount, setAmount] = useState(0);
//   const [message, setMessage] = useState('');

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (!stripe || !elements) {
//       setMessage('Stripe is not fully initialized.');
//       return;
//     }
  
//     const cardElement = elements.getElement(CardElement);
//     if (!cardElement) {
//       setMessage('Please enter a payment method.');
//       return;
//     }
  
//     try {
//       // Call your backend to create a PaymentIntent
//       const response = await fetch(env.API_URL+'/v4/pass/stripe/', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ amount: amount * 100 }), // Convert to cents
//       });
//       console.log('response',response);
      
//       const { clientSecret } = await response.json();
  
//       // Confirm the payment with the card details
//       const result = await stripe.confirmCardPayment(clientSecret, {
//         payment_method: {
//           card: cardElement,
//         },
//       });

//       console.log('result',result)
  
//       if (result.error) {
//         setMessage(`Payment failed: ${result.error.message}`);
//       } else if (result.paymentIntent.status === 'succeeded') {
//         setMessage('Payment successful!');
//       } else {
//         setMessage(`Payment status: ${result.paymentIntent.status}`);
//       }
//     } catch (error) {
//       console.log('error',error)
//       setMessage(`Error: ${error.message}`);
//     }
//   };
  
  
//   const cardStyle = {
//     style: {
//       base: {
//         color: '#32325d',
//         fontFamily: 'Arial, sans-serif',
//         fontSize: '16px',
//         '::placeholder': {
//           color: '#aab7c4',
//         },
//       },
//       invalid: {
//         color: '#fa755a',
//         iconColor: '#fa755a',
//       },
//     },
//   };
//   const handleSubmit_a = async (event) => {
//     console.log('a1')
//     event.preventDefault();
//     console.log('a2')
//     if (!stripe || !elements) {
//       console.log('a3')
//       return;
//     }
//     console.log('a4')

//     try {
//       console.log('a')
//       const response = await fetch(env.API_URL+'/v4/pass/stripe/', {
//       // const response = await fetch('http://localhost:8080/v4/pass/stripe/', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ amount: amount * 100 }), // Convert to cents
//       });
//       console.log('response',response)
//       const { clientSecret } = await response.json();

//       const result = await stripe.confirmCardPayment(clientSecret, {
//         payment_method: {
//           card: elements.getElement(CardElement),
//         },
//       });

//       console.log('result',result);
      

//       if (result.error) {
//         setMessage(`Payment failed: ${result.error.message}`);
//       } else if (result.paymentIntent.status === 'succeeded') {
//         setMessage('Payment succeeded!');
//         // Reward the customer here
//       }
//     } catch (error) {
//       setMessage(`Error: ${error.message}`);
//     }
//   };

//   return (
//     <div id="checkoutF" className="checkout-form">
//       <form onSubmit={handleSubmit}>
//         <input
//           type="number"
//           value={amount}
//           onChange={(e) => setAmount(e.target.value)}
//           placeholder="Enter amount"
//         />
//         <h2>Enter Payment Details</h2>
//         <div className="card-input">
//           <CardElement options={cardStyle} />
//         </div>
//         <button
//           type="submit"
//           className="submit-button"
//           disabled={!stripe 
//             // || loading
//           }
//         >
//           {
          
//           // loading ? 'Processing...' : 
//           'Pay Now'
//           }
//         </button>
//         {message && <div className="message">{message}</div>}
//       </form>
//     </div>
//     // <form onSubmit={handleSubmit}>
//       // <input
//       //   type="number"
//       //   value={amount}
//       //   onChange={(e) => setAmount(e.target.value)}
//       //   placeholder="Enter amount"
//       // />
//     //   <CardElement options={cardStyle}/>
//     //   <button type="submit" disabled={!stripe}>
//     //     Pay
//     //   </button>
//     //   {message && <div>{message}</div>}
//     // </form>
//   );
// };

// export default CheckoutForm;





import React, { useContext, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { LayoutContext } from '../context/Layout.context';
import { BrandContext } from '../context/Brand.context';
import { BrandService } from '../services';
import localforage from 'localforage';
import env from '../config';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [points, setPoints] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const brand = useContext(BrandContext)

  // Calculate the total amount based on points input
  const calculateAmount = (points) => {
    const parsedPoints = parseInt(points, 10);
    if (isNaN(parsedPoints) || parsedPoints <= 0) {
      return 0;
    } else if (parsedPoints <= 1000) {
      return parsedPoints * 0.10; // 10 cents per point
    } else if (parsedPoints <= 5000) {
      return parsedPoints * 0.05; // 5 cents per point
    } else {
      return parsedPoints * 0.04; // 4 cents per point
    }
  };

  const handlePointsChange = (e) => {
    const enteredPoints = e.target.value;
    setPoints(enteredPoints);
    setTotalAmount(calculateAmount(enteredPoints));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setLoading(true);

    if (!stripe || !elements) {
      setMessage('Stripe is not loaded yet.');
      setLoading(false);
      return;
    }

    if (!points || totalAmount <= 0) {
      setMessage('Please enter valid points to calculate the total amount.');
      setLoading(false);
      return;
    }

    // Confirm payment details
    const confirmPayment = window.confirm(
      `You are purchasing ${points} points at a rate of ${
        totalAmount / points
      } USD per point.\nTotal amount to be charged: $${totalAmount.toFixed(2)}.\nDo you want to proceed with the payment?`
    );

    if (!confirmPayment) {
      setLoading(false);
      setMessage('Payment cancelled.');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      // Call backend to create PaymentIntent
      const response = await fetch(env.API_URL+'/v4/pass/stripe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: Math.round(totalAmount * 100), brand: brand, points: points }), // Amount in cents
      });

      const { clientSecret } = await response.json();

      // Confirm the payment
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      console.log('result',result);
      

      if (result.error) {
        setMessage(`Payment failed: ${result.error.message}`);
      } else if (result.paymentIntent.status === 'succeeded') {
        // 
        await BrandService.Update({payload: {_id: brand._id, points: parseInt(brand.points)+parseInt(points)}})
        // 
        const getBrand = await BrandService.Get({query: {_id: brand._id}})
        localforage.setItem('brand',getBrand.response.data[0])
        if(brand)brand.updateBrand(getBrand.response.data[0])
        setMessage('Payment successful!');

      } else {
        setMessage(`Payment status: ${result.paymentIntent.status}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const layout = useContext(LayoutContext)
  React.useEffect(() => {
    layout.setLayout({
        title: 'Settings',
        borderBottom: false,
        switch: "",
        button: ""
    })
}, [])

  return (
    <div id="checkoutF" className="checkout-form w-40 middle mt_16 ml_100">
      <form onSubmit={handleSubmit}>
        <h2>Purchase Points</h2>

        {/* Points Input */}
        <div className="points-input">
          <label htmlFor="points">Enter Points to Purchase:</label>
          <input
            type="number"
            id="points"
            style={{minWidth: '500px'}}
            value={points}
            onChange={handlePointsChange}
            placeholder="Enter points"
          />
          {points ? (
            <p>
              You entered {points} points. Per point: $
              {(totalAmount / points || 0).toFixed(2)}. Total amount: $
              {totalAmount.toFixed(2)}.
            </p>
          ):<p>&nbsp;&nbsp;</p>}
        </div>

        {/* Card Input */}
        <div className="card-input">
          <CardElement options={cardStyle} />
        </div>

        <button
          type="submit"
          className="submit-button"
          disabled={!stripe || loading}
        >
          {loading ? 'Processing...' : 'Pay Now'}
        </button>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default CheckoutForm;
