import React, { useContext, useEffect, useState } from 'react'

import { useHistory, Link } from 'react-router-dom';

import CustomButton from '../../../components/CustomButton';
import { BrandContext } from '../../../context/Brand.context';
import { LayoutContext } from '../../../context/Layout.context';
import { Brand } from '../../../enums';
import { PngIcons, SvgIcons } from '../../../icons';

function CommunicationDashboard(props) {
    const layout            = useContext(LayoutContext)
    const history           = useHistory();
    const brand             = useContext(BrandContext)

    const [state, setstate] = useState([
        // {
        //     icon       : <SvgIcons.AUTOMATION/>,
        //     action     : "2 automation",
        //     heading    : "Automation",
        //     disable    : false,
        //     available  : true,
        //     description: "Engage with Automation. Automated messages and push notifications can be scheduled or triggered at events.",
        //     onClick    : ()=>history.push(props.match.url+'/automation')
        // },
        // {
        //     icon           : <SvgIcons.SMS/>,
        //     action         : "4 sms",
        //     heading        : "SMS",
        //     integration    : 'Twilio',
        //     link           : '/'+brand._id+'/setting/integrations',
        //     integrationIcon: PngIcons.TwilioLarge,
        //     available      : true,
        //     disable        : (brand.integrations?.smsService?.accountSid||brand.integrations?.textGrid?.accountSid) ? false                                                                                 : true,
        //     description    : "Build SMS into your omnichannel strategy to create a seamless, integrated customer experience that meets shoppers on their terms.",
        //     onClick        : ()=>history.push(props.match.url+'/sms')
        // },
        {
            icon       : <SvgIcons.NOTIFICATION/>,
            action     : "3 Notifications",
            heading    : "Push Notifications",
            disable    : false,
            available  : true,
            description: "Make Push notification  Part of a Seamless Customer Experience. Reach 100% of Your Mobile Audience All the Time.",
            onClick    : ()=>history.push(props.match.url+'/push')
        },
        // {
        //     icon       : <SvgIcons.STORIES/>,
        //     action     : "Coming Soon",
        //     heading    : "Stories",
        //     disable    : true,
        //     available  : false,
        //     description: "Engage and delight consumers by taking text messaging to the next level. Deliver a multimedia experience by App Push Notification.Only work with XIncentiveAPP.",
        //     onClick    : ()=>history.push(props.match.url+'/notification')
        // },
        
    ])
    
    
    useEffect(()=>{
        if(brand.brandType === 'sharedLoyality'){
            state.push({
                icon       : <SvgIcons.STORIES/>,
                action     : "Coming Soon",
                heading    : "Stories",
                disable    : brand.brandType === Brand.BrandTypes.SHARED_LOYALITY ? false : true,
                available  : true,
                description: "Engage and delight consumers by taking text messaging to the next level. Deliver a multimedia experience by App Push Notification.Only work with XIncentiveAPP.",
                onClick    : ()=>history.push(props.match.url+'/notification')
            })
            setstate([...state])
        }

        layout.setLayout({
            borderBottom: false,
            edit        : false,
            editTitle   : "",
            title       : "Communication",
            button      : <div></div>,
            switch      : "",
        })
    },[])

  return (
    <div className="pt_16 pr_40 pl_40 pb_16" style={{height: 'calc(100vh - 72px)', overflowY: 'scroll'}}>
        <div className='grid'>
            {
                state.map((value, index)=>(
                    <div className={' d-flex w-40 flex-column space-between'} style={{boxShadow: ((value.heading === "Push Notifications") || (value.heading === "Stories")) && 'none'}}>
                        <div>
                            <div className='mb_16 d-flex space-between'>
                                {value.icon}
                                {/*<div className='tag Link13M color-primary50'>{value.action}</div>*/}
                            </div>
                            <div className='color-neutral100 Heading16M mb_12'>{value.heading}</div>
                            <div className='color-neutral70 Body14R mb_40'>{value.description}</div>
                        </div>
                        <div>
                          {(value.available && !value.disable) ? 
                                <CustomButton 
                                    size      = "l"
                                    className = "w-100 cp"
                                    btntext   = "View All"
                                    varient   = "secondary"
                                    onClick   = {value.onClick}
                                />
                                :
                                (value.available && value.disable) ?
                                <div className="disableCommunication">
                                    <div className='d-flex'>
                                        <img src={value.integrationIcon} height="20" width="20" alt=""/>
                                        <div className='color-neutral100 Body14R ml_8'>{value.integration + ' integration is required.'}</div>
                                    </div>
                                    <Link to={value.link} className="color-primary50 U14M cp text-dec-none" >Integrate</Link>
                                </div>
                                :
                                <></>
                          }
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default CommunicationDashboard