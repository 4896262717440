import React, { useContext, useEffect, useState } from 'react';
import {Route, Switch, Redirect, Link, BrowserRouter} from "react-router-dom";

// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import env from '../../config'

import General from '../../pages/setting/subpages/General';
import Billing from '../../pages/setting/subpages/Billing';
import Integration from '../../pages/setting/subpages/IntegrationNew';
import Api from '../../pages/setting/subpages/Api';
import Notification from '../../pages/setting/subpages/Notification';
import Policy from '../../pages/setting/subpages/Policy';
import Staff from '../../pages/setting/subpages/Staff';
import CustomField from '../../pages/setting/subpages/CustomField';
import Application from '../../pages/setting/subpages/Application';
import Redemption from '../../pages/setting/subpages/Redemption';
import Locations from '../../pages/setting/subpages/Location';
import Loader from '../../components/Loader';
import { BrandContext } from '../../context/Brand.context';
import { AgencyService } from '../../services';
import { LayoutContext } from '../../context/Layout.context';
import Logs from './subpages/Logs';
import Settings from './subpages/Settings';
import { SvgIcons } from '../../icons';
import Account from './subpages/Account';
import Raffle from './subpages/Raffle';
import IntegrationNew from './subpages/IntegrationNew';
import VipClub from './subpages/VipClub';
import { Brand } from '../../enums';
import LocationNew from './subpages/LocationNew';
import BusinessWallet from './subpages/BusinessWallet';
import ExtraServices from './subpages/ExtraServices';
import InStore from './subpages/InStore';
import Referral from './subpages/Referral'
import Domain from './subpages/Domain';
import { useHistory } from "react-router-dom";
import Notifications from './subpages/Notifications';
import CheckoutForm from '../CheckoutForm';

export default function Setting(props) {
    
    const [stripePromise, setStripePromise] = useState()
    const [stripeError, setStripeError]     = useState()
    
    const layout = useContext(LayoutContext)
    const brand = useContext(BrandContext)
    console.log('BRAND',brand);
    console.log('bb',brand);
    console.log('env.STRIPE_PUBLISH_KEY',env.STRIPE_PUBLISH_KEY);

    // const setStripe = async() => {
    //     console.log('env.STRIPE_PUBLISH_KEY',env.STRIPE_PUBLISH_KEY);
    //     if(!brand.agency){
    //         console.log('inn loop');
    //         return setStripePromise(loadStripe(env.STRIPE_PUBLISH_KEY))
    //     }

    //     const query = {_id: brand.agency.id}
    //     const {error, response} = await AgencyService.Get({query})

    //     if(error || !response.data[0].paymentService) return setStripeError('agency plans not found')
    //     return setStripePromise(loadStripe(response.data[0].paymentService.stripe_publishable_key))
    // }

    // const onLoad = () => setStripe()

    // useEffect(onLoad, [])
    useEffect(async() => {
        console.log('env.STRIPE_PUBLISH_KEY',env.STRIPE_PUBLISH_KEY);
        console.log('0ut loop');

        if(brand.agency){
            console.log('brand',brand);
            console.log('0ut2 loop');
    
            
            const query = {_id: brand?.agency?.id}
            const {error, response} = await AgencyService.Get({query})
            console.log('error, response',error, response);
            
            if(error || !response.data[0].paymentService) setStripeError('agency plans not found')
            
            // setStripePromise(loadStripe(env.STRIPE_PUBLISH_KEY))
            setStripePromise(loadStripe(response.data[0].paymentService.stripe_publishable_key))
        }
        else{
            console.log('inn loop');
            setStripePromise(loadStripe(env.STRIPE_PUBLISH_KEY))
        }
       
    }, [])
    
    
    
    const ComponentList = [
        {
            name     : "Business Profile",
            icon     : <SvgIcons.BusinessProfileIcon/>,
            component: "/general",
            display : true,
        },
        {
            name     : "Team Members",
            icon     : <SvgIcons.TeamMembersIcon/>,
            component: "/staff",
            display : true,
            disable : false 
            // disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        },
        {
            name     : "Locations",
            icon     : <SvgIcons.LocIcon/>,
            component: "/locationnew",
            display : true,
            disable : false 
            // disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        },
        // {
        //     name     : "Pass Design",
        //     icon     : <SvgIcons.PassIcon/>,
        //     // component: "/billing",
        //     component: "/pass",
        //     display  : (!brand?.integrations?.cloudwaitress?.length>0) ? true : false,
        //     disable  : false
        // },
        // {
        //     name     : "Account",
        //     icon     : <SvgIcons.SettingIcon/>,
        //     component: "/account",
        //     display : true,
        //     disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        // },
        {
            name     : "Scanning Application",
            icon     : <SvgIcons.BusinessWall/>,
            component: "/businesswallet",
            display : true,
            disable : false 
            // disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        },
        {
            name     : "Notifications",
            icon     : <SvgIcons.NotiIcon/>,
            component: "/notifications",
            display : true,
            // display : (brand?.integrations?.cloudwaitress?.length>0) ? true : false,
            disable : false 
            // disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        },
        {
            name     : "Purchase Points",
            icon     : <SvgIcons.BillingIcon/>,
            component: "/payment",
            display : true,
            // display : (brand?.integrations?.cloudwaitress?.length>0) ? true : false,
            disable : false 
            // disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        },
        // {
        //     name     : "Extra Services",
        //     icon     : <SvgIcons.ExtraBService/>,
        //     component: "/extrabservices",
        //     display : true,
        //     disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        // },
        // {
        //     name     : "In-Store Settings",
        //     icon     : <SvgIcons.InStoreSett/>,
        //     component: "/storesettings",
        //     display : true,
        //     disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        // },
        // {
        //     name     : "Activity Logs",
        //     icon     : <SvgIcons.ActivityLogsIcon/>,
        //     component: "/logs",
        //     display : true,
        //     disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        // },
        // {
        //     name     : "Branded URL Setup",
        //     icon     : <SvgIcons.Domain />,
        //     component: "/Domain",
        //     display : true,
        //     disable : false 
        //     // disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        // },
        // {
        //     name     : "Referral",
        //     icon     : <SvgIcons.TwoMan />,
        //     component: "/referral",
        //     display : true,
        //     disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        // },
    
        // {
        //     name      : "Integrations",
        //     icon      : <SvgIcons.IntegrationIcon/>,
        //     component : "/integrations",
        //     display : true,
        //     // disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        //     disable : false 
            
        //     // disable   : ((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false
        // },
        // {
        //     name     : "Customs Fields",
        //     icon     : <SvgIcons.CustomFieldsIcon/>,
        //     component: "/customfield",
        //     display : true,
        //     disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        // },
        // {
        //     name     : "General & API Settings",
        //     icon     : <SvgIcons.APIIcon/>,
        //     component: "/api",
        //     display : true,
        //     // disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false )
        //     disable : false 
        // },  
        // {
        //     name     : "VIP Club",
        //     icon     : <SvgIcons.VipIcon/>,
        //     component: "/vip",
        //     disable : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? false :((!brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? true : false ),
        //     display : (brand.brandType === Brand.BrandTypes.NORMAL) && brand.vip  ,
        
        // },
        
        // {
        //     name     : "Billing & Plans",
        //     icon     : <SvgIcons.BillingIcon/>,
        //     component: "/billing",
        //     display  : (brand.brandType == Brand.BrandTypes.SHARED_LOYALITY || brand.brandType == Brand.BrandTypes.DEMO) ? false: true,
        //     disable  : false
        // },
        // {
        //     name     : "Raffle",
        //     icon     : <SvgIcons.TeamMembersIcon/>,
        //     component: "/raffle",
        //     display  : brand.brandType == Brand.BrandTypes.SHARED_LOYALITY ? true:false,
        //     disable  : false
        // },

    ]
    
    const [selected, setSelected]           = useState()
    
    const setSelectedTitleOnRefresh = () => {
       let currentComponent = ComponentList.filter((element)=>{
            if(window.location.pathname.includes(element.component)) return element
        })
        setSelected(currentComponent[0])
    }
    useEffect(setSelectedTitleOnRefresh, [])

    const history = useHistory();
    return (
        <div id="setting" className="">
            <div className="d-flex component settingsSections vh-90">
                <div className="w-20 mr_40 section1">
                    {
                    ComponentList.map((element,idx)=>element.display&&
                    element.name!=='Pass Design'&&
                        <div className={`${element.disable && 'disabled'} align-items-center`}>
                            <div className='d-flex align-items-center'>
                                <div className={`${element.disable && 'disabled'} leftBar mr_4 ${(element.name == selected?.name) && 'highlight'}`}></div>
                                {
                                <Link to={props.match.url+ `${element.component}`} style={{border:"none"}} className={`d-flex leftTitles Link14M borderRadius-6 align-items-center w-100 cp ${(element.name == selected?.name) && 'selected'}`} onClick={()=>setSelected(element)} key={idx}>
                                    <div className="mr_8">{element.icon}</div>
                                    <div className='middle'>{element.name}</div>
                                </Link>}
                            </div>
                            {(idx == 7 || idx == 3 || idx==4) && <div className="bar mt_12 mb_12 w-100"></div>}
                    </div>)}
                    {
                    ComponentList.map((element,idx)=>element.display&&
                    element.name==='Pass Design'&&
                        <div className={`${element.disable && 'disabled'} align-items-center`}>
                            <div className='d-flex align-items-center'>
                                <div className={`${element.disable && 'disabled'} leftBar mr_4 ${(element.name == selected?.name) && 'highlight'}`}></div>
                                {
                                <div style={{border:"none"}} className={`d-flex leftTitles Link14M borderRadius-6 align-items-center w-100 cp ${(element.name == selected?.name) && 'selected'}`} 
                                onClick={()=>{
                                    // setSelected(element);
                                    // console.log(window.location.host+'/'+brand._id+'/loyalty/design');
                                    const location = window.location.pathname.split('/')
                                    location.pop()
                                    location.pop()
                                    console.log(location.join('/'));
                                    history.push(location.join('/')+'/loyalty/design')
                                    // history.push({pathname: window.location.host+'/'+brand._id+'/loyalty/design'})
                                    }} key={idx}>
                                    <div className="mr_8">{element.icon}</div>
                                    <div className='middle'>{element.name}</div>
                                </div>}
                            </div>
                            {/* {(idx == 2 || idx == 3 || idx==4) && <div className="bar mt_12 mb_12 w-100"></div>} */}
                    </div>)}
                </div>
                <div className="w-80 section2"> 
                    <Switch>
                        <Route    path = {props.match.url+'/general'}   component           = {General}  ></Route>
                        <Route    path = {props.match.url+'/account'}   component           = {Account}  ></Route>
                        <Route    path = {props.match.url+'/payment'}   component           = {CheckoutForm}  ></Route>
                        <Route    path = {props.match.url+ '/logs'}           component     = {Logs}/>
                        <Route    path = {props.match.url+ '/locationnew'}           component     = {LocationNew}/>
                        <Route    path = {props.match.url+ '/businesswallet'}           component     = {BusinessWallet}/>
                        <Route    path = {props.match.url+ '/extrabservices'}           component     = {ExtraServices}/>
                        <Route    path = {props.match.url+ '/storesettings'}           component     = {InStore}/>
                        <Route    path = {props.match.url+ '/settings'}           component = {Settings}/>
                        <Route    path = {props.match.url+ '/customfield'}    component     = {CustomField}/>
                        <Route    path = {props.match.url+ '/billing'}        component     = {() => stripePromise ? <Elements stripe={stripePromise}><Billing/></Elements> : stripeError ? <div>{stripeError}</div> : <Loader width={180} height={180} />}/>
                        <Route    path = {props.match.url+ '/integrations'}    component    = {Integration}/>
                        {/* <Route    path = {props.match.url+ '/referral'}    component    = {Referral}/> */}
                        <Route    path = {props.match.url+ '/Domain'}    component    = {Domain}/>
                        <Route    path = {props.match.url+ '/api'}            component     = {Api}/>
                        <Route    path = {props.match.url+ '/policy'}         component     = {Policy}/>
                        <Route    path = {props.match.url+ '/locations'}         component  = {Locations}/>
                        <Route    path = {props.match.url+ '/notification'}   component     = {Notification}/>
                        <Route    path = {props.match.url+ '/notifications'}   component     = {Notifications}/>
                        {/* <Route    path = {props.match.url+ '/vip'}   component              = {VipClub}/> */}
                        <Route    path = {props.match.url+ '/staff'}          component     = {Staff}/>
                        <Route    path = {props.match.url+ '/raffle'}          component     = {Raffle}/>
                        <Route    path = {props.match.url+ '/application'}    component     = {Application}/>
                        <Route    path = {props.match.url+ '/redemtiontype'}    component   = {Redemption}/>
                        <Redirect path = {props.match.url+ '/*'} to                         = {props.match.url+'/general'} />
                        <Redirect path = {props.match.url} to                               = {props.match.url+'/general'} />
                    </Switch>  
                </div> 
            </div>
        </div>
    )
}


