import React, { useEffect, useState, useContext, useRef } from "react";
import CustomSearch from "../../../components/CustomSearch";
import { PngIcons, SvgIcons } from "../../../icons";
import {
  BrandService,
  CampaignService,
  CustomFieldService,
  GrowthtoolService,
  LocationService,
  PassService,
  TierService,
  TransactionService,

} from "../../../services";
import { UserService } from "../../../services";
import { BrandContext } from "../../../context/Brand.context";
import Loader from "../../../components/Loader";
import AudienceTable from "./AudienceTable";
import CustomsModal from "../../../components/CustomModal";
import ViewDetail from "../../../components/ViewDetail";
import { ClickOutside, utils } from "../../../utils";
import { AgencyContext } from "../../../context/Agency.context";
import QRCode from "../../../components/QrCode";
import CustomButton from "../../../components/CustomButton";
import { CircularProgress } from "@mui/material";
import CustomTextField from "../../../components/CustomTextField";
import CopyText from "../../../components/CopyText";
import TransactionHistory from "../../walletscan/subpages/TransactionHistory";
import { set } from "lodash";
import DeleteModal from "../../../components/DeleteModal";
import { Brand, Fields, GrowthTools, PassDesign, User } from "../../../enums";
import { LayoutContext } from "../../../context/Layout.context";
import localforage from "localforage";
import { ColorSchemeCode } from "../../../enums/ColorScheme";
import { useFormik } from "formik";
import { PassVld } from "../../../validation";
import CampaignsDropDown from "../../../components/CampaignsDropDown";
import Options from "../../../components/referrel/Options";
import { getContryCodes, getMoreOptions } from "../../../utils/Options";
import { getCountryCode } from "../../../utils/utils";

import { CSVLink, CSVDownload } from "react-csv";
import CustomFieldsDropDown from "../../../components/CustomFieldsDropDown";
import ImportCSV from "./ImportCSV";
import DesginDropDown from "../../../components/CustomDropDown";
import CustomDropDown from "../../../components/CustomDropDown";
import { array } from "yup";
import CustomSelect from '../../../components/CustomSelectCopy';
import PhoneInput from 'react-phone-input-2'
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



export default function Audience() {
  const [headers, setheaders] = useState(
  [
    { label: "Full Name", key: "fullName" },
    { label: "phone Number", key: "phone" },
    { label: "Email", key: "email", },
    { label: "Current Points", key: "currentpoints" },
    // { label: "Toal Points", key: "totalpoints" },
    { label: "Assigned to", key: "assigned" },
    { label: "Referral", key: "referral" },
  ]);

  const headersSample = [
    { label: "firstName", key: "firstName" },
    { label: "lastName", key: "lastName" },
    { label: "email", key: "email" },
    { label: "phone", key: "phone" },
    { label: "points", key: "points" },
  ];

  const brand = useContext(BrandContext);
  const layout = useContext(LayoutContext);

  const [show, setShow] = useState(false);
  const [customerData, setCustomerData] = useState();
  const [filteredCustomers, setFilteredCustomers] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [currentData, setCurrentData] = useState();
  const [viewMore, setViewMore] = useState(false)
  const [update, setUpdate] = useState(false)




  const [index, setIndex] = useState(50);

  const [campaigns, setCampaigns] = useState([]);
  const [passes, setPasses] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [csvData, setCsvData] = useState();

  const [dropDown, setDropDown] = useState(false);
  const [exportData, setExportData] = useState(false)

  const [fields, setFields] = useState([]);
  const [susbscriberField, setSubscriberField] = useState([]);
  const [customFields, setCustomFields] = useState();




  const onLoadCall = async () => {

    if((brand._id === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8")) headers.push({ label: "Entries", key: "entries" })

    const fields = await CustomFieldService.GetQueriedCustomFields({
      query: { brandId: brand._id, delete: false },
    });
    if (fields.response) {
      let fieldsArray = [];
      let subscriberFieldsArray = [];
      let customFieldsArray = [];
      fields.response.data.map((element, idx) => {
        if (element.customFieldType === Fields.CustomFiedlTypes.ASSIGNED_TO) {
          fieldsArray.push(element);
        }

        if (
          element.customFieldType === Fields.CustomFiedlTypes.SUBSCRIBER_FIELD
        ) {
          subscriberFieldsArray.push(element);
          customFieldsArray.push({
            key: element.key,
            value: element.value,
          });
        }
      });

      setFields([...fieldsArray]);
      setCustomFields([...customFieldsArray]);
      setSubscriberField([...subscriberFieldsArray]);
    }
  };

  useEffect(async () => {
    onLoadCall();
  }, []);

  const [showDetails, setShowDetails] = useState({
    campaignDropdown: false,
    addCustomerDropDown: false,
    subscriberDropdown: false,
    processing: "",
    addCustomer: false,
    showAddContact: false,
    loader: false,
    showFiter: false,
  });

  const [selected, setSelected] = useState({
    customer: "",
    campaign: {},
    activeCustomer: "",
  });

  const getPasses = async () => {
    const { response, error } = await PassService.GetQueriedPass({
      query: { delete: false, brandId: brand._id },
    });

    if (response) return response;
    else return error;
  };

  // const getUsers = async () => {
  //   const { response, error } = await UserService.GetByQuery({
  //     query: { delete: false, roles: User.Roles.SUBSCRIBER },
  //   });
  //   if (response) return response;
  //   else return error;
  // };

  // const getTransactions = async () => {
  //   const { response, error } = await TransactionService.GetQueriedTransactions(
  //     { query: { delete: false, brandId: brand._id } }
  //   );
  //   // console.log('response', response);
  //   if (response) return response;
  //   else return error;
  // };

  

  useEffect(() => {
    layout.setLayout({
      title: "Audience",
      backTitles: [],
      edit: false,
      switch: "",
      borderBottom: false,
      button: (
      // <></>
        <div className="d-flex">
          {/* <CustomButton
            varient="primary"
            className={"fs-24"}
            btntext={"Add Contact"}
            onClick={handleAddCustomer}
          /> */}
          {((currentData && currentData.length > 0) || (filteredCustomers && filteredCustomers.length>0) )&&<CustomButton
            icon={
              <div className="pt_4 rotate-right">
                <SvgIcons.ViewMoreIcon />
              </div>
            }
            className={"fs-24 ml_8"}
            varient={"secondary"}
            onClick={() => {
              setDropDown(!dropDown);
            }}
          />}
        </div>
      ),
    });
  }, [dropDown,currentData,filteredCustomers]);

  const onLoad = async () => {
    const customers = await localforage.getItem("customersAudience");
    console.log('responsefields', customers);


    if (await localforage.getItem("customers"))
      localforage.removeItem("customers");
    if (await localforage.getItem("audiencePasses"))
      localforage.removeItem("audiencePasses");
    if (await localforage.getItem("audienceTransactions"))
      localforage.removeItem("audienceTransactions");

    if (customers) {
      await dateSort(customers);
    }

    let [passes, users] = await Promise.all([
      getPasses(),
    ]);
    console.log('passes', passes);

    let growthToolId
    let growthToolData
    const growthTool = await GrowthtoolService.GetPublicGrowthTool({ query: { growthtoolType: GrowthTools.GrowhttoolTypes.LANDING_PAGE, brandId: brand._id } })
    if (growthTool.response) {
      console.log('growthTool', growthTool?.response);
      growthToolId = growthTool.response.data[0]._id
      growthToolData = growthTool.response.data
    }
    console.log('growthTool.response', growthTool.response);
    // console.log('ZOOP 1', growthToolData.filter((e)=> e.referralUrl == true));
    // console.log('ZOOP 2', (growthToolData &&  growthToolData.filter((e)=> e.referralUrl == true).length > 0 ? growthToolData.filter((e)=> e.referralUrl == true)[0]?._id :   growthToolId) )


    let array = [];
    let csvDataArray = [];



    if (passes.success) {
      for (const pass of passes.data) {
        if (
          pass.userData &&
          pass.passType === PassDesign.PassTypes.LOYALTY_CARD
        ) {
          array.push({
            user: { _id: pass.userId, ...pass.userData, referralCode: brand.channelDomain ? ('https://'+brand.channelDomain+'/page/' + (growthToolData &&  growthToolData.filter((e)=> e.referralUrl == true).length > 0 ? growthToolData.filter((e)=> e.referralUrl == true)[0]?._id :   growthToolId)  + '?referralCode=' + pass.referalCode) :('https://catchly.io/page/' + (growthToolData &&  growthToolData.filter((e)=> e.referralUrl == true).length > 0 ? growthToolData.filter((e)=> e.referralUrl == true)[0]?._id :   growthToolId)  + '?referralCode=' + pass.referalCode )},
            pass: pass,
          });

          if((brand._id === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8")){

            csvDataArray.push({
              fullName: pass.userData.lastName
                ? pass.userData.firstName + " " + pass.userData.lastName
                : pass.userData.firstName,
              phone:
                pass.userData.phone
                    || "",
              email: pass.userData.email,
              entries: pass?.entries,
              currentpoints: pass?.loyaltyCard?.currentPoints,
              assigned: pass?.userData?.customFields?.assignedTo?.name || "",
              referral: brand.channelDomain? 'https://'+brand.channelDomain+'/page/' + growthToolId + '?referralCode=' + pass.referalCode:'https://catchly.io/page/' + growthToolId + '?referralCode=' + pass.referalCode
              // referral: 'https://yonga.app/page/' + growthToolId + '?referralCode=' + pass.referalCode
            });
          }
          else{
            csvDataArray.push({
              fullName: pass.userData.lastName
                ? pass.userData.firstName + " " + pass.userData.lastName
                : pass.userData.firstName,
              phone:
                pass.userData.phone
                    || "",
              email: pass.userData.email,
              currentpoints: pass?.loyaltyCard?.currentPoints,
              assigned: pass?.userData?.customFields?.assignedTo?.name || "",
              referral: brand.channelDomain? 'https://'+brand.channelDomain+'/page/' + growthToolId + '?referralCode=' + pass.referalCode:'https://catchly.io/page/' + growthToolId + '?referralCode=' + pass.referalCode
              // referral: 'https://yonga.app/page/' + growthToolId + '?referralCode=' + pass.referalCode
            });

          }


        }

      }

      setCsvData(csvDataArray);
    }

    dateSort(array);

    // console.log('1');

    // let transactions = await getTransactions();

    // console.log('2');

    // let index = 0;
    // if (transactions.success) {
    //   for (const customer of array) {
    //     for (const transaction of transactions.data) {
    //       if (customer.pass._id === transaction.passId) {
    //         array[index].transaction.push(transaction);
    //       }
    //     }
    //     index = index + 1;
    //   }
    // }

    // console.log('3');

    dateSort(array);
    setUpdate(false)
  };


  // // console.log('csvDataArray ', csvData)

  const next = async () => {
    let newArray = filteredCustomers;

    if (index <= customerData.length) {
      for (let i = index; i < index + 50; i++) {
        if (customerData[i]) newArray.push(customerData[i]);
      }
      setFilteredCustomers(newArray);
      setIndex(index + 50);
    }
  }

  const previous = async () => {
    let newArray = [];

    if (index >= 100) {
      for (let i = index - 100; i < index - 50; i++) {
        if (customerData[i]) newArray.push(customerData[i]);
      }
      setFilteredCustomers(newArray);
      setIndex(index - 50);
    }
  }

  const dateSort = async (customersList) => {
    let customers = customersList;
    function compare(a, b) {
      if (a.pass.created > b.pass.created) {
        return -1;
      }
      if (a.pass.created < b.pass.created) {
        return 1;
      }
      return 0;
    }

    let array = customers.sort(compare);
    localforage.setItem("customersAudience", array);
    setCustomerData(array);
    let newArray = [];
    let index = 0;
    for (let i = 0; i < 50; i++) {
      if (array[i]) newArray.push(array[i]);
    }
    setFilteredCustomers(newArray);
  };

  useEffect(async () => {
    onLoad();
  }, [update]);

  const handleUserSearch = (e) => {
    let data = customerData.filter((element, idx) => {
      if ((element.user.email.toLowerCase().includes(e.target.value.toLowerCase()) || (element.user.phone && element.user.phone[0] === '0' ? element.user.phone.includes(e.target.value.toLowerCase()) : ('0'+element.user.phone).includes(e.target.value.toLowerCase()))))
        return true;
    });
    setCurrentData(data);
  };

  const UserImagePart = ({ color, firstName, lastName, image, id, email }) => {
    return (

      <div className="d-flex align-items-center">
        {image ? (
          <img className="userImagePart" src={image} width="30" />
        ) : (
          utils.getAvatar({
            firstName: firstName,
            lastName: lastName,
            id: id,
            tableName: "customers",
            width: "38px",
            heigth: "38px",
            bgColor: color,
            // bgColor   : utils.getRandomColor(firstName)
          })
        )}
        &nbsp;&nbsp;
        <div>
          <div className=" color-neutral90 Heading15M capitalize">
            {lastName ? firstName + " " + lastName : firstName}
          </div>
          <div className="color-neutral70 Link13R">{email}</div>
        </div>
      </div>
    );
  };

  const history = useHistory()

  const handleSelectedCustomer = (customer) => {
    // setShow(true);
    // setSelectedCustomer(customer);
    history.push({
      pathname: window.location.pathname + '/update',
      state: customer
  })
  };

  const sort = (condition) => {
    if (condition === "name") {
      let array = filteredCustomers.sort((a, b) =>
        a.user.firstName.localeCompare(b.user.firstName)
      );

      setCustomerData(array);
      setFilteredCustomers(array);
    }
    if (condition === "date") {
      function compare(a, b) {
        if (a.pass.created > b.pass.created) {
          return -1;
        }
        if (a.pass.created < b.pass.created) {
          return 1;
        }
        return 0;
      }

      let array = filteredCustomers.sort(compare);

      setCustomerData(array);
      setFilteredCustomers(array);
    }
  };

  const handleAddCustomer = () => {
    setShowDetails({ ...show, showAddContact: true });
  };

  const getCampaigns = async (refresh) => {
    let campaign = await localforage.getItem("campaign");
    // if (!campaign || refresh) {
    if (!campaign) {
      const query = {
        brandId:
          brand.brandType === Brand.BrandTypes.SUB_BRAND
            ? brand.parentBrandId
            : brand._id,
        campaignType: 5,
      };
      const { error, response } = await CampaignService.GetQueriedCampaign({
        query,
      });
      if (error) return;
      if (response) localforage.setItem("campaign", { ...response.data[0] });
      if (response) campaign = response.data[0];
      if (response) console.log('lllpp', response.data[0]);

    }

    if (campaign) setCampaigns(campaign);
    setSelected({ ...selected, campaign: campaign });
    await getTiers(campaign[0]);
  };

  const getTiers = async (campaign) => {
    let tiers = await localforage.getItem("tiers");
    if (!tiers) {
      const query = {
        brandId:
          brand.brandType === Brand.BrandTypes.SUB_BRAND
            ? brand.parentBrandId
            : brand?._id,
        campaignId: campaign?._id,
        delete: false,
      };
      const { error, response } = await TierService.Get({ query });
      if (error) return;
      if (response) localforage.setItem("tiers", response.data);
      if (response) tiers = response.data;
    }

    if (tiers) setTiers(tiers);
  };

  const onLoadCampaigns = async (refresh) => {
    console.log('refresh', refresh);
    if (refresh) getCampaigns(true);
    else getCampaigns(true);
    // console.log("Current",currentData)
  };

  useEffect(onLoadCampaigns, []);
  const viewMoreItems = [
    {
      component: <div onClick={() => { setExportData(!exportData) }}>Export Datas</div>
    }
  ]


  console.log('filteredCustomers', filteredCustomers);
  console.log('curentData', currentData);

  let interval;


  const fetchData = async () => {
    if((!csvData) || csvData?.length<50){
      console.log('inn');
      
      let [passes, users] = await Promise.all([
        getPasses(),
      ]);
      console.log('passes', passes);
  
      let growthToolId
      let growthToolData
      const growthTool = await GrowthtoolService.GetPublicGrowthTool({ query: { growthtoolType: GrowthTools.GrowhttoolTypes.LANDING_PAGE, brandId: brand._id } })
      if (growthTool.response) {
        console.log('growthTool', growthTool?.response);
        growthToolId = growthTool.response.data[0]._id
        growthToolData = growthTool.response.data
      }
      console.log('growthTool.response', growthTool.response);
      // console.log('ZOOP 1', growthToolData.filter((e)=> e.referralUrl == true));
      // console.log('ZOOP 2', (growthToolData &&  growthToolData.filter((e)=> e.referralUrl == true).length > 0 ? growthToolData.filter((e)=> e.referralUrl == true)[0]?._id :   growthToolId) )
  
  
      let array = [];
      let csvDataArray = [];
  
  
  
      if (passes.success) {
        for (const pass of passes.data) {
          if (
            pass.userData &&
            pass.passType === PassDesign.PassTypes.LOYALTY_CARD
          ) {
            array.push({
              user: { _id: pass.userId, ...pass.userData, referralCode: brand.channelDomain ? ('https://'+brand.channelDomain+'/page/' + (growthToolData &&  growthToolData.filter((e)=> e.referralUrl == true).length > 0 ? growthToolData.filter((e)=> e.referralUrl == true)[0]?._id :   growthToolId)  + '?referralCode=' + pass.referalCode) :('https://catchly.io/page/' + (growthToolData &&  growthToolData.filter((e)=> e.referralUrl == true).length > 0 ? growthToolData.filter((e)=> e.referralUrl == true)[0]?._id :   growthToolId)  + '?referralCode=' + pass.referalCode )},
              pass: pass,
            });
  
            csvDataArray.push({
              fullName: pass.userData.lastName
                ? pass.userData.firstName + " " + pass.userData.lastName
                : pass.userData.firstName,
              phone:
                pass.userData.phone
                    || "",
              email: pass.userData.email,
              currentpoints: pass?.loyaltyCard?.currentPoints,
              assigned: pass?.userData?.customFields?.assignedTo?.name || "",
              referral: brand.channelDomain? 'https://'+brand.channelDomain+'/page/' + growthToolId + '?referralCode=' + pass.referalCode:'https://catchly.io/page/' + growthToolId + '?referralCode=' + pass.referalCode
              // referral: 'https://yonga.app/page/' + growthToolId + '?referralCode=' + pass.referalCode
            });
  
          }
  
        }
  
        setCsvData(csvDataArray);
      }
  
      dateSort(array);
  
      // console.log('1');
  
      // let transactions = await getTransactions();
  
      // console.log('2');
  
      // let index = 0;
      // if (transactions.success) {
      //   for (const customer of array) {
      //     for (const transaction of transactions.data) {
      //       if (customer.pass._id === transaction.passId) {
      //         array[index].transaction.push(transaction);
      //       }
      //     }
      //     index = index + 1;
      //   }
      // }
  
      // console.log('3');
  
      dateSort(array);
      setUpdate(false)
    }
    else{
      clearInterval(interval);
    }
  }

  useEffect(() => {
    interval = setInterval(() => {
      fetchData(); // Fetch data every 30 seconds
    }, 30000);
    return () => clearInterval(interval);
  }, [])
  


  return (
    <div id="audience" className={((currentData && currentData.length > 0) || (filteredCustomers && filteredCustomers.length>0) )&&"pl_40 pr_40 pb_32"}>
{console.log('csvData',csvData)
}
      {dropDown ? (
        <div
          style={{
            position: "absolute",
            background: "white",
            top: "64px",
            right: "40px",
            zIndex: "9999",
            width: "200px",
            height: "auto",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            backgroundColor: "rgb(243, 244, 247)",
          }}
        >
          {/* <div className="item">
            <ImportCSV />
          </div>
          <div className="line"></div> */}
          <div className="item">
            {csvData && (
              <CSVLink data={csvData} headers={headers}>
                Download CSV
              </CSVLink>
            )}
          </div>
          {/* <div className="line"></div>
          <div className="item">
            <CSVLink data={[]} headers={headersSample}>
              Sample CSV <span className="caption">(for Import)</span>
            </CSVLink>
          </div> */}
        </div>
        // </ClickOutside>
      ) : (
        ""
      )}
      <>
        {((currentData && currentData.length > 0) || (filteredCustomers && filteredCustomers.length>0) )&&<div className="search d-flex align-items-center justify-content-right w-35 fl_r pb_16  mt_16">
          {/* <div className="pt_8 pb_8 cp">
            <SvgIcons.FilterIcon />
          </div>
          <div className="color-neutral60 U14M pl_8 pr_16 pt_8 pb_8 cp">
            Filters
          </div> */}
          <CustomSearch
            placeholder={"Search By Email"}
            className={"w-100 searchField"}
            position={"start"}
            height="36px"
            onChange={handleUserSearch}
            disableEndIcon={true}
            filterShow={show.showFiter}
          />
        </div>}
        <div className="w-100 ">
          {/* <Loader /> */}
          {!filteredCustomers || filteredCustomers.length < 0 ? (
          <Loader />
          ) : (
            <div>
              {console.log('currentData',currentData)}
              
              {(((!currentData) || currentData.length <= 0) && ((!filteredCustomers) || filteredCustomers.length<=0) )&&
              brand?.integrations?.cloudwaitress?.length>0?
                  <div id="midloader" className="middle" style={{height: 'calc(100vh - 64px)'}}>
                    <div>
                    <div class="loader"></div>
                    </div>
                    <div className="Heading28B color-neutral100">Let's Test Your Integration</div>
                    <div className="Body22R color-neutral70 mt_8">• Place your test order on CloudWaitress <br></br>
                    • Confirm the order in CloudWaitress <br></br>
                    {/* • Click 'Continue' below to verify the integration */}
                    </div>
                  </div>
                  :
                  ((!filteredCustomers) || filteredCustomers.length<=0) &&
                  <div id="midloader" className="middle" style={{height: 'calc(100vh - 64px)'}}>
                    <div>
                    {/* <div class="loader"></div> */}
                    </div>
                    <div className="Heading28B color-neutral100">No Customers</div>
                    <div className="Body22R color-neutral70 mt_8">When you start getting new customers, It'll show up here
                    </div>
                  </div>
              }
              {((currentData && currentData.length > 0) || (filteredCustomers && filteredCustomers.length>0) )&&<div className="bar"></div>}
              {((currentData && currentData.length > 0) || (filteredCustomers && filteredCustomers.length>0) )&&<div className="d-flex w-100 color-neutral90 Link11M audienceTableGrid overflow-hidden">
                <div
                  className="col-5 pt_16 pb_16 cp "
                  onClick={() => {
                    sort("name");
                  }}
                >
                  {filteredCustomers
                    ? filteredCustomers.length + " CUSTOMER"
                    : "CUSTOMER"}
                </div>
                <div className="col-3 pt_16 pb_16 cp">PHONE NUMBER</div>
                {/* <div className="col-2 pt_16 pb_16 cp">Total Points</div> */}
                {((brand._id === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8"))&&<div className={((brand._id === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8"))?"col-1 pt_16 pb_16 cp":"col-2 pt_16 pb_16 cp"}>Entries</div>}
                <div className={((brand._id === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8"))?"col-1 pt_16 pb_16 cp":"col-2 pt_16 pb_16 cp"}>Current Points</div>
                <div
                  className="col-2 pt_16 pb_16 text-left cp"
                  onClick={() => {
                    sort("date");
                  }}
                >
                  SUBSCRIBED
                </div>
              </div>}
              {console.log('currentData',currentData)}
              {console.log('currentData filteredCustomers',filteredCustomers)}
              
              {((currentData && currentData.length > 0) || (filteredCustomers && filteredCustomers.length>0) )&&<div className="bar"></div>}
              {<>
              {currentData?.length > 0 && currentData.length < 50 ?
                  <>
                    <div className="data ">
                      {currentData.length > 0 &&
                        currentData.map((element, idx) => (
                          <>
                          {console.log('element',element)}
                          
                            <div
                              className="d-flex align-items-center w-100 dataRow tableRowHover"
                              onClick={() => {
                                handleSelectedCustomer(element);
                              }}
                            >
                              <div className="col-5">
                                <UserImagePart
                                  color={element?.pass?.color}
                                  email={element.user.email}
                                  firstName={element.user.firstName}
                                  customers={filteredCustomers}
                                  lastName={element.user.lastName}
                                  id={element.user._id}
                                  image={element.user.avatar}
                                />
                              </div>

                              <div className="col-3 color-neutral90 Link13R">

                                

                                {
                                  (element.user.phone == "" ? "-" : 
                                    (element?.user?.phone?.split(" ").length>1?(element?.user?.phone?.split(" ")[1]):
                                    element?.user?.phone))

                                }


                              </div>
                              {/* <div className='col-2 color-neutral70 Body14R'>
                                              {element.user.email}
                                          </div> */}
                              {((element?.pass?.brandId === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8"))&&<div className={((element?.pass?.brandId === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8"))?"col-1 color-neutral90 Link13R pl_32":"col-2 color-neutral90 Link13R pl_32"}>
                                {/* {element?.pass?.loyaltyCard?.totalPoints} */}
                                {element?.pass?.entries}
                              </div>}
                              <div className={((element?.pass?.brandId === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8"))?"col-1 color-neutral90 Link13R pl_32":"col-2 color-neutral90 Link13R pl_32"}>
                                {/* {element?.pass?.loyaltyCard?.totalPoints} */}
                                {element?.pass?.loyaltyCard?.currentPoints}
                              </div>
                              <div className="col-2 text-left color-neutral90 Link13R">
                                {new Date(element.pass.created).toDateString()}
                              </div>
                            </div>
                            <div className="bar"></div>
                          </>
                        ))}
                    </div>


                  </>

                  :

                  <div className="data ">
                    {update ? <Loader baseHeight={"80px"} /> : ''}
                    {
                      console.log("filteredCustomers", filteredCustomers)

                    }
                    {filteredCustomers.length > 0 &&
                      filteredCustomers.map((element, idx) => (
                        <>
{console.log('element',element)}
                          <div
                            className="d-flex align-items-center w-100 dataRow tableRowHover"
                            onClick={() => {
                              handleSelectedCustomer(element);
                            }}
                          >
                            <div className="col-5">
                              <UserImagePart
                                color={element?.pass?.color}
                                email={element.user.email}
                                firstName={element.user.firstName}
                                customers={filteredCustomers}
                                lastName={element.user.lastName}
                                id={element.user._id}
                                image={element.user.avatar}
                              />
                            </div>

                            <div className="col-3 color-neutral90 Link13R">

                              

                              {element.user.phone || "N/A"}
                            </div>

                            {((element?.pass?.brandId === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8"))&&<div className={((element?.pass?.brandId === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8"))?"col-1 color-neutral90 Link13R pl_32":"col-2 color-neutral90 Link13R pl_32"}>
                              {element?.pass?.entries}
                            </div>}
                            <div className={((element?.pass?.brandId === "LVUtlIdXhiiRGJQFxbIo")||(brand._id === "6669642914cb921dcd8a1bc8"))?"col-1 color-neutral90 Link13R pl_32":"col-2 color-neutral90 Link13R pl_32"}>
                              {element?.pass?.loyaltyCard?.totalPoints}
                            </div>
                            {/* <div className='col-2 color-neutral70 Body14R'>
                                            {element.user.email}
                                        </div> */}
                            <div className="col-2 text-left color-neutral90 Link13R">
                              {new Date(element.pass.created).toDateString()}
                            </div>
                          </div>
                          <div className="bar"></div>
                        </>
                      ))}

                  </div>}
              </>
              }
              {filteredCustomers && filteredCustomers.length > 40 && <div className="text-center loadMoreBox">
                {/* <CustomButton
                  btntext={'Previous'}
                  varient="secondary"
                  onClick={previous}
                  className={"fs-24"}
                /> */}
                {((currentData && currentData.length > 0) || (filteredCustomers && filteredCustomers.length>0) )&&<CustomButton
                  btntext={'Load More'}
                  onClick={next}
                  varient="primary"
                  className={"fs-24 m-4"}
                />}
              </div>}

            </div>
          )}
          <CustomsModal
            open={show}
            minWidth={"85vw"}
            maxHeight={"85vh"}
            minHeight={"85vh"}
            padding={"0px"}
            component={
              <CustomerModal
                onLoadCall={onLoadCall}
                fields={fields}
                setFields={setFields}
                customFields={customFields}
                setCustomFields={setCustomFields}
                susbscriberField={susbscriberField}
                setSubscriberField={setSubscriberField}
                onClose={() => setShow(false)}
                show={show}
                setShow={setShow}
                customer={selectedCustomer}
                filteredCustomers={filteredCustomers}
                setFilteredCustomers={setFilteredCustomers}
                customerData={customerData}
                setCustomerData={setCustomerData}
              />
            }
            onClose={() => setShow(false)}
          />

          {showDetails.showAddContact && (
            <CustomsModal
              component={
                <AddCustomerComponent
                  onClose={() =>
                    setShowDetails({ ...show, showAddContact: false })
                  }
                  show={showDetails}
                  setShow={setShowDetails}
                  selected={selected}
                  setFilteredCustomer={setFilteredCustomers}
                  setSelected={setSelected}
                  getPass={getPasses}
                  getTransactions={() => { }}
                  campaigns={campaigns}
                  setPasses={setPasses}
                  setTransactions={setTransactions}
                  setCustomers={setCustomers}
                  update={update}
                  setUpdate={setUpdate}
                />
              }
              open={showDetails.showAddContact}
              minWidth={"600px"}
            />
          )}
        </div>
      </>
    </div>
  );
}


const CustomerModal = ({
  customer,
  onLoadCall,
  filteredCustomers,
  setFilteredCustomers,
  customerData,
  setCustomerData,
  show,
  setShow,
  onClose,
  fields,
  susbscriberField,
  setSubscriberField,
  setCustomFields,
  customFields,
  setFields,
}) => {
  const [selected, setSelected] = useState("customer");
  const [customerUpdated, setCustomerUpdated] = useState(customer);
  const [loader, setLoader] = useState(false);

  useEffect(async () => {
    setLoader(true);
    const { response, error } = await TransactionService.GetQueriedTransactions({ query: { passId: customer.pass._id, delete: false } })
    // console.log('response', response, error);

    if (response && response.success) {
      setCustomerUpdated({ ...customer, transaction: response.data });
    }
    setLoader(false);
  }, [])


  const UserImagePart = ({ firstName, lastName, image, id }) => {
    return (
      <div className="text-center mt_16">
        <div className="d-flex justify-content-center">
          {image ? (
            <img className="userImagePart" src={image} width="30" />
          ) : (
            utils.getAvatar({
              firstName: firstName,
              lastName: lastName,
              id: id,
              tableName: "customers",
              customers: customer,
              width: "48px",
              heigth: "48px",
              bgColor: ColorSchemeCode.themeColor,
            })
          )}
        </div>
        <div className="middle fs-14 color-neutral100 fw-5 capitalize mt_8">
          {firstName + " " + lastName}
        </div>
      </div>
    );
  };


  let components = {
    customer: (
      <Customer
        onLoadCall={onLoadCall}
        fields={fields}
        setFields={setFields}
        customFields={customFields}
        setCustomFields={setCustomFields}
        susbscriberField={susbscriberField}
        setSubscriberField={setSubscriberField}
        customer={customerUpdated}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        customerData={customerData}
        setCustomerData={setCustomerData}
      />
    ),
    status: (
      <Status
        customer={customerUpdated}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        customerData={customerData}
        setCustomerData={setCustomerData}
        show={show}
        setShow={setShow}
      />
    ),
    transaction: (
      <Transaction
        customer={customerUpdated}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        customerData={customerData}
        setCustomerData={setCustomerData}
      />
    ),
    setting: (
      <Setting
        customer={customerUpdated}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        customerData={customerData}
        setCustomerData={setCustomerData}
        show={show}
        setShow={setShow}
      />
    ),
  };

  return (
    <div className="w-100 row position-relative" id="audienceModal">
      {console.log('111', customerUpdated)}
      {(loader) ? <Loader /> :
        <>
          <div className="col-4 col-xl-2 col-lg-3 col-md-3  bg-color-bgLightSecondary">
            <UserImagePart
              firstName={customer.user.firstName}
              customers={customer}
              lastName={customer?.user?.lastName || ''}
              id={customer.user._id}
              image={customer.user.avatar}
            />
            <div className="mt_54 fs-14">
              <div
                className={
                  selected === "customer"
                    ? "link active fw-5"
                    : "link color-neutral60"
                }
                onClick={() => {
                  setSelected("customer");
                }}
              >
                Customer Overview
              </div>
              <div
                className={
                  selected === "status"
                    ? "link active fw-5"
                    : "link color-neutral60"
                }
                onClick={() => {
                  setSelected("status");
                }}
              >
                Pass Status
              </div>
              {/* <div
                className={
                  selected === "transaction"
                    ? "link active fw-5"
                    : "link color-neutral60"
                }
                onClick={() => {
                  setSelected("transaction");
                }}
              >
                Transaction History
              </div> */}
              {/* <div
                className={
                  selected === "setting"
                    ? "link active fw-5"
                    : "link color-neutral60"
                }
                onClick={() => {
                  setSelected("setting");
                }}
              >
                Setting
              </div> */}
            </div>
          </div>

          <div className="crossIcon cp z-index-100" onClick={onClose}>
            <SvgIcons.CrossIcon />
          </div>

          <div className="col-8 col-xl-10 col-lg-9 col-md-9">
            {components[selected]}
          </div>
        </>}
    </div>
  );
};

const Status = ({
  customer,
  filteredCustomers,
  setFilteredCustomers,
  customerData,
  setCustomerData,
  show,
  setShow,
}) => {
  const brand = useContext(BrandContext);
  const agency = useContext(AgencyContext);

  const [downloadQrLoader, setDownloadQrLoader] = useState();
  const [qrLoader, setQrLoader] = useState();
  const [editLoader, setEditloader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [sendEmailLoader, setSendEmailLoader] = useState(false);

  const [text, setText] = useState(customer.pass.loyaltyCard.currentPoints);

  const qrcode = {
    size: 200,
    text: customer.pass.passCode,
    color: "#2F80ED",
  };

  const [state, setState] = useState({
    points: [
      {
        "Pass Code": customer.pass.passCode,
      },
      {
        "Point Summary": customer.pass.loyaltyCard.currentPoints,
      },
      {
        "Total Spending":
          brand?.currency?.symbol +
          " " +
          customer.pass.loyaltyCard.totalSpending.toFixed(2),
      },
      {
        "Total Visits":
          customer.pass.loyaltyCard && customer.pass.loyaltyCard.redeemCount
            ? customer.pass.loyaltyCard.redeemCount
            : "-",
      },
      {
        "Current Tier":
          customer.pass.loyaltyCard.currentTier &&
            customer.pass.loyaltyCard.currentTier.currentTierName
            ? customer.pass.loyaltyCard.currentTier.currentTierName
            : "",
      },
      {
        "Pass Status":
          customer.pass.isWallet ? 'Subscribed' : 'Un-Subscribed'
      },
    ],
  });

  const setAddress = async () => {
    const ipAddress = await LocationService.GetIpAddress();
    if (ipAddress.error) return;

    const { error, response } = await LocationService.GetLocation({
      ipAddress: ipAddress.response.ip,
    });
    if (error) return;

    const location = {
      lat: response.latitude,
      lng: response.longitude,
      address: response.city + " " + response.country_name,
    };

    return location;
  };

  const transaction = async () => {
    setEditloader(true);
    const location = await setAddress();
    console.log('SelfSingleLoyalityTransaction', location);
    console.log('agency', agency);
    let payload = {
      agencyId: agency._id,
      passId: customer.pass._id,
      location: location,
      brandId: brand._id,
      points:
        parseInt(text) < parseInt(customer.pass.loyaltyCard.currentPoints)
          ? parseInt(customer.pass.loyaltyCard.currentPoints) - parseInt(text)
          : parseInt(text) - parseInt(customer.pass.loyaltyCard.currentPoints),
      subtract:
        parseInt(text) < parseInt(customer.pass.loyaltyCard.currentPoints) &&
        true,
    };
    console.log('11', payload);
    let SelfSingleLoyalityTransaction = await TransactionService.SelfSingleLoyalityTransaction({
      toaster: true,
      payload,
    });
    console.log('SelfSingleLoyalityTransactionx', SelfSingleLoyalityTransaction)
    if (SelfSingleLoyalityTransaction.response.success) {
      customerData.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          // console.log('in 1', customerData[idx]);
          customerData[idx].pass.loyaltyCard = SelfSingleLoyalityTransaction.response.data.transaction.loyaltyCard.passData;
        }
      });
      // console.log('1');
      setCustomerData(customerData);
      filteredCustomers.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          filteredCustomers[idx].pass.loyaltyCard = SelfSingleLoyalityTransaction.response.data.transaction.loyaltyCard.passData;
        }
      });
      // console.log('2');
      setFilteredCustomers(filteredCustomers);
    }
    // console.log('12');
    localforage.setItem("customersAudience", customerData);
    setEdit(false);
    setEditloader(false);
  };



  return (
    <div id="info" className="pt_32 pl_60 pr_60">
      <div className="color-neutral100 fs-18 fw-5">Pass Status</div>
      <div className="bar mt_12"></div>
      {/* <div className="mt_16">
                    <div className="qrCard" style={{width: '220px'}}>

                        <div className="qrBox">
                            <QRCode qrcode={qrcode}/>

                            <div className="overlayBox"></div>

                            <div className="absoluteMiddle downloadButtonBox">
                                <div className="mt_16 downloadBtn middle" onClick   = {SendQrCode}>
                                    {sendEmailLoader ? <CircularProgress size={20} color={'inherit'}/> : <SvgIcons.EmailIcon color={ColorSchemeCode.Heading}/>}
                                </div>
                               
                                <div className="mt_16 downloadBtn middle" onClick   = {downloadQr}  >
                                    {downloadQrLoader ? <CircularProgress size={16} color={'inherit'}/> : <SvgIcons.DownloadIcon width={22} height={22} color={ColorSchemeCode.Heading}/>}
                                  
                                </div>
                            </div>
                        </div>


                    </div>
                </div> */}

      <div className="mt_32 pb_30">
        <div className="row">
          <div className="color-neutral100 fs-18 fw-5 col-8">Status</div>
          <div className="col-4 edit text-left">
            {!edit ? (
              <CustomButton
                btntext={"Edit Points"}
                onClick={() => {
                  setEdit(!edit);
                }}
                varient="tertiary"
                icon={
                  <SvgIcons.EditIcon
                    height={"18"}
                    width={"18"}
                    color={"#3770FB"}
                  />
                }
              />
            ) : (
              <div className="d-flex justify-content-end">
                <CustomButton
                  btntext={"Cancel"}
                  varient={"secondary"}
                  disabled={editLoader}
                  onClick={() => {
                    setEdit(false);
                  }}
                  className={"mr_8"}
                />
                <CustomButton
                  varient={"primary"}
                  btntext={"Save Changes"}
                  disabled={editLoader}
                  onClick={() => {
                    transaction();
                  }}
                  className={"fs-14 fw-5"}
                  icon={
                    editLoader ? (
                      <CircularProgress size={16} color={"inherit"} />
                    ) : (
                      ""
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>

        <div className="bar mt_12"></div>

        <div className="">
          <div className="componentBox w-100">
            {state?.points.map((element, idx) => (
              <div key={idx} className="topHeaderSection row">
                <div className="col-7 align-self-center pl_0 color-caption opacity-10 Body14R">
                  {Object.keys(element)}
                </div>
                <div className="color-neutral100 col-5 fs-14 fw-5 align-self-center ">
                  {idx === 1 ? (
                    <div className="d-flex">
                      {edit ? (
                        <CustomTextField
                          type="number"
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                          icon={<div>Points</div>}
                          position="end"
                          top={14}
                          left={33}

                        />
                      ) : (
                        <div>{text}</div>
                      )}
                    </div>
                  ) : idx !== 0 ? (
                    <div className="d-flex">
                      {idx == 1 && (
                        <span
                          className="mr_8"
                          style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                          }}
                        ></span>
                      )}
                      {Object.values(element)}
                    </div>
                  ) : (
                    <span className="copyPass">
                      <CopyText content={Object.values(element)} />
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Customer = ({
  customer,
  onLoadCall,
  filteredCustomers,
  setFilteredCustomers,
  customerData,
  setCustomerData,
  fields,
  susbscriberField,
  setSubscriberField,
  setCustomFields,
  customFields,
  setFields,
}) => {
  const brand = useContext(BrandContext);

  const [editLoader, setEditloader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(false);

  const [selectedName, setSelectedName] = useState(
    customer.pass.userData &&
      customer.pass.userData.customFields &&
      customer.pass.userData.customFields.assignedTo
      ? customer.pass.userData.customFields.assignedTo.name
      : undefined
  );

  const [firstName, setFirstName] = useState(customer.user.firstName);
  const [lastName, setLastName] = useState(customer.user.lastName);
  const [dateOfBirth, setdateOfBirth] = useState(customer.pass.birthday);
  const [refferedBy, setRefferedBy] = useState();
  const ref = useRef();
  const [phone, setPhone] = useState(
    customer.user.phone
  );

  const [selected, setSelected] = useState(
    customer.pass.userData &&
      customer.pass.userData.customFields &&
      customer.pass.userData.customFields.assignedTo
      ? customer.pass.userData.customFields.assignedTo._id
      : undefined
  );

  const [selectedCustomField, setSelectedCustomField] = useState(false);

  useEffect(() => {
    let array = [];
    customFields &&
      customFields.length > 0 &&
      customFields.map((element, idx) => {
        if (
          customer.pass.userData &&
          customer.pass.userData.customFields &&
          customer.pass.userData.customFields[element.key]
        ) {
          array.push({
            ...element,
            value: customer.pass.userData.customFields[element.key],
          });
        } else {
          array.push({ ...element, value: "-" });
        }
      });
    setCustomFields(array);
  }, []);
  const [state, setState] = useState();


  const updateUser = async () => {
    // console.log()
    setEditloader(true);
    let payload1 = {
      _id: customer.user._id,
      firstName: firstName,
      lastName: lastName,
      birthday: dateOfBirth,
      // birthday: new Date(dateOfBirth),
      brandId : brand._id
    };

    if (phone) payload1.phone = phone;

    if (selected || selectedCustomField) {
      let data = fields.filter((element, idx) => {
        if (element._id === selected) return true;
      })[0];

      const headers = { "x-api-key": brand.apiKey };
      let payload = { passId: customer.pass._id, customFields: {} };
      if (selected) {
        payload.customFields = {
          assignedTo: {
            name: data.key,
            email: data.email,
            phone: data.phoneNo,
            _id: data._id,
          },
        };
      }

      if (customFields) {
        for (const field of customFields) {
          payload.customFields[field.key] = field.value;
        }
      }


      const passUpdate = await PassService.Update({ payload, headers });
      console.log('passUpdate',passUpdate , payload);
      if (selected) setSelected(data._id);
      if (selected) setSelectedName(data.key);
      setEdit(false);
      setEditloader(false);
      setSelectedCustomField(false);
      onLoadCall();
    }
    console.log('payload1USer',payload1);

    const Updates = await UserService.Update({
      toaster: true,
      payload: payload1,
    });

    if (Updates.response) {
      console.log('Updates',Updates , payload1);
      customerData.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          customerData[idx].user.firstName = firstName;
          customerData[idx].user.lastName = lastName;
          customerData[idx].user.phone = phone;
          customerData[idx].user.birthday = dateOfBirth;
        }
      });
      setCustomerData(customerData);
      filteredCustomers.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          filteredCustomers[idx].user.firstName = firstName;
          filteredCustomers[idx].user.lastName = lastName;
          filteredCustomers[idx].user.phone = phone;
          filteredCustomers[idx].user.birthday = dateOfBirth;

        }
      });
      setFilteredCustomers(filteredCustomers);
    }
    localforage.setItem("customersAudience", customerData);
    setEdit(false);
    setEditloader(false);
  };



  const onLoad = async () => {
    const customers = await localforage.getItem('customersAudience');
    let found = customers.find((currentCustomer) => {
      return currentCustomer.pass._id == customer.pass.referredBy
    })
    // console.log('found ', found);
    // console.log('customer ', customer);
    setState({
      profile: [
        {
          "First Name": customer.user.firstName,
        },
        {
          "Last Name": customer.user.lastName || "-",
        },
        {
          Phone: 
            customer.user.phone
            || "N/A",
        },
        {
          "Email Address": customer.user.email || "-",
        },
        {
          DOB: customer.pass.birthday ? new Date(customer.pass.birthday).getDate() + "-" + (new Date(customer.pass.birthday).getMonth() + 1) + '-' + new Date(customer.pass.birthday).getFullYear() : "-",
        },
        {
          "Assigned To": '-',
        },
        {
          "Referral": customer.user.referralCode ? customer.user.referralCode : '-',
        },
        {
          "Referred By": found ? found.pass.userData.firstName + " " + found.pass.userData.lastName : "-",
        },
      ],
      brandInfo: [
        {
          "Subscribed Brand": brand.brandName,
        },
        {
          Status: customer.pass.delete ? "Un active" : "Active",
        },
        {
          Tags: "-",
        },
        {
          "Subscription Date": new Date(customer.pass.created).toDateString(),
        },
      ],
    })


  }

  useEffect(onLoad, [])

  // console.log('refferedBy ', customer);

  return (
    <div id="info" className="pt_32 pl_60 pr_60">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div div className="row">
            <div className="color-neutral100 fs-18 fw-5 col-8 pt_8">Profile</div>
            <div className="col-4 edit text-left">
              {!edit ? (
                <CustomButton
                  style={{ marginLeft: "80px" }}
                  varient={"tertiary"}
                  btntext={"Edit Profile"}
                  onClick={() => {
                    setEdit(!edit);
                  }}
                  icon={
                    <SvgIcons.EditIcon
                      height={"18"}
                      width={"18"}
                      color={"#3770FB"}
                    />
                  }
                />
              ) : (
                <div className="d-flex justify-content-end">
                  <CustomButton
                    btntext={"Cancel"}
                    varient={"secondary"}
                    disabled={editLoader}
                    onClick={() => {
                      setEdit(false);
                    }}
                    className={"mr_8"}
                  />

                  <CustomButton
                    varient={"primary"}
                    btntext={"Save Changes"}
                    disabled={editLoader}
                    onClick={() => {
                      updateUser();
                    }}
                    className={"fs-14 fw-5 w-100"}
                    icon={
                      editLoader ? (
                        <CircularProgress size={16} color={"inherit"} />
                      ) : (
                        ""
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="bar mt_12"></div>
          <div>
            <div className="componentBox w-100">
              {state?.profile.map((element, idx) => (
                <div key={idx} className="topHeaderSection row">
                  <div className="col-7 align-self-center pl_0 color-caption opacity-10 Body14R">
                    {Object.keys(element)}
                  </div>
                  <div className="color-neutral100 col-5 fs-14 fw-5 align-self-center ">
                    {idx === 0 ? (
                      <div className="d-flex">
                        {edit ? (
                          <CustomTextField
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        ) : (
                          <div>{firstName}</div>
                        )}
                      </div>
                    ) : idx === 1 ? (
                      <div className="d-flex">
                        {edit ? (
                          <CustomTextField
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        ) : (
                          <div>{lastName}</div>
                        )}
                      </div>
                    )
                      : idx === 4 ? (
                        <div className="d-flex">
                          {edit ? (
                            // <CustomTextField
                            //   value={dateOfBirth}
                            //   onChange={(e) => setdateOfBirth(e.target.value)}
                            // />
                            <DatePicker
                              onChange={(e) => {
                                const selectedDate = new Date(e);
                                console.log('event date', new Date(e).toUTCString());
                                const localDate = new Date(e);
                                const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
                                console.log('utcDateString',new Date(utcDate).valueOf())
                                setdateOfBirth(new Date(utcDate).valueOf())

                              }}
                              selected={ dateOfBirth && new Date(dateOfBirth)}

                              icon="fa fa-calendar"
                              style={{padding : '12.5px 14px'}}
                              className="pt_12 pr_14 pb_12 pl_14"
                            />
                          ) : (
                            <div>{dateOfBirth ? new Date(dateOfBirth).getDate() + "-" + (new Date(dateOfBirth).getMonth() + 1) + '-' + new Date(dateOfBirth).getFullYear() : '-'}</div>
                          )}
                        </div>
                      ) :
                        idx === 2 ? (
                          <div className="d-flex">
                            {edit ? (
                              <div className="row">
                                <div className="col-12">
                                  <CustomTextField
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />{" "}
                                </div>
                              </div>
                            ) : (
                              <div>
                                {customer.user.phone||"N/A"}
                              </div>
                            )}
                          </div>
                        ) : idx === 5 ? (
                          <div className="d-flex">
                            {edit ? (
                              <div>
                                <select
                                  className="color-neutral100"
                                  value={selected}
                                  onChange={(e) => {
                                    setSelected(e.target.value);
                                  }}
                                >
                                  <option>none</option>
                                  {fields.map((element, idx) => (
                                    <option value={element._id}>
                                      {element.key}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              <div>{selectedName || "-"}</div>
                            )}
                          </div>
                        ) : (
                          <>{console.log('Object.values(element)', Object.values(element))}
                            <div className="d-flex">{Object.values(element)}</div></>
                        )}
                  </div>
                </div>
              ))}
              {susbscriberField.length > 0 &&
                susbscriberField.map((element, idx) => (
                  <div key={idx} className="topHeaderSection row">
                    <div className="col-7 align-self-center pl_0 color-caption opacity-10 Body14R">
                      {element.label}
                    </div>
                    <div className="color-neutral100 col-5 fs-14 fw-5 align-self-center ">
                      {edit ? (
                        element.moreValues ? (
                          <select
                            onChange={(e) => {
                              customFields[idx].value = e.target.value;
                              setSelectedCustomField(true);
                            }}
                            value={customFields[idx].value}
                          >
                            <option value={element.value}>
                              {element.value}
                            </option>
                            {element.moreValues.map((item, idx) => (
                              <option value={item.value}>{item.value}</option>
                            ))}
                          </select>
                        ) : (
                          <CustomTextField
                            value={customFields[idx].value}
                            onChange={(e) => {
                              let fieldArray = [];
                              customFields.map((field, index) => {
                                if (index === idx) {
                                  fieldArray.push({
                                    ...field,
                                    value: e.target.value,
                                  });
                                } else {
                                  fieldArray.push(field);
                                }
                              });
                              setCustomFields(fieldArray);
                              setSelectedCustomField(true);
                            }}
                          />
                        )
                      ) : (
                        <div>
                          {(customFields && customFields[idx].value) || "-"}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="color-neutral100 fs-18 fw-5 mt_32">Brand Info</div>
          <div className="bar mt_12"></div>
          <div className="">
            <div className="componentBox w-100">
              {state?.brandInfo.map((element, idx) => (
                <div key={idx} className="topHeaderSection row">
                  <div className="col-7 align-self-center pl_0 color-caption opacity-10 Body14R">
                    {Object.keys(element)}
                  </div>
                  <div className="color-neutral100 col-5 fs-14 fw-5 align-self-center ">
                    {idx === 1 ? (
                      <div
                        className={
                          Object.values(element)[0] === "Active"
                            ? "active"
                            : "inactive"
                        }
                      >
                        {Object.values(element)}
                      </div>
                    ) : (
                      <div className="d-flex">{Object.values(element)}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Transaction = ({ customer }) => {
  return (
    <div id="transaction" className="pt_32 pl_60 pr_60">
      <div className="color-neutral100 fs-18 fw-5 pt_8">Transactions</div>
      <div className="bar mt_12"></div>
      <TransactionHistory customer={customer} />,
    </div>
  );
};

const AddCustomerComponent = ({
  onClose,
  getTransactions,
  setTransactions,
  setPasses,
  getPass,
  show,
  setShow,
  setFilteredCustomer,
  selected,
  setSelected,
  campaigns,
  setCustomers,
  update,
  setUpdate
}) => {
  const agency = useContext(AgencyContext);
  console.log('selected', selected);

  const initState = {
    firstName: "",
    email: "",
    lastName: "",
    phone: "",
    birthday: "",
  };

  const [loader, setLoader] = useState(false);
  const brand = useContext(BrandContext);

  const handleSubmit = async () => {
    setLoader(true);
    const fullPhone = utils.getCountryCode(formik.values.fullPhone.includes('+') ? formik.values.fullPhone : '+' + formik.values.fullPhone)

    console.log('fullPhone ', fullPhone);

    let payload = {
      firstName: formik.values.firstName,
      email: formik.values.email,
      campaignCode: selected.campaign.campaignCode,
      brandId: selected.campaign.brandId,
      lastName: formik.values.lastName,
      phone: fullPhone.withoutCountryCode.replace(/\s/g, ''),
      birthday: new Date(formik.values.birthday).valueOf(),
    };
    console.log('payload ', payload);

    if (formik.values.birthday) payload.birthday = new Date(formik.values.birthday).valueOf()

    const headers = { "x-api-key": brand.apiKey };

    const { response, error } = await PassService.Create({
      toaster: true,
      payload,
      headers,
    });
    if (response) {
      console.log('response.data', response.data);
      const query = {
        _id:
          brand.brandType === Brand.BrandTypes.SUB_BRAND
            ? brand.parentBrandId
            : brand._id,
        userId: response.data.userId,
        delete: false,
      };
      const subscriber = await BrandService.GetSubscribers({ query });
      if (subscriber.response) {
        let customers = await localforage.getItem("totalcustomers");
        customers.push(subscriber.response.data[0]);
        // localForage.setItem('customers', customers)
        setCustomers(customers);
        setFilteredCustomer(customers);

        let pass = await getPass(subscriber.response.data[0]);
        setPasses((prevState) => [...prevState, pass]);
        let transaction =
          pass &&
          (await getTransactions(subscriber.response.data[0], pass._id));
        setTransactions((prevState) => [
          ...prevState,
          { [pass._id]: transaction },
        ]);
      }
      setShow({ ...show, addCustomer: false, showAddContact: false });
    } else {
      console.log('err', error);
    }

    setLoader(false);
    setUpdate(true)

  };


  // useEffect(() => {

  //   handleSubmit();

  // }, [loadData])



  const formik = useFormik({
    // validateOnChange : false,
    // validateOnBlur   : false,
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: PassVld.Create,
  });
  console.log("AAAA", formik.values)
  return (
    <div className="addCustomerComponent" id="customModal">
      <form>
        <div className="Heading22R color-neutral80">Add Customer</div>
        <div className="customLabel mt_16">Select Campaign</div>
        <div className="campaign-dropdown w-100 mt_0 position-relative mt_8">
          <ClickOutside
            onClick={(e) =>
              show.addCustomerDropDown &&
              setShow({ ...show, addCustomerDropDown: false })
            }
          >
            <div
              onClick={() =>
                setShow({
                  ...show,
                  addCustomerDropDown: !show.addCustomerDropDown,
                })
              }
            >
              <span className="fs-16" style={{ color: '#525252' }}>{selected.campaign.campaignName}</span>
              {
                <span className="float-right">
                  <SvgIcons.DropDownTriangleIcon
                    height="12px"
                    width="10px"
                    color={ColorSchemeCode.c828282}
                  />
                </span>
              }
            </div>
            {show.addCustomerDropDown && (
              <CampaignsDropDown
                title={"Campaigns"}
                campaignList={campaigns}
                onSelect={(campaign) => {
                  setSelected({ ...selected, campaign });
                  setShow({ ...show, addCustomerDropDown: false });
                }}
              />
            )}
          </ClickOutside>
        </div>
        <div className="mt_32 w-100">
          <CustomTextField
            label="First Name"
            className="w-100"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && formik.errors.firstName}
            helperText={
              formik.touched.firstName &&
              formik.errors.firstName &&
              formik.errors.firstName
            }
          />
        </div>
        <div className="mt_29 w-100">
          <CustomTextField
            label="Last Name"
            className="w-100"
            labelPaddingLeft="0px"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            inputProps={{ onFocus: formik.handleBlur }}
            error={formik.touched.lastName && formik.errors.lastName}
            helperText={
              formik.touched.lastName &&
              formik.errors.lastName &&
              formik.errors.lastName
            }
          />
        </div>
        <div className="phoneNumber d-flex mt_21" >
          <div className="w-100 pl_0 " id="PhoneInputField input">
            <label
              className="mb_4 fs-12 fw-5 control-label"
              style={{
                color: "rgb(75, 78, 91)",
                letterSpacing: "0.1px",
                transition: "all 0.2s ease-in-out 0s",
              }}
            >
              Phone
            </label>
            {console.log('formik ', formik.values)}
            <PhoneInput

              inputClass={'w-100 Body14R phoneInputField'}
              inputStyle={{ border: '1px solid ' + ColorSchemeCode.OtherBorder, height: '42px' }}
              value={formik.values.fullPhone || ''}
              onChange={

                phone => formik.setValues({ ...formik.values, fullPhone: phone })

              }
            />
          </div>
        </div>
        <div className="mt_29 w-100">
          <CustomTextField
            label="Email Address"
            className="w-100"
            labelPaddingLeft="0px"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            inputProps={{ onFocus: formik.handleBlur }}
            error={formik.touched.email && formik.errors.email}
            helperText={
              formik.touched.email && formik.errors.email && formik.errors.email
            }
          />
        </div>
        <div className="mt_21 w-100">
          <div className="customLabel">Birthday</div>
          {/* <CustomDatePicker  */}
          <CustomTextField
            fontSize="14px"
            className="w-100 date-icon-ssp"
            type="date"
            name="birthday"
            value={formik.values.birthday}
            onChange={formik.handleChange}
            inputProps={{ onFocus: formik.handleBlur }}
            error={formik.touched.birthday && formik.errors.birthday}
            helperText={
              formik.touched.birthday &&
              formik.errors.birthday &&
              formik.errors.birthday
            }
          />
        </div>
        <div className="d-flex justify-flex-end mt_32">
          <div className="mr_8">
            <CustomButton
              varient="secondary"
              btntext="Cancel"
              onClick={onClose}
            />
          </div>
          <div>
            <CustomButton
              varient="primary"
              btntext="Add"
              onClick={handleSubmit}
              disabled={formik.values.firstName == '' || formik.values.firstName == false || formik.values.email == '' || formik.values.birthday == '' || formik.values.lastName == '' || formik.values.fullPhone == ''}
              icon={loader && <CircularProgress size={20} color={"inherit"} />}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const Setting = ({
  customer,
  filteredCustomers,
  setFilteredCustomers,
  customerData,
  setCustomerData,
  show,
  setShow,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const handleDelete = async () => {
    setDeleteLoader(true);
    const { error, response } = await PassService.DeletePass({
      payload: { passId: customer.pass._id, delete: false },
    });

    if (response) {
      customerData.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          customerData.splice(idx, 1);
        }
      });
      setCustomerData(customerData);
      filteredCustomers.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          filteredCustomers.splice(idx, 1);
        }
      });
      setFilteredCustomers(filteredCustomers);
    }
    localforage.setItem("customersAudience", customerData);
    setDeleteLoader(false);
    setDeleteModal(false);
    setShow(false);
  };

  return (
    <div id="settingComponentAudience">
      <div className="buttonDelete">
        <div className="Heading16M color-neutral100 mb_4">Delete Contact</div>
        <div className="Body14R color-neutral60">
          Once you delete your contact, there is no going back. Please be
          certain.
        </div>
        <div className="bar mt_12 mb_24"></div>
        <CustomButton
          btntext={"Delete this contact"}
          varient="warningSecondary"
          onClick={() => {
            setDeleteModal(true);
          }}
        />

        <CustomsModal
          open={deleteModal}
          minWidth={"600px"}
          component={
            <DeleteModal
              icon={deleteLoader}
              title="Subscriber"
              onCancel={() => setDeleteModal(false)}
              onDelete={handleDelete}
            />
          }
          onClose={() => setDeleteModal(false)}
        />
      </div>
    </div>
  );
};


