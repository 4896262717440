import * as yup from 'yup';
import { Brand, enums } from '../enums'

const REQ_ERROR = 'This field is required'


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const location = yup.object({
    lat         : yup.number().required(REQ_ERROR),
    lng         : yup.number().required(REQ_ERROR),
    address     : yup.string().required(REQ_ERROR),
    city        : yup.string().required(REQ_ERROR),
    country     : yup.string().required(REQ_ERROR),
    countryCode : yup.string(),
    offset      : yup.string(),
    state       : yup.string()
})

const updateRedemptionType  = yup.object({
    brandRedemptionType   : yup.string().required(REQ_ERROR),
})

const currency = yup.object({
    code   : yup.string().required(REQ_ERROR),
    symbol : yup.string().required(REQ_ERROR)
})

const manychat = yup.object({
    apiKey : yup.string()
})


const walletApp = yup.object({
    android: yup.string(),
    apple  : yup.string()
}) 

const discountDetail = yup.object({
    discountType : yup.string().required().oneOf(Object.values(Brand.DiscountType)),
    discountOffer: yup.mixed()
                    .when('discountType',{
                        is  : value => value === Brand.DiscountType.CUSTOM,
                        then: yup.string().required(),
                    }),
    discountPercent: yup.mixed()
                    .when('discountType',{
                        is  : value => value === Brand.DiscountType.FIXED_AMOUNT,
                        then: yup.number().required(),
                    }),
                    
    discountImage : yup.mixed()
                    .when('discountType',{
                        is  : value => value === Brand.DiscountType.CUSTOM,
                        then: yup.string().required(),
                    }),
})

const CreateBrand = yup.object({
    brandName       : yup.string().required(REQ_ERROR),
    description     : yup.string().required(REQ_ERROR),
    brandColor      : yup.string(),
    appleWalletShow : yup.boolean().required(),
    location        : location.required(),
    currency        : currency.required(),
    brandType       : yup.string().required().oneOf(Object.values(Brand.BrandTypes)),
    phone           : yup.string().matches(/^\d{1,10}$/, 'Phone number is not valid'),
    
    // sharedCampaignHolder: yup.mixed()
    //                         .when('brandType',{
    //                             is  : value => value === Brand.BrandTypes.SHARED_LOYALITY,
    //                             then: discountDetail,
    //                         }),

    // parentBrandId       : yup.mixed()
    //                     .when('brandType',{
    //                         is  : value => value === Brand.BrandTypes.SHARED_LOYALITY,
    //                         then: yup.string(),
    //                     }),
        
    businessService : yup.string().required(REQ_ERROR),
    onlineService   : yup.string(),
    brandLogo       : yup.string(),
    brandCover      : yup.string(),
    walletApp       : walletApp,
    brandCoverFile  : yup.mixed()
                        .test('coverSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true) // true will neglect the validation case
                        .test('coverType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true),
    brandLogoFile   : yup.mixed()
                        .test('imageSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                        .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('brandLogo',{
                            is  : value => !value,
                            then: yup.mixed().required(REQ_ERROR)
                        })
})

const UpdateBrand = yup.object({
    email1       : yup.string().email(),
    phone          : yup.string().required().matches(/^\d{1,10}$/),
    // .matches(/^(\+?\d{1,9}|\d{1,4})$/),
    brandName      : yup.string().required(REQ_ERROR),
    brandColor     : yup.string().required(REQ_ERROR),
    location       : location.required(),
    currency       : currency.required(),
    businessService: yup.string().required(REQ_ERROR),
    onlineService  : yup.string(),
    brandLogo      : yup.string(),
    postPaid       : yup.boolean(),
    brandCover     : yup.string(),
    campaignType   : yup.number(),
    walletApp      : walletApp,
    upgradePlanUrl : yup.string(),
    brandCoverFile : yup.mixed()
                        .test('coverSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true) // true will neglect the validation case
                        .test('coverType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('brandCover',{
                            is   : value => !value,
                            then : yup.mixed()
                        }),
    bannerImageFile : yup.mixed()
                        .test('coverSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true) // true will neglect the validation case
                        .test('coverType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('brandCover',{
                            is   : value => !value,
                            then : yup.mixed()
                        }),
    brandLogoFile   : yup.mixed()
                        .test('imageSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                        .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('brandLogo',{
                            is   : value => !value,
                            then : yup.mixed().required(REQ_ERROR)
                        })

})

const Integrations = yup.object({
    manychat    : manychat,
    awsEmail    : yup.string(),
})

const VipClub = yup.object({
    webhook  : yup.string().required(),
    secretKey: yup.string().required(),
    points   : yup.number().min(1).required("Points should be greater than 0"),
    headingOne:yup.string().min(3),
    headingTwo:yup.string().min(3),
})

const Billing = yup.object({
    email       : yup.string().email().required(),
    companyName : yup.string().required()
})


export {
    CreateBrand,
    UpdateBrand,
    Integrations,
    Billing,
    updateRedemptionType,
    VipClub
}