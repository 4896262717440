// import "./style.css";
import { utils } from "../../utils";
import { Autocomplete } from "./Autocomplete";
import { allCountries } from 'country-telephone-data';

export default function SearchBrand({formik,setSearch}) {
  const handleSubmit = ({ value, query, queries }) => {
    // Query has been clicked if query is undefined.
    console.log(value, query, queries);

    const options = {method: 'GET', headers: {Authorization: 'Bearer aPrQEP5f+Bs7zN/dChxV2lSCxi2Nj9+rr0/NBxJxs1w='}};

    console.log('value.domain',value);
    console.log('value.query',query);

    fetch('https://api.brandfetch.io/v2/brands/'+query.domain, options)
    .then(response => response.json())
    .then(response => {
        console.log('logo',response?.logos[0]?.formats[0]?.src)
        console.log('response',response)
        console.log('response1',allCountries.find(c => c.iso2 === response?.company?.location?.countryCode?.toLowerCase())?.dialCode)


        


        formik.setValues({...formik.values, 
            brandName : response?.name,
            brandLogo : response?.logos[0]?.formats[0]?.src,
            brandCover : response?.images[0]?.formats[0]?.src,
            // brandLogoFile : response?.logos[0]?.formats[0]?.src,
            brandColor : response?.colors[0]?.hex,
            location              : {
                lat         : "",
                lng         : "",
                address     : "",
                city        : response?.company?.location?.city,
                country     : response?.company?.location?.country,
                countryCode : response?.company?.location?.countryCode,
                offset      : "",
                state       : response?.company?.location?.state,
              },
            currency : utils.getCurrency({currencyCode: response?.company?.location?.countryCode}),
            description : response?.longDescription || response?.description || "",
            website : response?.domain,
        })
        // formik.setValues({...formik.values})
        setSearch(false)
    }
    )
    .catch(err => console.error('err',err));
  };

  return (
    <div className="App">
      <Autocomplete
        onSubmit={handleSubmit}
        placeholder="Enter a brand to get started..."
      />
    </div>
  );
}
