
import React, { useContext, useState , useRef, useEffect } from 'react'

import CircularProgress from '@mui/material/CircularProgress';

import { useFormik } from 'formik';
import Loforage, * as localforage from 'localforage'


import CustomTextField from '../../../components/CustomTextField';
import CustomTextArea from '../../../components/CustomTextArea';
import CustomSelect from '../../../components/CustomSelect';
import CustomCheckBox from '../../../components/CustomCheckBox';

import { Options, utils } from '../../../utils';

import { BrandContext } from '../../../context/Brand.context';
import { LayoutContext } from '../../../context/Layout.context';
import { BrandVld, UserVld } from '../../../validation';
import { BrandService, UserService } from '../../../services';
import { PngIcons, SvgIcons } from '../../../icons';
import LogoUploader from '../../../components/LogoUploader';
import CustomsModal from '../../../components/CustomModal';
import CustomButton from '../../../components/CustomButton';
import CopyText from '../../../components/CopyText';
import { Brand } from '../../../enums'
import { ImageModalV2 } from '../../../components/ImageModalV2/ImageModalV2';
import { AgencyContext } from '../../../context/Agency.context';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import Location from './Location';
import Setting from './Settings';
import { UserContext } from '../../../context/User.context';
import axios from 'axios';
import { FormControlLabel, FormGroup, styled, Switch } from '@mui/material';


export default function General(props) {

    const brand = useContext(BrandContext)
    const agency = useContext(AgencyContext)
    const layout = useContext(LayoutContext)
    const user = useContext(UserContext);

    const [state, setState] = useState({
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
        termConditions :false
    })
 





    const initState = {
        appleWalletShow: brand.appleWalletShow || false,
        phone: brand.phone,
        fullPhone: brand.phone,
        brandName: brand.brandName,
        brandColor: brand.brandColor || "#2f80ed",
        description: brand.description,
        // emailCheck: brand.emailCheck || false,
        email1: brand.email1 || user.email || '',
        disclaimer: brand.disclaimer,
        currency: brand.currency,
        businessService: brand.businessService,
        onlineService: brand.onlineService,
        brandLogo: brand.brandLogo,
        bannerImage: brand?.bannerImage||'',
        inStoreReward: brand.inStoreReward || "false",
        pointStoreOffering: brand.pointStoreOffering || "false",
        brandCover: brand.brandCover,
        upgradePlanUrl: brand.upgradePlanUrl ? brand.upgradePlanUrl : '',
        walletApp: {
            android: brand.walletApp ? brand.walletApp.android : '',
            apple: brand.walletApp ? brand.walletApp.apple : ''
        },
        remotePointCheck: brand?.remotePointCheck || Brand.RemotePointCheck.APP,
        vip: brand.vip || false,
        tier: brand.tier || false,
        location: brand.location,
        multipleLocations: brand.multipleLocations || [],
        termsAndConditions : '',
        website : brand.website || ''
    }



    const formik = useFormik({
        initialValues: { ...initState },
        validationSchema: BrandVld.UpdateBrand,
        isInitialValid: true,
        enableReinitialize: true
    })

    React.useEffect(() => {
        layout.setLayout({
            title: 'Settings',
            borderBottom: false,
            switch: "",
            button: ""
        })
    }, [formik.values, formik.errors, state.loader])

    console.log('formik ', formik.values)


    return (
        <div id='General'>
            <form onSubmit={formik.handleSubmit}>
                <div className="ml_1">
                    <BrandSetting state={state} setState={setState} formik={formik} brand={brand} initState={initState} />
                </div>
            </form>
        </div>
    )
}

const BrandSetting = ({ formik, initState, brand, state, setState }) => {

    const [imageModal, setImageModal] = useState({
        logo: { open: false, imageEvent: '', return: '' },
        cover: { open: false, imageEvent: '', return: '' },
        bannerImage: { open: false, imageEvent: '', return: '' }
    })

    const [brandNameLoader, setBrandNameLoader] = useState(false)


    const handleBrandName = async (e) => {
        if (e.target.value === initState.brandName || !e.target.value) return

        setBrandNameLoader(true)
        const brandName = e.target.value
        const query = { brandName, delete: false }
        const { error, response } = await BrandService.Get({ query })
        if (response) {
            formik.setErrors({ brandName: 'Brand name already exist' })
            return setBrandNameLoader(false)
        }
        return setBrandNameLoader(false)
    }


    const handlePublicProfileUpdateFunc = async () => {
        setState({ generalLoader: true, apiError: '' })

        const fullPhone = utils.getCountryCode(formik.values.fullPhone.includes('+') ? formik.values.fullPhone : '+' + formik.values.fullPhone)

        let logo, cover, bannerImage
        const user = await localforage.getItem('user')
        const desiredPath = `${user._id}/${formik.values.brandName}/logo/${formik.values.brandLogoFile?.name.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '')}/${formik.values.brandLogoFile?.size}`
        const desiredPath1 = `${user._id}/${formik.values.brandName}/cover/${formik.values.brandCoverFile?.name.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '')}/${formik.values.brandCoverFile?.size}`
        const desiredPath2 = `${user._id}/${formik.values.brandName}/bannerImage/${formik.values.bannerImageFile?.name.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '')}/${formik.values.bannerImageFile?.size}`

        // uploading logo
        if (formik.values.brandLogoFile) {
            logo = await utils.uploadImage({ file: formik.values.brandLogoFile, desiredPath })
            setState({ loader: false, brandingLoader: true, apiError: "" })
        }
        if (logo && logo.error) return setState({ loader: false, brandingLoader: false, apiError: logo.error.message })

        // uploading cover
        if (formik.values.brandCoverFile) {
            setState({ loader: false, brandingLoader: true, apiError: "" })
            cover = await utils.uploadImage({ file: formik.values.brandCoverFile, desiredPath: desiredPath1 })
        }
        if (cover && cover.error) return setState({ loader: false, brandingLoader: false, apiError: cover.error.message })
        
            // uploading bannerImage
        if (formik.values.bannerImageFile) {
            setState({ loader: false, brandingLoader: true, apiError: "" })
            bannerImage = await utils.uploadImage({ file: formik.values.bannerImageFile, desiredPath: desiredPath2 })
        }
        if (bannerImage && bannerImage.error) return setState({ loader: false, brandingLoader: false, apiError: bannerImage.error.message })

        const permissions = await localforage.getItem('permissions')

        if (formik.values.brandLogoFile) {
            permissions.map((element, idx) => {
                if (element.businessId === brand._id) permissions[idx].logo = logo.response.data;
            })
            localforage.setItem('permissions', permissions)
        }

        if (formik.values.brandName) {
            permissions.map((element, idx) => {
                if (element.businessId === brand._id) permissions[idx].brandName = formik.values.brandName;
            })
            localforage.setItem('permissions', permissions)
        }

        const payload = {
            _id: brand._id,
            brandType: brand.brandType,
            brandName: formik.values.brandName,
            description: formik.values.description,
            disclaimer: formik.values.disclaimer,
            brandColor: formik.values.brandColor,
            currency: formik.values.currency,
            appleWalletShow: formik.values.appleWalletShow,
            businessService: formik.values.businessService,
            locationType: formik.values.businessService,
            onlineService: formik.values.onlineService,
            brandLogo: formik.values.brandLogo,
            brandCover: formik.values.brandCover,
            bannerImage: formik.values.bannerImage,
            // emailCheck: formik.values.emailCheck,
            email1: formik.values.email1 || user.email,
            email: user.email,
            inStoreReward: formik.values.inStoreReward == "false" ? false : true,
            pointStoreOffering: formik.values.pointStoreOffering == "false" ? false : true,
            phone: fullPhone.withoutCountryCode.replace(/\s/g, ''),
            upgradePlanUrl: formik.values.upgradePlanUrl,
            walletApp: {
                android: formik.values.walletApp.android,
                apple: formik.values.walletApp.apple,
            },
            vip: formik.values.vip,
            tier: formik.values.tier,
            remotePointCheck: formik.values.remotePointCheck,
            location: formik.values.location,
            multipleLocations: formik.values.multipleLocations,
            termsAndConditions: formik.values.termsAndConditions,
            website : formik.values.website
        }

        try {
            const url = 'https://rest.gohighlevel.com/v1/custom-values/';
            const config = {
              headers: {
                Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
              }
            };
            const dataName = {
              name: "brandName",
              value: payload.brandName||""
            };
            const dataLogo = {
              name: "brandLogo",
              value: decodeURIComponent(payload.brandLogo)||""
            };
            const dataCover = {
              name: "brandCover",
              value: decodeURIComponent(payload.brandCover)||""
            };
            const dataDesc = {
              name: "brandDesc",
              value: payload.description||""
            };
            const datadisclaimer = {
              name: "brandDisclaimer",
              value: payload.disclaimer||""
            };
            const dataSite = {
              name: "brandWebsite",
              value: payload.website||""
            };
            const get = await axios.get(url, config);
            console.log('Response:', get.data);
            if(get.data?.customValues?.length>0)
            for (const element of get.data.customValues) {
              if(element.name === "brandName"){
                const responseName = await axios.put(url+element.id, dataName, config);
                console.log('Response:', responseName.data);
              }
              if(element.name === "brandLogo"){
                const responseLogo = await axios.put(url+element.id, dataLogo, config);
                console.log('Response:', responseLogo.data);
              }
              if(element.name === "brandCover"){
                const responseCover = await axios.put(url+element.id, dataCover, config);
                console.log('Response:', responseCover.data);
              }
              if(element.name === "brandDesc"){
                const responseDesc = await axios.put(url+element.id, dataDesc, config);
                console.log('Response:', responseDesc.data);
              }
              if(element.name === "brandDisclaimer"){
                const responseDesc = await axios.put(url+element.id, datadisclaimer, config);
                console.log('Response:', responseDesc.data);
              }
              if(element.name === "brandWebsite"){
                const responseSite = await axios.put(url+element.id, dataSite, config);
                console.log('Response:', responseSite.data);
              }
            }
          } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
          }

        console.log('payload is ', payload.location);

        if (logo && logo.response) payload.brandLogo = logo.response.data
        if (cover && cover.response) payload.brandCover = cover.response.data
        if (bannerImage && bannerImage.response) payload.bannerImage = bannerImage.response.data

        const { error, response } = await BrandService.Update({ toaster: true, payload })
        console.log('error ', error);
        console.log('response', response);

        if (error) return setState({ generalLoader: false, brandingLoader: false, apiError: error.error })

        brand.updateBrand(response.data)
        // return setState({ generalLoader: false, apiError: '' })
    }

    const termsRef = useRef();
    useEffect(()=>{
        if(state.termConditions == false){
        formik.values.termsAndConditions = '';
        formik.setValues({...formik.values});
        if(termsRef&&termsRef.current&&termsRef.current.value) termsRef.current.value = ""
        
        }
      },[state.termConditions])









    //   
  const user = useContext(UserContext);
    
    const initState1 = {
        fullName: user.firstName + " " + user.lastName,
        email: user.email,
      };
    
      const handleSubmit1 = async () => {
        // setState({ ...state, generalLoader: true });
    
        let names = formik1.values.fullName.split(" ");
        let firstName = names.shift();
        let lastName = names.join(" ");
    
        const payload = {
          _id: user._id,
          firstName: firstName,
          lastName: lastName,
          email: formik1.values.email,
        };
    
        const { error, response } = await UserService.Update({
        //   toaster: true,
          payload,
        });
        if (response) localforage.setItem("user", response.data);
        // setState({ ...state, generalLoader: false });
        if (response) user.updateUser(response.data);
        return;
      };
    
      const formik1 = useFormik({
        initialValues: { ...initState1 },
        // onSubmit: handleSubmit1,
        validationSchema: UserVld.Update,
        isInitialValid: false,
        enableReinitialize: true,
      });
    
      const handleEmailChange = async (e) => {
        if (
          e.target.value === initState1.email ||
          !e.target.value ||
          formik1.errors.email
        )
          return;
    
        setState({ ...state, emailLoader: true });
        const email = e.target.value;
        const query = { email };
        const { error, response } = await UserService.GetByQuery({ query });
        if (response) {
          formik1.setErrors({ email: "email already exist" });
          return setState({ ...state, emailLoader: false });
        }
        return setState({ ...state, emailLoader: false });
      };









    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
          margin: 1,
          top: '-8px !important',
          left: '-8px !important',
          padding: 0,
          transform: 'translateX(6px)',
          '&.Mui-checked': {
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb': {
              backgroundColor: '#001e3c', // Background color when ON
              '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                  '#fff',
                )}" d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2v.01L12 11 4 6.01V6h16zM4 18v-9l8 5 8-5v9H4z"/></svg>')`, // Filled email icon when ON
              },
            },
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: '#aab4be', // Background color when OFF
            width: 32,
            height: 32,
            '&::before': {
              content: "''",
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                '#fff',
              )}" d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2v.01L12 11 4 6.01V6h16zM4 18v-9l8 5 8-5v9H4z"/></svg>')`, // Filled email icon when ON
            },
          },
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#aab4be',
          borderRadius: 20 / 2,
          ...theme.applyStyles('dark', {
            backgroundColor: '#8796A5',
          }),
        },
      }));
      
      
      
    return (
        <div className="brandSetting">
            <div className="w-100">
                <div className='d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>Account Profile</div>
                    <CustomButton btntext={"Update"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
                        setState({ generalLoader: true, apiError: '' })
                        await handlePublicProfileUpdateFunc();
                        await handleSubmit1();
                        setState({ generalLoader: false, apiError: '' })
                    }} />
                </div>
                <div className='bar mt_10 mb_20'></div>
                <div className='w-70'>
                    <CustomTextField
                        label={"Account Name"}
                        placeholder="Full Name"
                        type="text"
                        name="fullName"
                        value={formik1.values.fullName}
                        onChange={formik1.handleChange}
                        inputProps={{ onFocus: formik1.handleBlur }}
                        error={formik1.touched.fullName && formik1.errors.fullName}
                        helperText={
                            formik1.touched.fullName &&
                            formik1.errors.fullName &&
                            formik1.errors.fullName
                        }
                        />

                        {/*------------ EMAIL ADDRESS ------------*/}
                        {console.log('formik.values.termsAndConditions',formik.values.termsAndConditions)
                        }
                        <div className="position-relative mt_30">
                        <CustomTextField
                            label={"Email Address"}
                            placeholder="Email"
                            type="text"
                            name="email"
                            value={formik1.values.email}
                            onChange={formik1.handleChange}
                            onKeyUp={handleEmailChange}
                            position="end"
                            inputProps={{ onFocus: formik1.handleBlur }}
                            error={formik1.touched.email && formik1.errors.email}
                            helperText={
                            formik1.touched.email && formik1.errors.email && formik1.errors.email
                            }
                        />
                        <div
                            style={{ position: "absolute", top: "17px", right: "16px" }}
                            className=""
                        >
                            {state.emailLoader ? (
                            <CircularProgress size={16} color={"inherit"} />
                            ) : !formik1.errors.email ? (
                            <SvgIcons.Check />
                            ) : (
                            <SvgIcons.Failure />
                            )}
                        </div>
                        </div>
                </div>
                <div className='Heading22M color-neutral100 mt_30'>Business</div>
                <div className='bar'></div>



                {/* <FormGroup style={{width: 'fit-content'}}> */}
                    {/* <div className='Body16M mt_20'>Turn On Customer Email Notifications</div>
                    <MaterialUISwitch 
                    checked={formik.values.emailCheck}
                     onChange={(event)=>{
                        console.log('event.target.checked',event.target.checked)
                        event.stopPropagation()
                        formik.setValues({...formik.values, emailCheck: event.target.checked})
                    }}
                    /> */}

                    {/* <div className='Body16M mt_20'>Turn On Customer Email Notifications</div>
                    <Switch
                        checked={formik.values.emailCheck}
                        onChange={(event)=>{
                            console.log('event.target.checked',event.target.checked)
                            event.stopPropagation()
                            formik.setValues({...formik.values, emailCheck: event.target.checked})
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    /> */}
                {/* </FormGroup> */}

                <div className="w-70 mt_20">
                <div className="position-relative mt_30">
                    <CustomTextField
                        label={"Preferred Contact Email"}
                        placeholder="Preferred Contact Email"
                        type="text"
                        name="email1"
                        value={formik.values.email1}
                        onChange={formik.handleChange}
                        // onKeyUp={handleEmailChange}
                        // position="end"
                        inputProps={{ onFocus: formik.handleBlur }}
                        error={formik.touched.email1 && formik.errors.email1}
                        helperText={
                        formik.touched.email1 && formik.errors.email1 && formik.errors.email1
                        }
                    />
                </div>
                    <div className="col-md-12 col-12 mt_30 pl_2 pr_0">
                        <CustomTextField
                            className="w-100"
                            label="Brand Name"
                            name="brandName"
                            value={formik.values.brandName}
                            onChange={formik.handleChange}
                            onKeyUp={handleBrandName}
                            icon={brandNameLoader ? <CircularProgress size={20} color={'inherit'} /> : !formik.errors.brandName ? <SvgIcons.Check /> : ''}
                            position='end'
                            inputProps={{ tabIndex: '3', onFocus: formik.handleBlur }}
                            error={formik.touched.brandName && formik.errors.brandName}
                            helperText={formik.touched.brandName && formik.errors.brandName ? formik.errors.brandName : ''}
                        />
                    </div>
                    <div className="col-md-12 col-12 mt_30 pl_0 pr_0">
                        <CustomSelect
                            label={'Business Type'}
                            options={Options.getVenueType()}
                            name="businessService"
                            value={formik.values.businessService}
                            onChange={(e) => formik.setValues({ ...formik.values, businessService: e.target.value })}
                            error={formik.touched.businessService && formik.errors.businessService}
                            helperText={formik.errors.businessService && formik.errors.businessService}
                        />
                    </div>

                    <div className="col-md-12 col-12 mt_30 pl_2 pr_0" id="PhoneInputField">
                        {/* <label className='mb_8 fs-14 fw-5 control-label' style={{color: ColorSchemeCode.neutral90, letterSpacing : 0.1,transition:'ease-in-out 0.2s'}}>Phone Number</label> */}
                        <PhoneInput
                            inputClass={'w-100 Body14R'}
                            inputStyle={{ border: '1px solid ' + ColorSchemeCode.OtherBorder, height: '42px' }}
                            value={formik.values.fullPhone || ''}
                            onChange={phone => formik.setValues({ ...formik.values, fullPhone: phone })}
                        />
                    </div>

                    <div className="col-md-12 col-12 mt_30 pl_2 pr_0">
                        <CustomTextArea
                            label="Description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            name="description"

                        />
                    </div>
                    <div className="col-md-12 col-12 mt_30 pl_2 pr_0">
                        <CustomTextArea
                            label="Disclaimer"
                            value={formik.values.disclaimer}
                            onChange={formik.handleChange}
                            name="disclaimer"

                        />
                    </div>
                    {/* <div className="col-md-12 col-12 mt_16 pl_0 position-relative pr_0">
                        <CustomTextField
                            className="w-100 disabled-high-opacity"
                            label="Brand Id"
                            name="brandId"
                            value={brand._id}
                            inputProps={{ tabIndex: '2', onFocus: formik.handleBlur }}
                            error={formik.touched.brandName && formik.errors.brandName}
                            helperText={formik.touched.brandName && formik.errors.brandName ? formik.errors.brandName : ''}
                        />
                        <div className="copyBox" style={{ position: "absolute", top: "15px", right: "16px", }}>

                            <CopyText id="brandId" content={brand._id} hideText={true} />
                        </div>
                    </div> */}
                    <div className="col-md-12 col-12 mt_30 pl_0 position-relative pr_0">
                        <CustomTextField
                            className="w-100 "
                            label="Website/Online Order"
                            name="website"
                            value={formik.values?.website}
                            onChange={formik.handleChange}
                            // inputProps={{ tabIndex: '2', onFocus: formik.handleBlur }}
                            error={formik.touched.website && formik.errors.brandwebsiteName}
                            helperText={formik.touched.website && formik.errors.website ? formik.errors.website : ''}
                        />
                       
                    </div>
                    {
                        console.log("BRAND", brand)
                    }
                    {/* <div className="col-md-12 col-12 mt_16 pl_0 position-relative pr_0">
                        <CustomTextField
                            className="w-100 disabled-high-opacity"
                            label="business Page URL"
                            name="businessPageUrl"

                            value={window.location.hostname + "/page" + window.location.pathname.replace('setting/general', 'businesspage')}
                        // inputProps   = {{tabIndex: '2', onFocus: formik.handleBlur }}
                        // error        = {formik.touched.brandName && formik.errors.brandName}
                        // helperText   = {formik.touched.brandName && formik.errors.brandName ? formik.errors.brandName : ''}
                        />
                        <div className="copyBox" style={{ position: "absolute", top: "15px", right: "16px", }}>
                            <CopyText id="businessPageUrl" content={window.location.hostname + "/page" + window.location.pathname.replace('setting/general', 'businesspage')} hideText={true} />
                        </div>
                    </div> */}

                    

                  

                </div>


                {/* <div className='w-100 col-md-12 col-12 pl_0 pr_0 '>
                            <Location state={state} setState={setState} formik={formik} brand={brand} initState={initState}/>
                        </div>   */}

                <div className='w-100 col-md-12 col-12 pl_0 pr_0 mt_20'>
                    <Branding formik={formik} handlePublicProfileUpdateFunc={handlePublicProfileUpdateFunc} imageModal={imageModal} state={state} setImageModal={setImageModal} />
                </div>



                {/* <div className='w-100 col-md-12 col-12 pl_0 pr_0 '>
                <div className='Heading22M color-neutral100' mb_8>Terms and Conditions</div>

                <div className='bar'></div>
                </div> */}

                {/* <div className='w-70 col-md-12 col-12 pl_0 pr_0 '>
                    <div className="w-100 mt_20 mb_10 ml_7">
                        <CustomCheckBox
                            label={<p className="mb_0 Link13R">Add Terms & Condition</p>}
                            fontSize='16px '
                            Color='#2E3039'
                            
                          onChange={()=>{
                          state.termConditions = !state.termConditions
                          if(!state.termConditions){
                            formik.values.termsAndConditions = '';
                            formik.setValues({...formik.values});
                          }
                          setState({ ...state })
                        }} 
                        />
                    </div>
                    <div className="position-relative w-100">
                        <CustomTextField
                            label={"Terms & Condition URL"}
                            placeholder="http://www.example.com/terms-and-conditions"
                            type="text"
                            inputRef    = {termsRef}
                            name="termsAndConditions"
                            value={formik.values.termsAndConditions}
                          onChange    = { formik.handleChange}
                          className   = {!state.termConditions && 'disabled'}
                          disabled    = {!state.termConditions}
                      
                        />
                    </div>
                </div> */}

                <div className='w-100 col-md-12 col-12 pl_0 pr_0 '>
                    <Setting state={state} setState={setState} formik={formik} brand={brand} initState={initState} />
                </div>

            </div>
        </div>
    )

}

const Branding = ({ formik, imageModal, setImageModal, handlePublicProfileUpdateFunc, state }) => {
    return (
        <>
            <div className='d-flex space-between align-items-center mt_16 mb_8'>
                <div className="col-md-6 col-6 pl_0 pr_0 w-100 Heading22M color-neutral100">
                    Branding
                </div>
            </div>

            <div className="col-md-12 col-12  pl_0 pr_0">
                <div className="bar"></div>
            </div>

            <div className="w-70">
                <LogoUploader
                    src={formik.values.brandLogoFile ? URL.createObjectURL(formik.values.brandLogoFile) : formik.values.brandLogo}
                    label="Brand Logo"
                    caption={'Your profile picture will appear where your brand is presented on wallet, such as on your brand page.'}
                    className="mt_10 pl_0"
                    hint='Its recommended that you use a picture thats at least 98 x 98 pixels and 4 MB or less. Use a PNG or GIF (no animations) file.'
                    onChange={(e) => { setImageModal({ ...imageModal, logo: { ...imageModal.logo, open: true, imageEvent: e } }) }}
                    error={formik.values.brandLogoFile && formik.errors.brandLogoFile}
                    btntext="Upload Logo"
                />

                <div className="col-md-12 col-12 mt_16 pl_0 pr_0">
                    <div className="bar"></div>
                </div>


                <div className="col-12 col-md-12 pl_0">


                    <div className="col-12 pl_0 pr_0">
                        <LogoUploader
                            src={formik.values.brandCoverFile ? URL.createObjectURL(formik.values.brandCoverFile) : formik.values.brandCover}
                            label="Brand Cover"
                            className="mt_16 pl_0 "
                            caption="This image will be used for your cover image, like your facebook cover image."
                            hint={'For the best results on all devices, use an image thats at least 2048 x 1152 pixels and 6 MB or less.'}
                            error={formik.values.brandCoverFile && formik.errors.brandCoverFile}
                            htmlFor={'coverUpload'}
                            onChange={(e) => { setImageModal({ ...imageModal, cover: { ...imageModal.cover, open: true, imageEvent: e } }) }}
                        />

                    </div>
                    <div className="col-md-12 col-12 mt_16 pl_0 pr_0">
                    <div className="bar"></div>
                </div>
                    <div className="col-12 pl_0 pr_0">
                        <LogoUploader
                            src={formik.values.bannerImageFile ? URL.createObjectURL(formik.values.bannerImageFile) : formik.values.bannerImage || PngIcons.banner}
                            label="Banner Image (social media page)"
                            className="mt_16 pl_0 mb_56"
                            caption="This image will be used for your banner image, like your social media cover image."
                            hint={'For the best results on all devices, use an image thats at least 2048 x 1152 pixels and 6 MB or less.'}
                            error={formik.values.bannerImageFile && formik.errors.bannerImageFile}
                            htmlFor={'bannerImage'}
                            onChange={(e) => { setImageModal({ ...imageModal, bannerImage: { ...imageModal.bannerImage, open: true, imageEvent: e } }) }}
                        />

                    </div>

                    <CustomsModal
                        open={imageModal.cover.open}
                        onClose={() => setImageModal({ ...imageModal, cover: { ...imageModal.cover, open: false } })}
                        minWidth="470px"
                        minHeight="470px"
                        padding="0px"
                        component={
                            <ImageModalV2
                                imageModal={imageModal}
                                setImageModal={setImageModal}
                                image={'cover'}
                                type={'cover'}
                                formik={formik}
                            />
                        }
                    />
                    {console.log('imageModal',imageModal)
                    }
                    <CustomsModal
                        open={imageModal.bannerImage.open}
                        onClose={() => setImageModal({ ...imageModal, bannerImage: { ...imageModal.bannerImage, open: false } })}
                        minWidth="470px"
                        minHeight="470px"
                        padding="0px"
                        component={
                            <ImageModalV2
                                imageModal={imageModal}
                                setImageModal={setImageModal}
                                image={'bannerImage'}
                                type={'bannerImage'}
                                formik={formik}
                            />
                        }
                    />

                    <CustomsModal
                        open={imageModal.logo.open}
                        onClose={() => setImageModal({ ...imageModal, logo: { ...imageModal.logo, open: false } })}
                        minWidth="470px"
                        minHeight="470px"
                        padding="0px"
                        component={
                            <ImageModalV2
                                imageModal={imageModal}
                                setImageModal={setImageModal}
                                image={'logo'}
                                formik={formik}
                            />
                        }
                    />


                </div>
            </div>
        </>
    )
}

