import * as yup from 'yup';

const REQ_ERROR = 'This field is required'

const Create = yup.object({
    firstName   : yup.string().required(REQ_ERROR),
    lastName    : yup.string().required(REQ_ERROR),
    email       : yup.string().email().required(REQ_ERROR),
    phone       : yup.string().strict().required(REQ_ERROR).matches(/^\d{1,10}$/),
})
export {
    Create,
}