import * as yup from 'yup';


const Create = yup.object({
    templateName : yup.string().required(),
    templateType : yup.string().required(),
    password     : yup.string().required(),
    saveto       : yup.string().required(),
})

const DefaultFormVld = yup.object({
        // fullName  : yup.string(),
        firstName  : yup.string().required(),
        lastName   : yup.string().required(),
        email      : yup.string().email(),
        phone      : yup.string().strict().matches(/^\d{1,10}$/),
})


export {
    Create,
    DefaultFormVld
}