import * as yup from 'yup';

const REQ_ERROR = 'This field is required'

const Create = yup.object({
    name           : yup.string().required(REQ_ERROR),
    minPoints      : yup.number().required(REQ_ERROR),
    maxPoints      : yup.number().required(REQ_ERROR).min(
        yup.ref('minPoints'),
        "max points can't be less than min points"
      ),
    backgroundColor: yup.string().required(REQ_ERROR),
    customDiscounts: yup.array().nullable(),
    // fontColor       : yup.string().required(REQ_ERROR),
    discountValue   : yup.number().max(100).nullable(),
    extraPoints     : yup.number(),
    expirationDays  : yup.number().min(0).required(),    
})


export {
    Create,
}