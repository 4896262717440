import React, { useContext, useState } from 'react'

import localforage, * as localForage from 'localforage'


import CustomTextField from '../../../components/CustomTextField';
import CustomSelect from '../../../components/CustomSelect';

import { Options, utils } from '../../../utils';

import { LayoutContext } from '../../../context/Layout.context';
import { BrandService } from '../../../services';
import { SvgIcons } from '../../../icons';
import CustomsModal from '../../../components/CustomModal';
import { FormControl, FormHelperText, RadioGroup } from '@mui/material';
import CustomButton from '../../../components/CustomButton';
import { Brand } from '../../../enums'
import DeleteModal from '../../../components/DeleteModal';
import { AgencyContext } from '../../../context/Agency.context';
import { showToaster } from '../../../utils/utils';
import CustomRadio from '../../../components/CustomRadio';
import 'react-phone-input-2/lib/style.css'
import CustomSwitch from '../../../components/CustomSwitch';


function Setting({formik, initState, brand}) {
    const agency = useContext(AgencyContext)
    const layout = useContext(LayoutContext)

    const [state, setState] = useState({
        loader: false,
        apiError: '',
    })

    const handleSubmit = async () => {
        setState({ loader: true, apiError: '' })

        const fullPhone = utils.getCountryCode(formik.values.fullPhone.includes('+') ? formik.values.fullPhone : '+' + formik.values.fullPhone)

        let logo, cover
        const user = await localForage.getItem('user')
        const desiredPath = `${user._id}/${formik.values.brandName}`

        // uploading logo
        if (formik.values.brandLogoFile)
            logo = await utils.uploadImage({ file: formik.values.brandLogoFile, desiredPath })
        if (logo && logo.error) return setState({ loader: false, apiError: logo.error.message })

        // uploading cover
        if (formik.values.brandCoverFile)
            cover = await utils.uploadImage({ file: formik.values.brandCoverFile, desiredPath })
        if (cover && cover.error) return setState({ loader: false, apiError: cover.error.message })

        const permissions = await localForage.getItem('permissions')

        if (formik.values.brandLogoFile) {
            permissions.map((element, idx) => {
                if (element.businessId === brand._id) permissions[idx].logo = logo.response.data;
            })
            localforage.setItem('permissions', permissions)
        }

        if (formik.values.brandName) {
            permissions.map((element, idx) => {
                if (element.businessId === brand._id) permissions[idx].brandName = formik.values.brandName;
            })
            localforage.setItem('permissions', permissions)
        }

        const payload = {
            _id: brand._id,
            brandType: brand.brandType,
            brandName: formik.values.brandName,
            description: formik.values.description,
            brandColor: formik.values.brandColor,
            location: formik.values.location,
            currency: formik.values.currency,
            appleWalletShow: formik.values.appleWalletShow,
            businessService: formik.values.businessService,
            locationType: formik.values.businessService,
            onlineService: formik.values.onlineService,
            brandLogo: formik.values.brandLogo,
            vip: formik.values.vip,
            tier: formik.values.tier,
            brandCover: formik.values.brandCover,
            email: user.email,
            phone: fullPhone.withoutCountryCode.replace(/\s/g, ''),
            // phone          : formik.values.phone,
            upgradePlanUrl: formik.values.upgradePlanUrl,
            walletApp: {
                android: formik.values.walletApp.android,
                apple: formik.values.walletApp.apple,
            },
            remotePointCheck: formik.values.remotePointCheck,
        }

        // console.log('payload is ', payload);

        if (logo && logo.response) payload.brandLogo = logo.response.data
        if (cover && cover.response) payload.brandCover = cover.response.data

        const { error, response } = await BrandService.Update({ toaster: true, payload })
        if (error) return setState({ loader: false, apiError: error.error })

        brand.updateBrand(response.data)
        return setState({ loader: false, apiError: '' })
    }

    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
  

    React.useEffect(() => {
        layout.setLayout({
            title: 'Settings',
            borderBottom: false,
            switch: "",
            button: ""
        })
    }, [formik.values, formik.errors, state.loader, deleteModal])

    const handleDelete = async () => {
        setDeleteLoader(true)
        let payload = {
            _id: brand._id,
            delete: true
        }
        if (agency.whiteLabel) payload.agencyId = agency._id
        const { response, error } = await BrandService.Update({ toaster: true, payload })
        if (response) {
            setDeleteLoader(true)
            setDeleteModal(false)
            utils.Logout()
        }
        if (error) {
            if (error.message === "first cancel brand subscription") showToaster({ position: 'bottom-right', title: 'Title Error', message: 'First cancel brand subscription', severity: 'error' })
            if (error.message === "first disconnect your brand") showToaster({ position: 'bottom-right', title: 'Title Error', message: 'first disconnect your brand', severity: 'error' })
            setDeleteLoader(false)
            setDeleteModal(false)
        }
    }

    return (
        <div id='General' className=''>
            <form>
                <div className="">
                    <BrandInformation brand={brand} handleSubmit={handleSubmit} state={state} formik={formik} setDeleteModal={setDeleteModal} />
                </div>
            </form>
            <CustomsModal
                open={deleteModal}
                minWidth={'600px'}
                component={<DeleteModal icon={deleteLoader} title="Business" onCancel={() => setDeleteModal(false)} onDelete={handleDelete} />}
                onClose={() => setDeleteModal(false)}
            />
        </div>
    )
}


const BrandInformation = ({ formik, brand, setDeleteModal, handleSubmit, state }) => {
    const [tempOne , settempOne] = useState(false)
    const [tempTwo , settempTwo] = useState(false)
    return (
        <div className="brandInformation">
            {/* <div className="w-100">
                <div className='col-md-12 mt_24   col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>Business Wallet</div>
                </div>
                <div className="col-md-12 col-12 mb_10 pl_0 pr_0">
                    <div className="bar"></div>
                </div>
                    <div className="w-70">
                        <div className="Heading16M color-neutral100 ">
                            Show wallet Pass
                        </div>
                        <div className="color-propertySectionTextColor color-neutral60 fs-14">
                            Show wallet pass on landing Page
                        </div>
                        <div className="mt_8 ml_7">
                            <FormControl error={formik.touched.appleWalletShow && formik.errors.appleWalletShow}>
                                <RadioGroup
                                    name="appleWalletShow"
                                    value={formik.values.appleWalletShow}
                                    onChange={(e) => {
                                        formik.setValues({ ...formik.values, appleWalletShow: e.target.value === 'true' ? true : false })
                                    }}
                                    onFocus={formik.handleBlur}
                                >
                                    <CustomRadio
                                        value={true}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={false}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>
                                    {formik.touched.appleWalletShow && formik.errors.appleWalletShow ? formik.errors.appleWalletShow : ''}
                                </FormHelperText>
                            </FormControl>
                        </div>

                        <div className="col-12 pl_0 pr_0 mt_16">
                            <div className="Heading16M ">
                                Default App
                            </div>
                            <div className="color-propertySectionTextColor color-neutral60 fs-14">
                                Select android and ios wallet app you want users to add their passes.
                            </div>
                            <div>
                                <div className="appleApplication">
                                    <div className="d-flex align-items-center mt_16">
                                        <div className="mb_8">
                                            <SvgIcons.AppleIcon />
                                        </div>
                                        <div className="fs-12 pb_3 fw-5 color-neutral60 pt_5 ml_4 mb_8">
                                            Apple Application
                                        </div>
                                    </div>
                                    <div className="col-12 pl_0 pr_0">
                                        <CustomSelect
                                            options={Options.ApplePassRedeemTypes()}
                                            value={formik.values.walletApp ? formik.values.walletApp.apple : ''}
                                            onChange={(e) => formik.setValues({ ...formik.values, walletApp: { ...formik.values.walletApp, apple: e.target.value } })}
                                            inputProps={{ tabIndex: '13' }}
                                        />
                                    </div>

                                </div>

                                <div className="androidApplication">
                                    <div className="d-flex align-items-center mt_16">
                                        <div className="mb_5 ">
                                            <SvgIcons.AndroidIcon />
                                        </div>
                                        <div className="fs-12 pb_2 mb_0 fw-5 color-neutral60 mb_8 pt_3 ml_4">
                                            Android Application
                                        </div>
                                    </div>
                                    <div className="col-12 pl_0 pr_0" >
                                        <CustomSelect
                                            options={Options.AndroidPassRedeemTypes()}
                                            value={formik.values.walletApp ? formik.values.walletApp.android : ''}
                                            onChange={(e) => formik.setValues({ ...formik.values, walletApp: { ...formik.values.walletApp, android: e.target.value } })}
                                            inputProps={{ tabIndex: '14' }}
                                        />
                                    </div>

                                </div>

                            </div>

                        </div>
                </div>

            </div> */}
            {/* <div className='w-100'>
                <div className='col-md-12 mt_24  col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>Extra Business Services</div>
                </div>
                <div className=" col-md-12 col-12 pl_0 pr_0 mb_11">
                    <div className="bar"></div>
                </div>
                <div className='w-70'>

                
                        <div className="Heading16M color-neutral100 ">
                            Upgrade Plan Link
                        </div>
                        <div className="Body14R color-neutral60">
                            Set the link to redirect the user to upgrade their membership.
                        </div>
                        <div className="col-md-12 col-12 pl_2 pr_0 mt_8 mb_16">
                            <CustomTextField
                                className="w-100"
                          
                                placeholder  = "URL"
                                name         = "upgradePlanUrl"
                                inputProps   = {{ tabIndex: '11' }}
                                defaultValue = {formik.values.upgradePlanUrl ? formik.values.upgradePlanUrl : ''}
                                onBlur       = {formik.handleChange}
                                error        = {formik.errors.upgradePlanUrl && formik.values.upgradePlanUrl && !formik.values.upgradePlanUrl.state ? formik.errors.upgradePlanUrl.state : false}
                                helperText   = {formik.errors.upgradePlanUrl && formik.values.upgradePlanUrl && !formik.values.upgradePlanUrl.state ? formik.errors.upgradePlanUrl.state : ''}
                            />
                        </div>
                 
                    {brand.brandType === Brand.BrandTypes.NORMAL && 
                    <>
                        <div className='d-flex space-between w-100 align-items-center'>
                            <div className="Heading16M fs-18 color-c000000">
                                VIP Club
                            </div>
                            <div>
                                <CustomSwitch
                                    checked={formik.values.vip}
                                    value={formik.values.vip}
                                    onChange={async (e) => {
                                        formik.setValues({ ...formik.values, vip: !formik.values.vip })
                                    }}
                                />
                            </div>

                        </div>
                        <div className="Body14R color-neutral60">
                            Beware, if you Refresh API Key all connected API methods will be disabled. Here is the link to Swagger where you can try our API. Help article is available here.
                        </div>
                    </>}
                    <div className="col-12 pl_0 pr_0 mt_16 mb_36">
                        <div className='d-flex space-between w-100 align-items-center'>
                            <div className="Heading16M fs-18 color-c000000">
                                Loyalty Tiers
                            </div>
                            <div>
                                <CustomSwitch
                                    checked={formik.values.tier}
                                    value={formik.values.tier}
                                    onChange={async (e) => {
                                        formik.setValues({ ...formik.values, tier: !formik.values.tier })
                                    }}
                                />
                            </div>

                        </div>
                        <div className="Body14R color-neutral60">
                            Beware, if you Refresh API Key all connected API methods will be disabled. Here is the link to Swagger where you can try our API. Help article is available here.
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="w-100">
                <div className='col-md-12 mt_24  col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>In-Store Reward Type</div>
                   
            <CustomButton
                        btntext={"Update"}
                        type="submit"
                        disabled={state.loader}
                        icon={state.loader && <CircularProgress size={20} color={'inherit'} />}
                        onClick={handleSubmit}
                    />
                </div>
                <div className=" col-md-12 col-12 pl_0 pr_0">
                    <div className="bar"></div>
                </div>
                <div className="col-12 col-md-12 pl_0 pr_0">
                    <div className="w-70 mt_10">
                        <div className="Heading16M color-neutral100 ">
                        Do you like to create reward outside of Walletly?
                        </div>
                        {console.log('formik ', formik.values)}
                        <div className="mt_8 ml_11">
                            <FormControl 
                            >
                                <RadioGroup
                                     name     = "inStoreReward"
                                     onChange = {formik.handleChange}
                                     value={formik.values.inStoreReward}
                                >
                                    <CustomRadio
                                        value = {"true"}
                                        label = {<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value = {"false"}
                                        label = {<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>
                                   
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="Heading16M color-neutral100 ">
                        Do you like Customers to collect points and use them as per Store offering?
                        </div>
                        <div className="mt_8 ml_11">
                            <FormControl 
                            
                            >
                                <RadioGroup
                                   name     = "pointStoreOffering"
                                   onChange = {formik.handleChange}
                                   value    = {formik.values.pointStoreOffering}
                                >
                                    <CustomRadio
                                        value={"true"}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={"false"}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>
                                  
                                </FormHelperText>
                            </FormControl>
                        </div>
                    </div>
                    </div>
                    
            </div> */}


            <div className="buttonDelete mt_36">
                <div className='Heading22M col-12 col-md-12 pl_0 pr_0 color-neutral100 mb_8'>Delete Business Page</div>
                <div className='bar  col-12 col-md-12 mb_10 '></div>
                <div className="w-70">
                    <div className="Body14R color-neutral80 mt_16 mb_16">Once you delete your account, there is no going back. Please be certain.</div>
                    <CustomButton
                        btntext={"Delete this business page"}
                        varient="warningSecondary"
                        className={brand.brandType === Brand.BrandTypes.SHARED_LOYALITY ? "mr_4 disabled mb_56" : "mr_4 mb_56"}
                        onClick={() => setDeleteModal(true)}
                    />
                </div>
            </div>

        </div>
    )
}


export default Setting