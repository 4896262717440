import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { utils } from '../../../utils';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import { UserService } from '../../../services';
import Loader from '../../../components/Loader';
import { LayoutContext } from '../../../context/Layout.context';

function UpdateAudience() {
    const history = useHistory();

    const [found, setfound] = useState()
    const [subscriber, setSubscriber] = useState([])
    const [loader, setLoader] = useState(true)
    const layout = useContext(LayoutContext)

    useEffect(() => {
        layout.setLayout({
            borderBottom: true,
            edit        : false,
            title       : history.location.state.pass.userData.firstName,
            backTitles  : ['Audience'],
            switch : '',
            button: <></>
        })
    }, [history.location.state])
    

    useEffect(async() => {
        setLoader(true)
        const customers = await localforage.getItem('customersAudience');
        let found = customers.find((currentCustomer) => {
          return currentCustomer?.pass?._id == history?.location?.state?.pass?.referredBy
        })
        setfound(found)

        const a = history.location.state.pass.brandId+'&'+history.location.state.pass.userId
        console.log(a)
        const {error, response} = await UserService.GetSubscriber({payload: {_id: a}})
        console.log('error',error);
        console.log('response',response);
        setSubscriber(response.data.tags)

        setLoader(false)
        
    }, [])
    
  return (
    <div>
        {console.log(history.location.state)}
        {loader?<Loader />:
        <div className='row m_0'>
            <div className='col-3 p_0 position-relative'>
                <img className='w-100' height={'123px'} src='https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/014bf620-d970-41ab-6e5e-5a19e8de1a00/Digi' />
                <div className='position-absolute' style={{top: '70px', left: '15px'}}>
                    {utils.getAvatar({
                        firstName: history.location.state.pass.userData.firstName,
                        lastName: history.location.state.pass.userData.lastName,
                        id: history.location.state.pass._id,
                        tableName: "customers",
                        customers: history.location.state,
                        width: "94px",
                        heigth: "94px",
                        className: 'ua-whitehead',
                        border: true,
                        bgColor: '#2960EC',
                        })
                    }
                </div>
                <div className='mt_60 ua-head ml_20'>{history.location.state.pass.userData.firstName + ' '+ history.location.state.pass.userData.lastName}</div>
                <div className='ua-desc ml_20 mt_4 mb_4'>{history.location.state.pass.userData.email}</div>
                <div className='ua-desc ml_20'>{new Date(history.location.state.pass.birthday).toDateString()}</div>
                <div className='bar mt_26 mb_26'></div>
                <div className='ua-head ml_20 mb_4'>Phone Number</div>
                <div className='ua-desc ml_20'>{history.location.state.pass.userData.phone}</div>
                <div className='bar mt_26 mb_26'></div>
                <div className='ua-head ml_20 mb_4'>Subscription Date</div>
                <div className='ua-desc ml_20'>{new Date(history.location.state.pass.created).toDateString()}</div>
            </div>
            <div className='col-9' style={{background: '#F5F7FC', height: 'calc(100vh - 60px)'}}>
                <div className='p_26 mr_11' style={{background: 'white',borderRadius: '16px'}}>
                    
                    <div className='bar mt_29 mb_29'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4'>Referred By</div>
                        <div className='col-8 ua-desc'>{found ? (found?.pass?.userData?.firstName + " " + found?.pass?.userData?.lastName) : "-"}</div>
                    </div>
                    <div className='bar mt_29 mb_20'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4 align-content-center'>Tags</div>
                        <div className='col-8 ua-desc d-flex gap-8'>
                            {subscriber.length>0&&subscriber.map((value, index)=>(<div className='ua-bg'>{value}</div>))}
                        </div>
                    </div>
                    <div className='bar mt_20 mb_29'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4'>Point Summary</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass.loyaltyCard.currentPoints}</div>
                    </div>
                    <div className='bar mt_29 mb_29'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4'>Total Spending</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass.loyaltyCard.totalSpending}</div>
                    </div>
                    <div className='bar mt_29 mb_29'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4'>Total Visits</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass.loyaltyCard.redeemCount}</div>
                    </div>
                    <div className='bar mt_29 mb_29'></div>
                    <div className='row'>
                        {/* <div className='ua-head1 col-4'>Current Tier</div> */}
                        <div className='ua-head1 col-4'>Referral Count</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass?.refer || '-'}</div>
                        {/* <div className='col-8 ua-desc'>{history.location.state.pass.loyaltyCard.currentTier &&
                history.location.state.pass.loyaltyCard.currentTier.currentTierName
                ? history.location.state.pass.loyaltyCard.currentTier.currentTierName
                : "-"}</div> */}
                    </div>
                    <div className='bar mt_29 mb_29'></div>
                    {/* <div className='row'>
                        <div className='ua-head1 col-4'>Pass Status</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass.isWallet ? 'Subscribed' : 'Un-Subscribed'}</div>
                    </div> */}

                </div>
            </div>
        </div>}
    </div>
  )
}

export default UpdateAudience