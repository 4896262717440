import React, { useContext, useEffect } from "react";
import * as localForage from "localforage";
import { Link, useHistory } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import CustomButton from "../../components/CustomButton";
import VerificationCode from "../../components/VerificationCode";

import PngIcons from "../../icons/png.icon";
import { AuthService, UserService } from "../../services";
import { utils } from "../../utils";
import { UserContext } from "../../context/User.context";
import { Server } from "../../enums/enums";
import { AgencyContext } from "../../context/Agency.context";

export default function EmailVerify() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const agency = useContext(AgencyContext);

  const [state, setState] = React.useState({
    loader     : false,
    resendCode : false,
    apiError   : "",
    apiResponse: "",
    code       : "",
    valid      : false,
  });

  console.log('agency ', agency)

  const [defaultCode, setDefaultCode] = React.useState();

  useEffect(async () => {
    let user = await localForage.getItem("user");
    setDefaultCode(user.verificationCode);
  }, []);

  const handleCode = (code) => setState({ ...state, code, valid: true });
  const Validate = (valid) => setState({ ...state, valid });

  const verifyAccount = async (e) => {
    e.preventDefault();
    setState({ ...state, apiError: "", apiResponse: "", loader: true });
    const payload = { verificationCode: state.code, awsEmail : agency?.awsEmail || "" };
    const { error, response } = await AuthService.VerifyAccount({ payload });
    if (error && error.message.includes("invalid code"))
      return setState({ ...state, apiError: error.message });

    if (error && !error.message.includes("verified already"))
      return setState({ ...state, apiError: error.message });

    const user = await UserService.Get();
    user.response && localForage.setItem("user", user.response.data);
    setState({ ...state, loader: false });
    userContext.updateUser(user.response.data);
    // history.push("/brand");
  };

  const resendCode = async () => {
    setState({ ...state, resendCode: true, apiError: "", apiResponse: "" });

    const user = await localForage.getItem("user");
    const payload = { email: user.email, awsEmail : agency?.awsEmail || "" };
    const { error, response } = await AuthService.Resend({
      toaster: true,
      payload,
    });
    setState({ ...state, resendCode: false });
  };

  return (
    <div className="middle" id="Email">
      <div className="cardForm">
        <div className="row">
          <div className="col-12 text-center mt_16">
            <img
              src={PngIcons.x6}
              height="60px"
              width="60px"
              alt=""
            />
          </div>

          <div className="Heading2 col-12 text-center mb_35">Let's Go!</div>

          <div className="Heading2 col-12">Email Verification</div>
          <form onSubmit={verifyAccount}>
            <div className="subtitle1 col-12 mt_8 mb_24">
              Please enter the code we emailed you.
            </div>
            <div className="col-12">
              <div className="inputFields">
                <VerificationCode
                  length     = {4}
                  onComplete = {handleCode}
                  Validate   = {Validate}
                  loader     = {state.loader}
                />
              </div>
            </div>
            <CustomButton
              varient="primary"
              className="fs-14 col-12 fw-5 mt_32"
              textColor="white"
              type="submit"
              btntext="Verify"
              disabled={
                window.location.hostname === Server.DEV_URL
                  ? false
                  : state.loader || !state.valid
              }
              icon={
                state.loader && <CircularProgress size={20} color={"inherit"} />
              }
            />
            <span className="error">{state.apiError}</span>
            {state.apiResponse}
          </form>

          {/* <div id='verifycode'>{defaultCode}</div> */}

          <div className="col-12 text-center mt_48">
            {state.resendCode ? (
              <CircularProgress size={20} color={"inherit"} />
            ) : (
              <Link className="resendLogout" onClick={resendCode}>
                Resend Code
              </Link>
            )}{" "}
            or
            <Link className="resendLogout" onClick={utils.Logout}>
              {" "}
              Logout
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
