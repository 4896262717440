import * as yup from 'yup';
import { enums } from '../enums';

const REQ_ERROR = 'This field is required'

const location = yup.object({
    lat         : yup.number(),
    lng         : yup.number(),
    address     : yup.string().required(REQ_ERROR),
    city        : yup.string(),
    country     : yup.string(),
    countryCode : yup.string(),
    offset      : yup.string(),
    state       : yup.string()
})

const Create = yup.object({
    name        : yup.string().required(REQ_ERROR),
    facebook    : yup.mixed().when('checkSocialLinks',{
        is   : value => value === true,
        then : yup.mixed().when('checkFacebook',{
            is   : url => url === true,
            then : yup.string().matches(/^(www\.)?facebook.com|fb.com\/[a-zA-Z0-9(_@\.\?)?]/,'Please check if the URL is correct, you may have added "https://" at the start of the link. or not looking like facebook url').required()
    })
}),
    // yup.string().matches(/^(www\.)?facebook.com|fb.com\/[a-zA-Z0-9(\.\?)?]/,'Please check if the URL is correct, you may have added "https://" at the start of the link.'),
    // facebook    : yup.string().matches(/^(?!\-)(xn--)?(?:[a-zA-Z\u3040-\uFFEF\d\-]{0,62}[a-zA-Z\u3040-\uFFEF\d]\.){1,126}(?!\d+)[a-zA-Z\u3040-\uFFEF\d\-]{1,63}$/,'Please enter valid Domain'),
    // instagram   : yup.string().matches(/^(?!\-)(xn--)?(?:[a-zA-Z\u3040-\uFFEF\d\-]{0,62}[a-zA-Z\u3040-\uFFEF\d]\.){1,126}(?!\d+)[a-zA-Z\u3040-\uFFEF\d\-]{1,63}$/,'Please enter valid Domain'),
    instagram   : yup.mixed().when('checkSocialLinks',{
        is   : value => value === true,
        then : yup.mixed().when('checkInstagram',{
            is   : url => url === true,
            then : yup.string().matches(/^(www\.)?instagram.com|fb.com\/[a-zA-Z0-9(_@\.\?)?]/,'Please check if the URL is correct, you may have added "https://" at the start of the link. or not looking like instagram url').required()
    })
}),
    // yup.string().matches(/^(www\.)?instagram.com\/[a-zA-Z0-9(\.\?)?]/,'Please check if the URL is correct, you may have added "https://" at the start of the link.'),
    website     : yup.mixed().when('checkSocialLinks',{
        is   : value => value === true,
        then : yup.mixed().when('checkWebsite',{
            is   : url => url === true,
            then : yup.string().matches(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,'Enter correct url').required(REQ_ERROR)
    })
}), 
    // yup.string().matches(/^(?!\-)(xn--)?(?:[a-zA-Z\u3040-\uFFEF\d\-]{0,62}[a-zA-Z\u3040-\uFFEF\d]\.){1,126}(?!\d+)[a-zA-Z\u3040-\uFFEF\d\-]{1,63}$/,'Please check if the URL is correct, you may have added "https://" at the start of the link.'),
    email       : yup.string().email().required(REQ_ERROR),
    fullPhone : yup.string().required(REQ_ERROR),
    type           : yup.string().required(REQ_ERROR),
    redemptionType : yup.string().required(REQ_ERROR),
    other          : yup.mixed().when('type',{
                    is   : value => value === "other",
                    then : yup.string().required(REQ_ERROR)
                }),
    location   : location,
    description: yup.string().min(25),
    logo       : yup.string(),
    logoFile   : yup.mixed()
                    .test('imageSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('avatar',{
                        is   : value => !value,
                        then : yup.mixed()
                    }),
    brandLogoFile     : yup.mixed()
                    .test('imageSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('avatar',{
                        is   : value => !value,
                        then : yup.mixed()
                    }),

    checkDealOffer     : yup.bool(),
    
    checkSocialLinks : yup.bool(),
    checkFacebook    : yup.bool(),
    checkInstagram   : yup.bool(),
    checkWebsite     : yup.bool(),

    dealOfferName     : yup.mixed().when('checkDealOffer',{
                                is   : image => image === true,
                                then : yup.mixed().required('Deal Name is Required')
                        }),
    dealOfferImage    : yup.string(),
    dealDescription   : yup.mixed().when('checkDealOffer',{
                            is   : image => image === true,
                            then : yup.mixed().required('Deal Description is Required')
                        }),
    dealOfferImageFile: yup.mixed()
                            .test('imageSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                            .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                            .when('dealOfferName',{
                                    is   : value => value !== undefined,
                                    then : yup.mixed().when('dealOfferImage',{
                                        is   : image => image === undefined,
                                        then : yup.mixed().required('Deal Image is Required')
                                })
                            }),

    checkTierSystem    : yup.string(),
    tierSystemName     : yup.string(),

    discountValue    : yup.array().nullable(),   //
    extraPoints      : yup.number(),
    avgSpendingValue : yup.number().nullable(),
    customDiscounts  : yup.array().nullable(),

    checkDiscount : yup.array(),
    checkCustom   : yup.array(),
    checkExtra    : yup.array(),
    checkBirthday : yup.boolean(),

    minPoints : yup.string(),
    maxPoints : yup.string(),

})


export {
    Create,
}