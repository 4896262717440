import React from 'react';
import { PngIcons } from './icons';
// import './App.css'; // Import your CSS file

function Null() {
  return (
    <div id='Null' className="container">
      <header>
        <div className="logo">
          {/* Replace with your logo */}
          <img src={PngIcons.Logo} alt="XIncentiveLogo" />
        </div>
        <nav>
          <button>Log In</button>
          <button>Get Started</button>
        </nav>
      </header>

      <main>
        <h1>Customer Experience Management</h1>
        <div className="image-container">
          {/* Replace with your image or GIF */}
          <img src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExZmp2OGh0YWQyNXg2d2p1bmYwYnp6YmxpM2NrMmE4NzhyZ240bzVqZSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/ZbHkUWQZsaK1TUEUJa/giphy.webp" alt="Cooking Image" />
        </div>
      </main>
    </div>
  );
}

export default Null;