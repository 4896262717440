import React, { useEffect, useState } from 'react'
import CustomSwitch from '../../../../components/CustomSwitch';
import CustomTableV2 from '../../../../components/CustomTableV2'

function Table() {
  const [result, setResult] = useState([])

  const columns = [
    { field: 'Deals', headerName: 'Deals', flex: 2, sortable: true,
        valueFormatter : ({ value }) => value.props.children[2],
        renderCell     : (params) => {
            return (
              'asd'
                // result.map((v,i)=>(
                //     v.id === params.id && <div>{v.BrandName}</div>
                // ))
            );
        }
    },
    { field: 'Condition', headerName: 'Condition', flex: 4 },
    { field: 'ValidDays', headerName: 'Valid Days', flex: 4 },
    { field: 'Created', headerName: 'Created',align:'right', headerAlign:'right', flex: 2 },
    { field: 'Customers', headerName: 'Customers', flex: 4 },
    { field: 'PhoneNumber', headerName: 'Phone Number', flex: 2, align:'right', headerAlign:'right' },
    { field: "", headerName: "", sortable: false, flex: 1, align:'right', disableClickEventBubbling: true,
      renderCell: (params) => {
          return (
              <div className="d-flex justify-content-between align-items-center cp " >
                <CustomSwitch/>
              </div>
          );
       }
    }
  ];

  const pushArr = (arr, body) => {
    arr.forEach((v,index) => {
        // body.push({
        //     id          : v._id,
        //     value       : v,
        //     BrandName   : <div><img width="32px" height="32px" className="borderRadius-50" src={v.brandLogo}/>  {" "+ utils.capitalizeAll(v.brandName)}</div>,
        //     Address     : v.location.address,
        //     Created     : new Date(v.created).getDate() + '-' + (new Date(v.created).getMonth()+1) + '-' + new Date(v.created).getFullYear(),
        //     PhoneNumber : v.phone,
        // })
    });
    return body
  }

  useEffect(onLoad, [])

  const data = [
    {
      deal: {
        img         : '',
        title       : 'Iced Sugar Cookie Almondmilk Latte',
        description : 'A delicious blend of white Cheddar and mozzarella cheeses on sourdough bread, topped with a Parmesan butter spread.'
      },
      condition : 'Per visit',
      validDays : '5',
      created   : '18 Nov 2021',
      customers : '0'
    }
  ]

  const onLoad = async() => {
    let body = []
    let arr  = []
        arr  = pushArr(data, body)
    setResult(arr)
}

  return (
    <div>
      <CustomTableV2
        pageSize      = {50}
        height        = {'calc(100vh - 120px)'}
        checkbox      = {false}
        rows          = {result}
        title         = {'Deals'}
        columns       = {columns}
        deleteAll     = {false}
        handleRefresh = {()=>console.log('aaaa')}
        // handleRefresh = {()=>onLoad(true)}
        onRowClick    = {(value)=>console.log('edit')}
      />
    </div>
  )
}

export default Table
