import * as yup from 'yup';
import { Communication } from '../enums';

const REQ_ERROR = 'This field is required'

const CreateEmail = yup.object({
    communicationType : yup.string().required(REQ_ERROR).oneOf([Communication.CommunicationTypes.EMAIL]),
    communicationName : yup.string().required(REQ_ERROR),
    active            : yup.boolean().required(),
    html              : yup.string().required(REQ_ERROR),
    json              : yup.object().required(REQ_ERROR),
    htmlCanvas        : yup.string().required(REQ_ERROR),
})

const CreateSMS = yup.object({
    communicationType: yup.string().required(REQ_ERROR).oneOf([Communication.CommunicationTypes.SMS]),
    communicationName: yup.string().required(REQ_ERROR),
    active           : yup.boolean().required(),
    message          : yup.string().required(REQ_ERROR),
    viewMessage      : yup.string().required(REQ_ERROR),
    linkCheck        : yup.boolean(),
    phone            : yup.string().required().matches(/^\d{1,10}$/),
})

const CreateNotification = yup.object({
    communicationType : yup.string().required(REQ_ERROR).oneOf([Communication.CommunicationTypes.NOTIFICATION]),
    communicationName : yup.string().required(REQ_ERROR),
    active            : yup.boolean().required(),
    message           : yup.string().required(REQ_ERROR),
    html              : yup.string(),
    htmlCanvas        : yup.string(),
})

export {
    CreateEmail,
    CreateSMS,
    CreateNotification
}