import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../components/CustomButton";
// import CustomsModal from '../../../../components/CustomModal';
import CustomModal from "../../../../components/CustomModal";
import CustomTextArea from "../../../../components/CustomTextArea";
import CustomTextField from "../../../../components/CustomTextField";
import { ImageModalV2 } from "../../../../components/ImageModalV2/ImageModalV2";
import LogoUploader from "../../../../components/LogoUploader";
import { LayoutContext } from "../../../../context/Layout.context";
import { ColorSchemeCode } from "../../../../enums/ColorScheme";
import { PngIcons, SvgIcons } from "../../../../icons";
import { InventoryVld } from "../../../../validation";
import localforage, * as localForage from "localforage";
import { ClickOutside, utils } from "../../../../utils";
import {
  CampaignService,
  DealService,
  EarningWayService,
  FlowService,
  InventoryService,
  RedeemingWayService,
} from "../../../../services";
import { BrandContext } from "../../../../context/Brand.context";
import { AgencyContext } from "../../../../context/Agency.context";
import { CircularProgress } from "@mui/material";
import { Brand, Campaign, enums } from "../../../../enums";
import CustomSearch from "../../../../components/CustomSearch";
import { useHistory } from "react-router-dom";
import ViewDetail from "../../../../components/ViewDetail";
import CustomsModal from "../../../../components/CustomModal";
import DeleteModal from "../../../../components/DeleteModal";
import CustomSwitch from "../../../../components/CustomSwitch";
import Loader from "../../../../components/Loader";
import Spinner from "../../../../components/Spinner";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core";

function Redeemingmain(props) {
  const layout = useContext(LayoutContext);
  const brand = useContext(BrandContext);
  const agency = useContext(AgencyContext);
  const history = useHistory();

  // const edit = history.location.state.edit
  const [dropIdx, setDropIdx] = useState();
  const [imageModal, setImageModal] = useState({
    logo: { open: false, imageEvent: "", return: "" },
    cover: { open: false, imageEvent: "", return: "" },
  });

  const [state, setstate] = useState({
    apiError: "",
    loader: false,
    deleteLoader: false,
    deleteModal: false,
    mainLoader: true,
    deleteItem: "",
    deleteIndex: "",
    result: [],
  });

  const [redeemingIndex,setRedeemingIndex]=useState();

  // const [modal1, setModal1] = useState(false);
  // const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  // const [modal4, setModal4] = useState(false);

  const [viewMore, setViewMore] = useState(false);
  const [index, setIndex] = useState();
  const [search, setSearch] = useState([]);


  const [disable, setDisable] = useState({
    redeemingWays: false,
    loyaltyBuilder: false
  })

  const initState = {
    _id: "",
    brandLogo: "",
    brandLogoFile: "",
    name: "",
    nameq: "",
    price: "0",
    description: "",
    edit: false,
  };



  useEffect(() => {
    layout.setLayout({
      modalPermission : false ,
      // title: disable.redeemingWays ? "Reward Builder":"Redeeming Ways",
      title: "Launch Pad",
      // title: "Reward Builder",
      // backTitles: ["Loyalty Suite"],
      // borderBottom: true,
      edit: false,
      switch: '',
      button: (<></>
        // <div className="">
        //   {/* <CustomButton
        //     icon={<SvgIcons.SettingIcon color={ColorSchemeCode.primary50} />}
        //     varient="secondary"
        //     onClick={() => setModal1(true)}
        //     disabled={state.mainLoader }
        //   /> */}
        //   <CustomButton
        //     varient={"primary"}
        //     className={"ml_8"}
        //     btntext={"Add Rule"}
        //     disabled={state.mainLoader}
        //     // btntext={disable.redeemingWays?"Create Flow":"Add ways to redeem"}
        //     onClick={() => {
        //       // if((!disable.loyaltyBuilder) && (!disable.redeemingWays)){
        //       //   // console.log('a');
        //       //   // setModal4(true)
        //       // }
        //       // else if(disable.loyaltyBuilder){
        //       //   // console.log('b');
        //       setModal3(true)
        //       // }
        //       // else if(disable.redeemingWays){
        //       //   // console.log('c');
        //       //   history.push({
        //       //     pathname: window.location.pathname + '/rewardadd',
        //       //     state: {...state.loyaltyBuilder[0], new: true, index: state?.loyaltyBuilder[0]?.flow?.length || 0 }
        //       //   })
        //       // }
        //       // else{
        //       //   // console.log('d');
        //       //   history.push({
        //       //     pathname: window.location.pathname + '/rewardadd',
        //       //     state: {...state.loyaltyBuilder[0], new: true, index: state?.loyaltyBuilder[0]?.flow?.length || 0 }
        //       //   })
        //       // }
        //     }}
        //   />
        // </div>
      ),
    });
  }, []);
  // }, [disable, state.mainLoader]);

  const [earningWays, setEarningWays] = useState([]);

  const onLoad = async (refresh) => {
    let campaign = await localforage.getItem('campaign');
    console.log('campaign',campaign);
    if ((!campaign) || campaign.length === 0) {
      console.log('campaign in',campaign);
      const ccampaign = await CampaignService.GetPublicCampaign({ query: { brandId: brand._id, delete: false, campaignType: 5 } })
      if (ccampaign.response?.data?.length > 0){
        campaign = ccampaign.response.data
        localForage.setItem("campaign", ccampaign.response.data);
      }
    }
    let ways = await localforage.getItem('earningways');
    console.log('ways',ways);
    if ((!ways) || ways.length === 0) {
      console.log('ways in',ways);
      const wways = await EarningWayService.GetQueriedEarningWaysPublic({ query: { campaignId: campaign[0]?._id || campaign?._id, brandId: brand._id } })
      if (wways.response?.data?.length > 0) {
        localForage.setItem("earningways", wways.response.data);
        ways = wways.response.data
      }
    }

    // console.log('wways ', ways)

    setEarningWays(ways);

    // console.log('ways ', ways)

    // setstate({ ...state, mainLoader: true });
    let deal = await localForage.getItem("deal");
    console.log('deal',deal);
    if (!deal) {
      console.log('deal in',deal);
      const { response, error } = await DealService.Get({
        query: { brandId: brand._id, delete: false },
      });
      if (response) {
        localForage.setItem("deal", response.data);
        deal = response.data;
      }
    }

    const query = { brandId: brand._id, delete: false}
    let redeemingWaysCheck = false;
    let loyaltyBuilderCheck = false;
    let redeemingWays = await localForage.getItem("redeemingWays");
    console.log('redeemingWays',redeemingWays);
    if(!redeemingWays){
      console.log('redeemingWays in',redeemingWays);
      redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
      console.log('redeemingWays res',redeemingWays);
      if (redeemingWays?.response){
        localForage.setItem("redeemingWays", redeemingWays.response.data);
        loyaltyBuilderCheck = true
      }
      else loyaltyBuilderCheck = false
      // console.log('query ',query)
    }
    else{
      loyaltyBuilderCheck = true
    }
    // console.log('redeemingWays ',redeemingWays)

    // let flow = await localForage.getItem("flow");
    // console.log('flow',flow);
    // if(!flow && !redeemingWays){
    //   console.log('flow in',flow);
    //   flow = await FlowService.Get({ query: { brandId: brand._id, delete: false, flowType: 'new' } })
    //   if (flow.response) {
    //     localForage.setItem("flow", flow.response.data);
    //     // console.log("Redeem Flow",flow?.response);
    //     redeemingWaysCheck = true
    //     setSearch(flow.response.data)
    //   }
    //   else redeemingWaysCheck = false
  
    //   // console.log('flow ',flow)
    // }
    // else{
      // redeemingWaysCheck = true
      // setSearch(flow)
    // }



    setDisable({ redeemingWays: redeemingWaysCheck, loyaltyBuilder: loyaltyBuilderCheck })

    if (redeemingWays?.response?.data) {
      redeemingWays.response.data.sort((a, b) => {
        if (a.created > b.created) return -1;
        else return 1;
      })
      setstate({ ...state, mainLoader: false, result: deal, redeemingWays: redeemingWays?.response?.data });
    }
    else if (redeemingWays) {console.log('redeemingWays',redeemingWays)
      redeemingWays?.sort((a, b) => {
        if (a?.created > b?.created) return -1;
        else return 1;
      })
      setstate({ ...state, mainLoader: false, result: deal, redeemingWays: redeemingWays });
    }
    // else if (flow?.response?.data) {
    //   setstate({ ...state, mainLoader: false, result: deal, loyaltyBuilder: flow?.response?.data });
    // }
    // else if (flow) {
    //   setstate({ ...state, mainLoader: false, result: deal, loyaltyBuilder: flow });
    // }
    else {
      setstate({ ...state, mainLoader: false, result: deal, redeemingWays: [] });
    }

  };

  useEffect(onLoad, []);

  const handleSubmit = async () => {
    setstate({ ...state, loader: true });

    if (formik.values.brandLogoFile == "" && formik.values.brandLogo == "") {
      utils.showToaster({
        position: "top-right",
        title: "Missing Reward Logo",
        message: "Reward Logo is required",
        severity: "error",
      });
    } else {
      const user = await localForage.getItem("user");
      const desiredPath = `${user._id}/${formik.values.productName}`;

      let logo;
      if (formik.values.brandLogoFile && !formik.errors.brandLogoFile) {
        logo = await utils.uploadImage({
          file: formik.values.brandLogoFile,
          desiredPath,
        });
        if (logo.error)
          return setstate({
            ...state,
            loader: false,
            apiError: logo.error.message,
          });
      }

      let payload = {
        name: formik.values.name.toLowerCase(),
        description: formik.values.description,
        image: logo && logo.response.data,
        price: formik.values.price,
        agencyId: agency._id,
        brandId: brand._id,
      };

      let payload2 = {
        name: formik.values.name.toLowerCase(),
        description: formik.values.description,
        image: logo && logo.response.data,
        agencyId: agency._id,
        brandId: brand._id,
        discountType: Brand.DealDiscountType.AMOUNT_TOTAL_PRODUCT,
        discountValue: 0,
        discountedPrice: formik.values.price,
        totalPrice: formik.values.price,
      };

      // console.log('====================================');
      // console.log(payload);
      // console.log(payload2);
      // console.log('====================================');

      if (!agency._id) delete payload2.agencyId;

      if (!formik.values.edit) {
        payload.prefix = utils.getRandomString(3);
        const { response, error } = await InventoryService.Create({
          toaster: true,
          payload,
        });
        if (error) setstate({ ...state, loader: false, apiError: error.error });
        else {
          let inventory = await localforage.getItem("inventory");
          if (inventory == null) inventory = [];
          inventory.push(response.data);
          localforage.setItem("inventory", inventory);

          payload2.items = [response.data._id];

          const dealres = await DealService.Create({
            payload: { ...payload2 },
          });
          if (dealres.error)
            setstate({
              ...state,
              loader: false,
              apiError: dealres.error.error,
            });
          else {
            let deal = await localForage.getItem("deal");
            if (deal === undefined || deal === null) deal = [];
            deal.push(dealres.response.data);
            localForage.setItem("deal", deal);
            state.result = deal;
            setstate({ ...state });
            // setModal2(false);
            // setModal1(false);
            setTimeout(() => {
              formik.setValues({ ...initState });
            }, 2000);
          }
        }
      } else {
        if (formik.values.name1 === formik.values.name) delete payload2.name;
        const { response, error } = await DealService.Update({
          toaster: true,
          payload: { ...payload2, _id: formik.values._id },
        });
        if (error) setstate({ ...state, loader: false, apiError: error.error });
        else {
          let deal = await localForage.getItem("deal");
          deal.map((element, idx) => {
            if (element._id === formik.values._id) deal[idx] = response.data;
          });
          localForage.setItem("deal", deal);
          state.result = deal;
          setstate({ ...state });
          // setModal2(false);
          // setModal1(false);
          setTimeout(() => {
            formik.setValues({ ...initState });
          }, 2000);
        }
      }
    }
    setstate({ ...state, loader: false });
  };

  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: InventoryVld.Create,
    // isInitialValid   : inventoryData && inventoryData.edit,
    isInitialValid: false,
    enableReinitialize: true,
  });

  const handlePriceChange = (e) => {
    if (e.target.value >= 0) {
      formik.setValues({ ...formik.values, price: e.target.value });
    }
  };

  const [processingElement, setProcessingElement] = useState();

  const handleEventSearchFunc = (e) => {
    const SearchResult = search.filter(element => {
      return element.name.toLowerCase().includes(e.target.value.toLowerCase())
    });
    // setFilteredData([...SearchResult])
    setstate({ ...state, loyaltyBuilder: [...SearchResult] })
  }
  // console.log('disable.redeemingWays',!disable.redeemingWays);
  // console.log('state',state);
  // console.log('earning spend',earningWays[0]?.active);
  return (
    <div>
      {/* {console.log('state?.redeemingWays')} */}
      
      {
        // (!disable.redeemingWays) || brand?.integrations?.cloudwaitress ?
          <div>
            {/*--------------- EMPTY STATE ---------------*/}
            {!state.mainLoader && state?.redeemingWays?.length === 0 && earningWays?.length === 0 ? (
              <div className="emptyState" style={{ height: "calc(100vh - 68px)" }}>
                {/* IMGAGE */}
                <img src={PngIcons.emptyImgRedeem} className="emptyImage" />
                {/* CONTENT */}
                <div className="gap-16 emptyBody">
                  <h2 className="Heading22M color-neutral100">
                    Before creating redeeming ways
                  </h2>
                  {/* BODY */}
                  <div className="Body16R color-neutral80 midd">
                    <div className="d-flex flex-column gap-12">
                      <div className="d-flex gap-8">
                        1. Go to settings situated on the top bar.
                        <img src={PngIcons.btnSetting} height="24px"></img>
                      </div>
                      <div className="d-flex">
                        2. List all your rewards there. 📦
                      </div>
                      <div className="d-flex">
                        3. Start making your redeeming ways. 🥳
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (

              // ----------------- LISTING START -----------------
              !state.mainLoader &&(
                <div className="pt_16 pl_50 pr_40">
                  


<div style={{ 
  display: 'grid', 
  gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
  gap: '16px' 
}}>
  {earningWays && earningWays.length > 0 && 
    earningWays.map((element, index) => (
      ((element.activity === Campaign.Activities.EARNING.BIRTHDAY && !element.active) ||
      (element.activity === Campaign.Activities.EARNING.CHECKIN && !element.active)) ? null :
      
      <Card key={index} sx={{ flex: '1 1 300px' }} onClick={() => {
        history.push({
          pathname: window.location.pathname.replace('redeem', 'reward/earn'),
          state: element
        })
      }}>
        {console.log('elementE',element)}
        
        <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
          <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div className="d-flex gap-6">
              <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
              {element.activity == Campaign.Activities.EARNING.SIGNUP?"Sign Up": element.activity === Campaign.Activities.EARNING.SPENDING ? "Points": element.earningName}
              </Typography>
              {console.log('element.earningValue',element.earningValue)}
              
              {<img src={element.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
            </div>
            <Typography variant="h6" color="text.secondary" style={{fontSize: '14px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              textOverflow: 'ellipsis',
            }}>
              {element.activity == Campaign.Activities.EARNING.SIGNUP?"Customize rewards for new customer signups.": element.activity === Campaign.Activities.EARNING.SPENDING ? "Set custom loyalty points for customer actions": element.earningName}
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            height="140"
            style={{ padding: 12, width: '140px' }}
            image={element.activity == Campaign.Activities.EARNING.SIGNUP
              ? PngIcons.newEWSU
              : element.activity === Campaign.Activities.EARNING.SPENDING
                ? PngIcons.newEWS
                : ""}
            alt={element.activity == Campaign.Activities.EARNING.SIGNUP?"Sign Up": element.activity === Campaign.Activities.EARNING.SPENDING ? "Points": element.earningName}
          />
        </CardActionArea>
      </Card>
    ))
  }
  {/* {console.log('state?.redeemingWays',state?.redeemingWays)} */}
  
  {state?.redeemingWays && state?.redeemingWays.length > 0 && 
    state?.redeemingWays.map((element, index) => (
      ((element.activity === Campaign.Activities.EARNING.BIRTHDAY) ||
      (element.activity === Campaign.Activities.EARNING.CHECKIN)) ? null :
      element.redeemingWayType !== enums.RedeemingWays.PRODUCT&&
      element.redeemingWayType !== enums.RedeemingWays.TAG&&
      
      <Card key={index} sx={{ flex: '1 1 300px', height: 'fit-content' }} onClick={
        () => {
          setRedeemingIndex(index);
          console.log('element.active',element.active);
          
          history.push({
          pathname: props.match.url + "/add",
          state: {
            _id: element._id,
            redeemingIndex:index,
            tag: element.tag ? element.tag : [],
            active: element.active === true? true: element.active === false?false: '',
            rewardDealId:
              element?.rewardValue?.rewardDealId ?
              element.rewardValue.rewardDealId : 0,
            name: element.name,
            Description: element.description && element.description,
            redeemingWayType:
              element.redeemingWayType,
            cwCode:
              element?.rewardValue?.cwCode ? element?.rewardValue?.cwCode : 0,
            notification:
              element?.notification ? element?.notification : false,
            PointCost:
              element?.rewardValue?.costingPoint ? element?.rewardValue?.costingPoint : 0,
            PointCostTo:
              element?.rewardValue?.costingPointTo ? element?.rewardValue?.costingPointTo : 0,
            PointCostBy:
              element?.rewardValue?.costingPointBy ? element?.rewardValue?.costingPointBy : 0,
            offerPoints:
              element?.rewardValue?.offerPoints ? element?.rewardValue?.offerPoints : 0,
            tagName:
              element?.rewardValue?.tagName ? element?.rewardValue?.tagName : "",
            numberofDays:
              element?.rewardValue?.numberofDays ? element?.rewardValue?.numberofDays : 0,
            PointCostType:
              element?.rewardValue?.costingPointType ? element?.rewardValue?.costingPointType : '',
            CustomerGets:
              element?.rewardValue?.discountValue ?
              element?.rewardValue?.discountValue : 0,
            DiscountType:
              element?.rewardValue?.productDiscountType ?
              element?.rewardValue?.productDiscountType : '',
            DiscountTypeValue:
              element?.rewardValue?.discountValue ?
              element?.rewardValue?.discountValue : '',
              BonusPoints : 
              element?.rewardValue?.bonusPoints ? element?.rewardValue?.bonusPoints : 0 ,
              DateType :
              element?.rewardValue?.dateType ? element?.rewardValue?.dateType : '',
              dateRange :
              element?.rewardValue?.dateRange ? element?.rewardValue?.dateRange : '',
              calenderValue :
              element?.rewardValue?.calenderValue ? element?.rewardValue?.calenderValue : '',
              onetime :
              element?.onetime ? element?.onetime: '' ,
              widget :
              element?.widget ? element?.widget: '' ,
          }
        });
     
      }
      }>
        <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
          <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div className="d-flex gap-6">
              <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
              {element.redeemingWayType === enums.RedeemingWays.REFERRAL?"Referral": 
              element.redeemingWayType === enums.RedeemingWays.INACTIVITY ? "Inactivity": 
              element.redeemingWayType === enums.RedeemingWays.BIRTHDAY ? "Birthday": 
              element.redeemingWayType === enums.RedeemingWays.PROMOTION ? "Promotion": 
              element.redeemingWayType === enums.RedeemingWays.PRODUCT ? "Points Reward": 
              "Else - "+element.name}
              </Typography>
              {<img src={element.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
            </div>
            <Typography variant="h6" color="text.secondary" style={{fontSize: '14px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              textOverflow: 'ellipsis',
            }}>
              {console.log('element222',element)}
              {element.redeemingWayType === enums.RedeemingWays.REFERRAL?"Share the love, earn the points": 
              element.redeemingWayType === enums.RedeemingWays.INACTIVITY ? "Reactivation Bonus: "+element.rewardValue.offerPoints+" bonus points for reactivating an inactive account":
              element.redeemingWayType === enums.RedeemingWays.BIRTHDAY ? "Delight customers with special points on their special day":
              element.redeemingWayType === enums.RedeemingWays.PROMOTION ? "Boost loyalty with multiplied points on selected dates":
              element.redeemingWayType === enums.RedeemingWays.PRODUCT ? 'Point Reward: Coupon “'+element.rewardValue.cwCode+'” for '+element.rewardValue.costingPoint+' points':
               'Else'}
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            height="140"
            style={{ padding: 12, width: '140px' }}
            image={element.redeemingWayType === enums.RedeemingWays.PRODUCT
              ? PngIcons.newRewardBlock
              : element.redeemingWayType ===
                enums.RedeemingWays.BIRTHDAY
                ? PngIcons.newBD
                : element.redeemingWayType === enums.RedeemingWays.REFERRAL
                ? PngIcons.NewRBlock
                : element.redeemingWayType ===
                enums.RedeemingWays.PROMOTION
                ? PngIcons.newPBlock
                : element.redeemingWayType ===
                enums.RedeemingWays.TAG
                ? PngIcons.automationTags
                : element.redeemingWayType ===
                enums.RedeemingWays.INACTIVITY
                ? PngIcons.NewIABlock
                :''}
            alt={element.earningName}
          />
        </CardActionArea>
      </Card>
    ))
  }
  {/* <div onClick={()=>{
    // setModal3(true)
    history.push({
      pathname: props.match.url + "/add",
      state: { redeemingWayType: "product", new: true },
    })
  }
    } className='w-100  trigger1' style={{ alignContent: 'center', color:'#2960EC', minHeight: '140px' }} varient='tertiary'>
    <SvgIcons.Add color={'#2960EC'}/><br/>
    Add New Rewards
  </div> */}
</div>
<div className="bar mt_40 mb_40"></div>
<div style={{ 
  display: 'grid', 
  gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
  gap: '16px' 
}}>
  {state?.redeemingWays && state?.redeemingWays.length > 0 && 
    state?.redeemingWays.map((element, index) => (
      ((element.activity === Campaign.Activities.EARNING.BIRTHDAY && !element.active) ||
      (element.activity === Campaign.Activities.EARNING.CHECKIN && !element.active)) ? null :
      element.redeemingWayType === enums.RedeemingWays.PRODUCT&&
      
      <Card key={index} sx={{ flex: '1 1 300px', height: 'fit-content' }} onClick={
        () => {
          setRedeemingIndex(index);
          history.push({
          pathname: props.match.url + "/add",
          state: {
            _id: element._id,
            redeemingIndex:index,
            tag: element.tag ? element.tag : [],
            active: element.active === true? true: element.active === false?false: '',
            rewardDealId:
              element?.rewardValue?.rewardDealId ?
              element.rewardValue.rewardDealId : 0,
            name: element.name,
            Description: element.description && element.description,
            redeemingWayType:
              element.redeemingWayType,
            cwCode:
              element?.rewardValue?.cwCode ? element?.rewardValue?.cwCode : 0,
            PointCost:
              element?.rewardValue?.costingPoint ? element?.rewardValue?.costingPoint : 0,
            PointCostTo:
              element?.rewardValue?.costingPointTo ? element?.rewardValue?.costingPointTo : 0,
            PointCostBy:
              element?.rewardValue?.costingPointBy ? element?.rewardValue?.costingPointBy : 0,
            offerPoints:
              element?.rewardValue?.offerPoints ? element?.rewardValue?.offerPoints : 0,
            tagName:
              element?.rewardValue?.tagName ? element?.rewardValue?.tagName : "",
            numberofDays:
              element?.rewardValue?.numberofDays ? element?.rewardValue?.numberofDays : 0,
            PointCostType:
              element?.rewardValue?.costingPointType ? element?.rewardValue?.costingPointType : '',
            CustomerGets:
              element?.rewardValue?.discountValue ?
              element?.rewardValue?.discountValue : 0,
            DiscountType:
              element?.rewardValue?.productDiscountType ?
              element?.rewardValue?.productDiscountType : '',
            DiscountTypeValue:
              element?.rewardValue?.discountValue ?
              element?.rewardValue?.discountValue : '',
              BonusPoints : 
              element?.rewardValue?.bonusPoints ? element?.rewardValue?.bonusPoints : 0 ,
              DateType :
              element?.rewardValue?.dateType ? element?.rewardValue?.dateType : '',
              dateRange :
              element?.rewardValue?.dateRange ? element?.rewardValue?.dateRange : '',
              calenderValue :
              element?.rewardValue?.calenderValue ? element?.rewardValue?.calenderValue : '',
              onetime :
              element?.onetime ? element?.onetime: '' ,
              widget :
              element?.widget ? element?.widget: '' ,
          }
        });
     
      }
      }>
        <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
          <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div className="d-flex gap-6">
              <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
              {element.redeemingWayType === enums.RedeemingWays.REFERRAL?"Referral": 
              element.redeemingWayType === enums.RedeemingWays.INACTIVITY ? "Inactivity": 
              element.redeemingWayType === enums.RedeemingWays.BIRTHDAY ? "Birthday": 
              element.redeemingWayType === enums.RedeemingWays.PROMOTION ? "Promotion": 
              element.redeemingWayType === enums.RedeemingWays.PRODUCT ? "Points Reward": 
              "Else - "+element.name}
              </Typography>
              {<img src={element.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
            </div>
            <Typography variant="h6" color="text.secondary" style={{fontSize: '14px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              textOverflow: 'ellipsis',
            }}>
              {/* {console.log('element',element)} */}
              {element.redeemingWayType === enums.RedeemingWays.REFERRAL?"Share the love, earn the points": 
              element.redeemingWayType === enums.RedeemingWays.INACTIVITY ? "Re-engage dormant customers with enticing":
              element.redeemingWayType === enums.RedeemingWays.BIRTHDAY ? "Delight customers with special points on their special day":
              element.redeemingWayType === enums.RedeemingWays.PROMOTION ? "Boost loyalty with multiplied points on selected dates":
              element.redeemingWayType === enums.RedeemingWays.PRODUCT ? 'Point Reward: Coupon “'+element.rewardValue.cwCode+'” for '+element.rewardValue.costingPoint+' points':
               'Else'}
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            height="140"
            style={{ padding: 12, width: '140px' }}
            image={element.redeemingWayType === enums.RedeemingWays.PRODUCT
              ? PngIcons.newRewardBlock
              : element.redeemingWayType ===
                enums.RedeemingWays.BIRTHDAY
                ? PngIcons.newBD
                : element.redeemingWayType === enums.RedeemingWays.REFERRAL
                ? PngIcons.NewRBlock
                : element.redeemingWayType ===
                enums.RedeemingWays.PROMOTION
                ? PngIcons.newPBlock
                : element.redeemingWayType ===
                enums.RedeemingWays.TAG
                ? PngIcons.automationTags
                : element.redeemingWayType ===
                enums.RedeemingWays.INACTIVITY
                ? PngIcons.NewIABlock
                :''}
            alt={element.earningName}
          />
        </CardActionArea>
      </Card>
    ))
  }
  <div onClick={()=>{
    // setModal3(true)
    history.push({
      pathname: props.match.url + "/add",
      state: { redeemingWayType: "product", new: true },
    })
  }
    } className='w-100  trigger1' style={{ alignContent: 'center', color:'#2960EC', minHeight: '140px' }} varient='tertiary'>
    <SvgIcons.Add color={'#2960EC'}/><br/>
    Add New Reward
  </div>
</div>

    
                  

                </div>
              )
            )}
            {state.mainLoader && (
              <Loader baseHeight={"60vh"} width={120} height={120} />
            )}

            <CustomsModal
              open={state.deleteModal}
              minWidth={"600px"}
              component={
                <DeleteModal
                  icon={state.deleteLoader}
                  title="Redeeming Way"
                  onCancel={() => setstate({ ...state, deleteModal: false })}
                  onDelete={async () => {
                    setstate({ ...state, deleteLoader: true });
                    const { response, error } = await RedeemingWayService.Update({
                      payload: { delete: true, _id: state.deleteItem._id },
                    });
                    if (response) {
                      state.redeemingWays.splice(state.deleteIndex, 1);
                      setstate({ ...state, deleteLoader: false, deleteModal: false });
                    }
                  }}
                />
              }
              onClose={() => setstate({ ...state, deleteModal: false })}
            />

            {/* <CustomModal
          open={modal1}
          onClose={() => setModal1(false)}
          maxWidth={880}
          minWidth={800}
          padding={"0px"}
          title={
            <div className="d-flex space-between  pt_32 pb_20 pr_24 pl_24">
              <div className="Heading22M color-neutral100">Settings</div>
              <div className="cp" onClick={() => setModal1(false)}>
                <SvgIcons.CrossIcon />
              </div>
            </div>
          }
          component={
            <Modal1Component
              initState={initState}
              setstate={setstate}
              state={state}
              formik={formik}
              setModal1={setModal1}
              setModal2={setModal2}
            />
          }
        /> */}

            {/* <CustomModal
          open={modal2}
          onClose={() => setModal2(false)}
          maxWidth={550}
          minWidth={500}
          title={
            <div className="d-flex space-between">
              <div className="Heading22M color-neutral100">
                {formik.values.edit ? "Edit Reward" : "Add Reward"}
              </div>
              <div
                className="cp"
                onClick={() => {
                  setModal2(false);
                  formik.setValues({ ...initState });
                }}
              >
                <SvgIcons.CrossIcon />
              </div>
            </div>
          }
          component={
            <Modal2Component
              initState={initState}
              state={state}
              handleSubmit={handleSubmit}
              formik={formik}
              setModal2={setModal2}
              setImageModal={setImageModal}
              imageModal={imageModal}
              handlePriceChange={handlePriceChange}
            />
          }
        /> */}

            <CustomModal
              open={modal3}
              onClose={() => setModal3(false)}
              maxWidth={550}
              minWidth={500}
              title={
                <div className="d-flex space-between ">
                  <div className="Heading22M color-neutral100 mt_8 mb_20">
                    {"Ways to redeem"}
                  </div>
                  <div
                    className="cp mt_8 mb_20"
                    onClick={() => {
                      setModal3(false);
                    }}
                  >
                    <SvgIcons.CrossIcon />
                  </div>
                </div>
              }
              component={
                <Modal3Component
                  props={props}
                  initState={initState}
                  state={state}
                  layout={layout}
                  handleSubmit={handleSubmit}
                  formik={formik}
                  setModal3={setModal3}
                  setImageModal={setImageModal}
                  imageModal={imageModal}
                  handlePriceChange={handlePriceChange}
                />
              }
            />

            {/* <CustomModal
          open={modal4}
          onClose={() => setModal4(false)}
          maxWidth={550}
          minWidth={500}
          title={
            <div className="d-flex space-between ">
              <div className="Heading22M color-neutral100 ">
                {"Select type"}
              </div>
              <div
                className="cp "
                onClick={() => {
                  setModal4(false);
                }}
              >
                <SvgIcons.CrossIcon />
              </div>
            </div>
          }
          component={
            <Modal4Component
              props={props}
              initState={initState}
              state={state}
              handleSubmit={handleSubmit}
              formik={formik}
              setModal4={setModal4}
              setModal3={setModal3}
              setImageModal={setImageModal}
              imageModal={imageModal}
              handlePriceChange={handlePriceChange}
            />
          }
        /> */}
          </div>
        //   :
        //   <div>

        //     {/*--------------- EMPTY STATE ---------------*/}
        //     {!state.mainLoader && state?.loyaltyBuilder?.length === 0 && earningWays?.length === 0 ? (
        //       <div className="emptyState" style={{ height: "calc(100vh - 68px)" }}>
        //         {/* IMGAGE */}
        //         <img src={PngIcons.emptyImgRedeem} className="emptyImage" />
        //         {/* CONTENT */}
        //         <div className="gap-16 emptyBody">
        //           <h2 className="Heading22M color-neutral100">
        //             Before creating reward builder
        //           </h2>
        //           {/* BODY */}
        //           <div className="Body16R color-neutral80 midd">
        //             <div className="d-flex flex-column gap-12">
        //               <div className="d-flex gap-8">
        //                 1. Go to settings situated on the top bar.
        //                 <img src={PngIcons.btnSetting} height="24px"></img>
        //               </div>
        //               <div className="d-flex">
        //                 2. List all your rewards there. 📦
        //               </div>
        //               <div className="d-flex">
        //                 3. Start making your reward builder. 🥳
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     ) : (
        //       // ----------------- LISTING START -----------------
        //       !state.mainLoader && (
        //         <div className="pt_16 pb_16 pl_50 pr_40">
        //           <div className='d-flex pt_14 pb_14 pl_30 pr_30 cp w-100 color-neutral90 Link12M audienceTableGrid overflow-hidden'>
        //             <div className='col'
        //             >
        //               {/* {state?.loyaltyBuilder?.length+" FLOW"} */}
        //               Name
        //             </div>
        //             <div className='col-4 Link11M'>
        //               {'Last Updated'}
        //             </div>
        //           </div>
        //           {
        //             // console.log('earningWays',earningWays)
        //           }
        //           {earningWays && earningWays.length > 0 && earningWays.map((element, idx) => (
        //             element.activity != Campaign.Activities.EARNING.CHECKIN &&
        //             <>
        //               <div
        //                 key={idx}
        //                 className={
        //                   "row align-items-center tableRowNew pt_26 pb_26 pl_30 pr_30 tableRowHover singleProgram cp m_0 "
        //                 }
        //                 onClick={() => {
        //                   history.push({
        //                     pathname: window.location.pathname + '/earn',
        //                     state: element
        //                   })
        //                 }}
        //               >
        //                 <div className="col">
        //                   <div className='Heading15M color-neutral70'>
        //                     {element.earningName}
        //                   </div>
        //                 </div>
        //                 <div className='col-4 color-neutral90 Link13R'>
        //                   {new Date(element.updated || element.created).toDateString()}
        //                 </div>
        //               </div>
        //             </>
        //           ))}
        //           {state?.loyaltyBuilder?.length>0 && state.loyaltyBuilder.map((element, idx) => (
        //             element?.flow?.length > 0 && element.flow.map((ele, ind) => (
        //               <>
        //                 <div
        //                   key={ind}
        //                   className={
        //                     "row align-items-center pt_26 pb_26 pl_30 pr_30 tableRowNew tableRowHover singleProgram cp m_0"
        //                   }
        //                   onClick={() => {
        //                     history.push({
        //                       pathname: window.location.pathname + '/rewardadd',
        //                       state: { ...element, index: ind }
        //                     })
        //                   }}
        //                 >
        //                   <div className="col">
        //                     <div className='Heading15M color-neutral100'>
        //                       {ele.block || element.name}
        //                     </div>
        //                   </div>
        //                   <div className='col-4 color-neutral90 Link13R'>
        //                     {new Date(element.updated || element.created).toDateString()}
        //                   </div>
        //                 </div>
        //               </>
        //             ))
        //           ))
        //           }
        //         </div>
        //       )
        //     )}
        //     {state.mainLoader && (
        //       <Loader baseHeight={"60vh"} width={120} height={120} />
        //     )}

        //     <CustomsModal
        //       open={state.deleteModal}
        //       minWidth={"600px"}
        //       component={
        //         <DeleteModal
        //           icon={state.deleteLoader}
        //           title="Redeeming Way"
        //           onCancel={() => setstate({ ...state, deleteModal: false })}
        //           onDelete={async () => {
        //             setstate({ ...state, deleteLoader: true });
        //             const { response, error } = await RedeemingWayService.Update({
        //               payload: { delete: true, _id: state.deleteItem._id },
        //             });
        //             if (response) {
        //               state.redeemingWays.splice(state.deleteIndex, 1);
        //               setstate({ ...state, deleteLoader: false, deleteModal: false });
        //             }
        //           }}
        //         />
        //       }
        //       onClose={() => setstate({ ...state, deleteModal: false })}
        //     />

        //     {/* <CustomModal
        //   open={modal1}
        //   onClose={() => setModal1(false)}
        //   maxWidth={880}
        //   minWidth={800}
        //   padding={"0px"}
        //   title={
        //     <div className="d-flex space-between  pt_32 pb_20 pr_24 pl_24">
        //       <div className="Heading22M color-neutral100">Settings</div>
        //       <div className="cp" onClick={() => setModal1(false)}>
        //         <SvgIcons.CrossIcon />
        //       </div>
        //     </div>
        //   }
        //   component={
        //     <Modal1Component
        //       initState={initState}
        //       setstate={setstate}
        //       state={state}
        //       formik={formik}
        //       setModal1={setModal1}
        //       // setModal2={setModal2}
        //     />
        //   }
        // /> */}

        //     {/* <CustomModal
        //   open={modal2}
        //   onClose={() => setModal2(false)}
        //   maxWidth={550}
        //   minWidth={500}
        //   title={
        //     <div className="d-flex space-between">
        //       <div className="Heading22M color-neutral100">
        //         {formik.values.edit ? "Edit Reward" : "Add Reward"}
        //       </div>
        //       <div
        //         className="cp"
        //         onClick={() => {
        //           setModal2(false);
        //           formik.setValues({ ...initState });
        //         }}
        //       >
        //         <SvgIcons.CrossIcon />
        //       </div>
        //     </div>
        //   }
        //   component={
        //     <Modal2Component
        //       initState={initState}
        //       state={state}
        //       handleSubmit={handleSubmit}
        //       formik={formik}
        //       setModal2={setModal2}
        //       setImageModal={setImageModal}
        //       imageModal={imageModal}
        //       handlePriceChange={handlePriceChange}
        //     />
        //   }
        // /> */}

        //     <CustomModal
        //       open={modal3}
        //       onClose={() => setModal3(false)}
        //       maxWidth={550}
        //       minWidth={500}
        //       title={
        //         <div className="d-flex space-between ">
        //           <div className="Heading22M color-neutral100 mt_8 mb_20">
        //             {"Ways to redeem"}
        //           </div>
        //           <div
        //             className="cp mt_8 mb_20"
        //             onClick={() => {
        //               setModal3(false);
        //             }}
        //           >
        //             <SvgIcons.CrossIcon />
        //           </div>
        //         </div>
        //       }
        //       component={
        //         <Modal3Component
        //           props={props}
        //           layout={layout}
        //           initState={initState}
        //           state={state}
        //           handleSubmit={handleSubmit}
        //           formik={formik}
        //           setModal3={setModal3}
        //           setImageModal={setImageModal}
        //           imageModal={imageModal}
        //           handlePriceChange={handlePriceChange}
        //         />
        //       }
        //     />

        //     {/* <CustomModal
        //   open={modal4}
        //   onClose={() => setModal4(false)}
        //   maxWidth={550}
        //   minWidth={500}
        //   title={
        //     <div className="d-flex space-between ">
        //       <div className="Heading22M color-neutral100 ">
        //         {"Select type"}
        //       </div>
        //       <div
        //         className="cp "
        //         onClick={() => {
        //           setModal4(false);
        //         }}
        //       >
        //         <SvgIcons.CrossIcon />
        //       </div>
        //     </div>
        //   }
        //   component={
        //     <Modal4Component
        //       props={props}
        //       initState={initState}
        //       state={state}
        //       handleSubmit={handleSubmit}
        //       formik={formik}
        //       setModal4={setModal4}
        //       setModal3={setModal3}
        //       setImageModal={setImageModal}
        //       imageModal={imageModal}
        //       handlePriceChange={handlePriceChange}
        //     />
        //   }
        // /> */}
        //   </div>
      }
    </div>
  );
}

// const Modal4Component = ({
//   setModal4,
//   setModal3,
//   formik,
//   setImageModal,
//   imageModal,
//   handlePriceChange,
//   handleSubmit,
//   state,
//   initState,
//   props,
// }) => {
//   const history = useHistory();
//   return (
//     <div>
//       <div className="Body14R color-neutral60 mb_20">
//         Please choose the loyalty type you want to create; you can only utilise one loyalty type at a time; the rest will be inactive.
//       </div>
//       <div className="d-flex mt_16 mb_16 gap-8">
//         <div className="middle bg-color-neutral0 pt_24 pb_24 pl_16 pr_16 borderRadius-4 cp" onClick={() => {setModal3(true); setModal4(false)}}>
//           <div className="Heading16M color-neutral100">Basic</div>
//           <div className="color-neutral80 Link13R">There are just three types of basic redeeming options: reward offers, dollar discounts, and percentage off.</div>
//         </div>
//         <div className="middle bg-color-neutral0 pt_24 pb_24 pl_16 pr_16 borderRadius-4 cp"
//         onClick={()=>{
//           history.push({
//             pathname: window.location.pathname + '/rewardadd'//,
//               // state: { edit: true }
//           })
//         }}>
//           <div className="Heading16M color-neutral100">Advance</div>
//           <div className="color-neutral80 Link13R">You will be able to employ communication channels and construct conditional redemption techniques in advance.</div>
//         </div>
//       </div>

//       <div
//         className="bar"
//         style={{ marginLeft: "-25px", width: "110.5%" }}
//       ></div>

//       <div
//         className="w-100 d-flex justify-content-right mt_20"
//         style={{ marginBottom: "-4px" }}
//       >
//         <CustomButton
//           size="s"
//           varient="secondary"
//           btntext={"Cancel"}
//           onClick={() => setModal4(false)}
//         />
//       </div>
//     </div>
//   );
// };

const Modal3Component = ({
  setModal3,
  formik,
  setImageModal,
  imageModal,
  handlePriceChange,
  handleSubmit,
  state,
  initState,
  props,
  // layout
}) => {
  const history = useHistory();
  const brand = useContext(BrandContext);
  const layout = useContext(LayoutContext);

  const [disable, setDisable] = useState({
    birthday: false,
    referral: false,
  })

  const handleDisableBirthdayFunc = (key) => {
    let found = state?.redeemingWays.filter((redeemingway) => {
      return redeemingway.redeemingWayType == "birthday"
    })
    return found;
  }

  const handleDisableReferralFunc = () => {
    let found = state?.redeemingWays.filter((redeemingway) => {
      return redeemingway.redeemingWayType == "referral"
    })
    return found;
  }


  useEffect(async () => {
    let birthday = handleDisableBirthdayFunc();
    let referral = handleDisableReferralFunc();
    setDisable({ ...disable, birthday: birthday.length > 0 ? true : false, referral: referral.length > 0 ? true : false })
  }, state.redeemingWays)

  return (
    <div className="">
      <div className="pt_16 pb_16">
        {/* <div
          className={`d-flex align-items-center p_8 cp waystoredeem ${brand?.integrations?.cloudwaitress ? 'disabled' : ''}`}
          onClick={() =>
            history.push({
              pathname: props.match.url + "/add",
              state: { redeemingWayType: "amount" },
            })
          }
        >
          <img src="/images/AmountDiscount.png" alt="AmountDiscount" />
          <div className="Heading16M color-neutral100 ml_16">
            Amount Discount
          </div>
        </div> */}
        {/* <div
          className={`d-flex align-items-center p_8 cp waystoredeem ${brand?.integrations?.cloudwaitress ? 'disabled' : ''}`}
          onClick={() =>
            history.push({
              pathname: props.match.url + "/add",
              state: { redeemingWayType: "percent" },
            })
          }
        >
          <img src="/images/PercentageOff.png" alt="PercentageOff" />
          <div className="Heading16M color-neutral100 ml_16">
            Percentage Off
          </div>
        </div> */}
        <div
          className="d-flex align-items-center p_8 cp waystoredeem"
          onClick={() =>{
            // layout.setLayout({
            //   edit: true,
            //   placeholder: "New Rule",
            //   editTitle: "New Rule"
            // })
            history.push({
              pathname: props.match.url + "/add",
              state: { redeemingWayType: "product", new: true },
            })
          }}
        >
          <img src={PngIcons.automationReward} width={'48px'} height={'48px'} alt="ProductOffer" />
          <div className="Heading16M color-neutral100 ml_16">
            Reward Offer
          </div>
        </div>
        <div
          className={`d-flex align-items-center p_8 cp waystoredeem ${disable.referral && 'disabled'}`}
          onClick={() =>
            history.push({
              pathname: props.match.url + "/add",
              state: { redeemingWayType: enums.RedeemingWays.REFERRAL },
            })
          }
        >
          <img src={PngIcons.automationRefferal} width={'48px'} height={'48px'}  alt="ProductOffer" />
          <div className="Heading16M color-neutral100 ml_16">
            Referral
          </div>
        </div>
        <div
          className={`d-flex align-items-center p_8 cp waystoredeem `}
          onClick={() =>
            history.push({
              pathname: props.match.url + "/add",
              state: { redeemingWayType: enums.RedeemingWays.INACTIVITY },
            })
          }
        >
          <img src={PngIcons.automationInactivity} width={'48px'} height={'48px'}  alt="ProductOffer" />
          <div className="Heading16M color-neutral100 ml_16">
            Inactivity
          </div>
        </div>
        <div
          className={`d-flex align-items-center p_8 cp waystoredeem ${disable.birthday && 'disabled'} `}
          onClick={() =>
            history.push({
              pathname: props.match.url + "/add",
              state: { redeemingWayType: enums.RedeemingWays.BIRTHDAY },
            })
          }
        >
          <img src={PngIcons.automationBirthday} width={'48px'} height={'48px'}  alt="ProductOffer" />
          <div className="Heading16M color-neutral100 ml_16">
            Birthday
          </div>
        </div>
        <div
          className={`d-flex align-items-center p_8 cp waystoredeem  `}
          onClick={() =>
            history.push({
              pathname: props.match.url + "/add",
              state: { redeemingWayType: enums.RedeemingWays.PROMOTION },
            })
          }
        >
          <img src={PngIcons.automationPromoton} width={'48px'} height={'48px'}  alt="ProductOffer" />
          <div className="Heading16M color-neutral100 ml_16">
            Promotion
          </div>
        </div>
        <div
          className={`d-flex align-items-center p_8 cp waystoredeem  `}
          onClick={() =>
            history.push({
              pathname: props.match.url + "/add",
              state: { redeemingWayType: enums.RedeemingWays.TAG },
            })
          }
        >
          <img src={PngIcons.automationTags} width={'48px'} height={'48px'}  alt="ProductOffer" />
          <div className="Heading16M color-neutral100 ml_16">
            Tags
          </div>
        </div>
      </div>
      <div
        className="bar"
        style={{ marginLeft: "-25px", width: "110.5%" }}
      ></div>

      <div
        className="w-100 d-flex justify-content-right mt_20"
        style={{ marginBottom: "-4px" }}
      >
        <CustomButton
          size="s"
          varient="secondary"
          btntext={"Cancel"}
          onClick={() => setModal3(false)}
        />
      </div>
    </div>
  );
};

// const Modal1Component = ({
//   setModal1,
//   setModal2,
//   formik,
//   state,
//   setstate,
//   initState,
// }) => {
//   const brand = useContext(BrandContext);
//   const [filtered, setFiltered] = useState(state.result);
//   const [data, setData] = useState(state.result);
//   const [deleteModal, setDeleteModal] = useState(false);
//   const [deleteLoader, setDeleteLoader] = useState(false);

//   const [index1, setIndex] = useState();

//   const [viewMore, setViewMore] = useState([]);

//   useEffect(() => {
//     let temp = [];
//     if (filtered && filtered.length > 0) {
//       for (const value of filtered) {
//         temp.push(false);
//       }
//     }
//     setViewMore(temp);
//   }, [filtered]);

//   const ViewMoreIconClickedFunc = (e, index) => {
//     e.stopPropagation();
//     e.preventDefault();
//     if (index1 === index) setIndex();
//     else setIndex(index);
//   };

//   const handleDelete = async (e) => {
//     setDeleteLoader(true);
//     const payload = {
//       _id: formik.values._id,
//       delete: true,
//     };

//     // console.log('payload', payload);


//     const { error, response } = await DealService.Update({
//       toaster: true,
//       payload,
//     });

//     // console.log('response', response, error);
//     if (error) setstate({ ...state, loader: false, apiError: error.error });

//     let inventory = await localForage.getItem("deal");
//     let temp = [];
//     inventory.map((element, idx) => {
//       if (element._id !== formik.values._id) temp.push(element);
//     });
//     localForage.setItem("deal", temp);
//     state.result = temp;
//     setstate({ ...state });
//     formik.setValues({ ...initState });
//     setDeleteLoader(false);
//     setDeleteModal(false);
//     setModal2(false);
//   };

//   const handleSearch = (e) => {
//     const results = state.result.filter((item) =>
//       item.name.toLowerCase().includes(e.target.value.toLowerCase())
//     );
//     // setFiltered(results);
//     setData(results);
//   };

//   return (
//     // PRODUCT LISTING MODAL
//     <div className="pr_24 pl_24 pt_16 pb_16">
//       {/* {console.log('child 1',state.result.length)} */}
//       <div className="d-flex space-between pb_16 ">
//         <div>
//           <h3 className="Heading16M color-neutral90 m_0 pb_8">
//             Reward Setup
//           </h3>
//           <p className="Body14R color-neutral60">
//             Please Setup reward for your customer.
//           </p>
//         </div>
//       </div>
//       {/* <div className='Heading16M color-neutral100'>Product Listing</div>
//             <div className='Body14R color-neutral60 mb_16 mt_6'>In place an order earning way, customer will earn points every time they place any order.</div> */}
//       {filtered && filtered.length > 0 ? (
//         <>
//         <div className="d-flex space-between align-items-center pb_16 border-bottom">
//           <CustomButton
//             size="s"
//             btntext={"Add Reward"}
//             onClick={() => {
//               // console.log('====================================');
//               // console.log(formik.values);
//               // console.log('====================================');
//               formik.setValues({ ...formik.values, price: "0", edit: false });
//               setModal2(true);
//             }}
//           />
//           <CustomSearch
//             placeholder={"Filter by email or name"}
//             className={"w-40 searchField"}
//             position={"start"}
//             height="24px"
//             fontSize="12px"
//             lineHeight="16px"
//             borderRadius="100px"
//             onChange={handleSearch}
//             // disableEndIcon = {true}
//           />
//         </div>

//       <div
//         style={{
//           height: filtered && filtered.length > 0 && "400px",
//           overflowY: filtered && filtered.length > 0 && "scroll",
//         }}
//       >
//         {data && data.length > 0 ? (
//           data.map((value, index) => (
//             <>
//               <div className="row tableRowHover transition w-100 pt_8 pb_8 align-items-center m_0">
//                 <div className="col-1 ">
//                   <img
//                     style={{
//                       border: "0.3px solid #D6DAE9",
//                       borderRadius: "4px",
//                       objectFit: "cover",
//                     }}
//                     width={40}
//                     height={40}
//                     src={value.image}
//                   />
//                 </div>
//                 <div className="col-7">
//                   <div className="Link14M color-neutral100 ">
//                     {utils.capitalize(value.name)}
//                   </div>
//                 </div>
//                 <div className="col-3 Link13R color-neutral90 text-align-right">
//                   {brand.currency.symbol + " " + value.discountedPrice}
//                 </div>
//                 <div className="col-1">
//                   {
//                     <ClickOutside
//                       onClick={() => {
//                         if (index1 == index) setIndex();
//                       }}
//                     >
//                       <div
//                         className="viewMoreIcon float-right rotate-right cp"
//                         onClick={(e) => ViewMoreIconClickedFunc(e, index)}
//                       >
//                         <SvgIcons.ViewMoreIcon />
//                       </div>
//                       {index1 == index && (
//                         <div
//                           className="viewdetail position-absolute right-0"
//                           style={{ top: "10px", zIndex: "1" }}
//                         >
//                           <ViewDetail
//                             items={[
//                               {
//                                 name: "Edit",
//                                 function: (e) => {
//                                   formik.setValues({
//                                     ...formik.values,
//                                     _id: value._id,
//                                     brandLogo: value.image,
//                                     name: value.name,
//                                     name1: value.name,
//                                     price: value.discountedPrice,
//                                     description: value.description,
//                                     edit: true,
//                                   });
//                                   setModal2(true);
//                                 },
//                               },
//                               {
//                                 name: "Delete",
//                                 function: () => {
//                                   formik.setValues({
//                                     ...formik.values,
//                                     _id: value._id,
//                                   });
//                                   setDeleteModal(true);
//                                 },
//                               },
//                             ]}
//                           />
//                         </div>
//                       )}
//                     </ClickOutside>
//                   }
//                 </div>
//               </div>
//               {index !== filtered.length - 1 && (
//                 <div className="border-bottom "></div>
//               )}
//             </>
//           ))
//         ) : (
//           <div className="emptyState-sm">
//             {/* CONTENT */}
//             <div className="emptyBody">
//               <div className="Body14R color-neutral60 mb_0">
//                 No Reward Found
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//       </>)
//       :
//           <div className="emptyState-sm">
//             {/* IMGAGE */}
//             <img src={PngIcons.emptyProductListing} className="emptyImage-sm" />
//             {/* CONTENT */}
//             <div className="emptyBody">
//               <h2 className="Heading16M color-neutral80 mb_0">
//                 Create your Reward Listing
//               </h2>
//               {/* BODY */}
//               <div className="Body14R color-neutral60 midd">
//                 <p>
//                   Turn on “Follow this page” in the Updates menu on any page to
//                   receive updates here.
//                 </p>
//               </div>
//             </div>
//             <CustomButton
//               varient={"primary"}
//               size="s"
//               btntext={"Add Reward"}
//               onClick={() => {
//                 formik.setValues({ ...formik.values, edit: false });
//                 setModal2(true);
//               }}
//             />
//           </div>

//       }
//       {/* <div
//         className="bar mb_12"
//         style={{ marginLeft: "-25px", width: "106.5%" }}
//       ></div> */}
//       {/* <div
//         className="w-100 d-flex pb_8 pt_8 justify-content-right"
//         style={{ marginBottom: "-12px" }}
//       >
//         <CustomButton
//           size="s"
//           varient="secondary"
//           btntext={"Cancel"}
//           onClick={() => setModal1(false)}
//         />
//       </div> */}

//       <CustomsModal
//         open={deleteModal}
//         minWidth={"600px"}
//         component={
//           <DeleteModal
//             icon={deleteLoader}
//             title="Reward"
//             onCancel={() => setDeleteModal(false)}
//             onDelete={handleDelete}
//           />
//         }
//         onClose={() => setDeleteModal(false)}
//       />
//     </div>
//   );
// };

// const Modal2Component = ({
//   setModal2,
//   formik,
//   setImageModal,
//   imageModal,
//   handlePriceChange,
//   handleSubmit,
//   state,
//   initState,
// }) => {
//   const brand = useContext(BrandContext);
//   return (
//     <div>
//       {/* {console.log('child 2',state.result.length)} */}
//       <div id="AddInventory2">
//         <div className="modalComponent">
//           <div className="mt_16">
//             <CustomTextField
//               label="Reward Name"
//               className="w-100"
//               name="name"
//               placeholder="Enter name"
//               value={formik.values.name}
//               onChange={formik.handleChange}
//               inputProps={{ onFocus: formik.handleBlur }}
//               error={formik.touched.name && formik.errors.name}
//               helperText={
//                 formik.touched.name && formik.errors.name
//                   ? formik.errors.name
//                   : ""
//               }
//             />
//           </div>

//           <div
//             className="col-12 pl_0 pr_0 z-index-20 "
//             style={{ height: "165px" }}
//           >
//             <LogoUploader
//               src={
//                 formik.values.brandLogoFile
//                   ? URL.createObjectURL(formik.values.brandLogoFile)
//                   : formik.values.brandLogo
//               }
//               label="Brand Cover"
//               // size         = 's'
//               displayLabel="Deal Image"
//               hint={
//                 <div>
//                   <div className="Link12R color-neutral60">
//                     File requirements JPG, PNG, WebP upto 10 MB.
//                   </div>
//                   <div className="Link12R color-neutral60">
//                     Minimum pixels required: 550 x 440.
//                   </div>
//                 </div>
//               }
//               className="mt_24 mb_24 pl_0"
//               error={formik.values.brandLogoFile && formik.errors.brandLogoFile}
//               btntext="Upload Deal Image"
//               btnName="Upload"
//               htmlFor={"logoUpload"}
//               onChange={(e) => {
//                 setImageModal({
//                   ...imageModal,
//                   logo: { ...imageModal.logo, open: true, imageEvent: e },
//                 });
//               }}
//             />
//           </div>
//           <div className="error">{formik.errors.brandLogoFile}</div>

//           <div className="mt_16">
//             <CustomTextField
//               label="Reward Price (Optional)"
//               paddingLeft="55px"
//               className="w-100"
//               name="price"
//               type="number"
//               icon={brand.currency.symbol}
//               position="start"
//               placeholder="Enter price"
//               value={formik.values.price}
//               inputProps={{ onFocus: formik.handleBlur }}
//               onChange={(e) => handlePriceChange(e)}
//               error={formik.touched.price && formik.errors.price}
//               helperText={
//                 formik.touched.price && formik.errors.price
//                   ? formik.errors.price
//                   : ""
//               }
//             />
//           </div>
//           <div className="mt_16 mb_24">
//             <CustomTextArea
//               label="Description (Optional)"
//               className="w-100"
//               name="description"
//               placeholder="Enter short description about the reward"
//               value={formik.values.description}
//               onChange={formik.handleChange}
//               inputProps={{ onFocus: formik.handleBlur }}
//               error={formik.touched.description && formik.errors.description}
//               helperText={
//                 formik.touched.description && formik.errors.description
//                   ? formik.errors.description
//                   : ""
//               }
//             />
//           </div>
//         </div>

//         <CustomModal
//           open={imageModal.logo.open}
//           onClose={() =>
//             setImageModal({
//               ...imageModal,
//               logo: { ...imageModal.logo, open: false },
//             })
//           }
//           minWidth="470px"
//           minHeight="470px"
//           padding="0px"
//           component={
//             <ImageModalV2
//               imageModal={imageModal}
//               setImageModal={setImageModal}
//               image={"logo"}
//               type={"logo"}
//               formik={formik}
//             />
//           }
//         />
//       </div>
//       <div className="bar mb_20 mt_32"></div>
//       <div className="w-100 d-flex justify-content-right">
//         <CustomButton
//           varient="secondary"
//           btntext={"Cancel"}
//           onClick={() => {
//             setModal2(false);
//             setTimeout(() => {
//               formik.setValues({ ...initState });
//             }, 2000);
//           }}
//         />
//         <CustomButton
//           disabled={state.loader || !formik.isValid}
//           onClick={handleSubmit}
//           icon={
//             state.loader && <CircularProgress size={20} color={"inherit"} />
//           }
//           className={"ml_10"}
//           btntext={formik.values.edit ? "Update" : "Create"}
//         />
//       </div>
//     </div>
//   );
// };

export default Redeemingmain;
