import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { CampaignService, GrowthtoolService, BrandService, PassService, EarningWayService, TierService, FlowService, UserService } from '../../services'
import Loader from '../../components/Loader'
import { Campaign, enums, GrowthTools, PassDesign } from '../../enums';
import { AgencyContext } from '../../context/Agency.context';
import {useHistory} from 'react-router-dom';
import localforage from 'localforage';
import { showToaster } from '../../utils/utils';

import MobileDetect from 'mobile-detect'
import env from '../../config';

let campaign, earningWays, location, brandData, disable = false, tiers;
let user = {firstName : '' , lastName : ''}
let growthToolId = '';



const createPass = ({formValues, brandData, campaign, growthToolType}) => {
    if(!brandData || !campaign) return {success: false, message: 'missing credentials'}
    

    const payload = {...formValues, brandId: brandData._id, campaignCode: campaign.campaignCode}

    if(growthToolType === GrowthTools.GrowhttoolTypes.POS) payload.growthToolType = GrowthTools.GrowhttoolTypes.POS


    if(formValues.phone && (formValues.phone.includes('+') || formValues.phone.includes('-')))
    return showToaster({
        position : 'top-right',
        title    : 'incorrect phone',
        message  : 'incorrect phone format',
        severity : 'error'
    })

 
    if(formValues.phone && (formValues.phone[0] === 0 || formValues.phone[0] === '0')){
        payload.phone       = formValues.phone.slice(1)
    }
    else
    {
        payload.phone       = formValues.phone&&formValues.phone
    }

    var md = new MobileDetect(window.navigator.userAgent);
    let os;
    if(md.os()){
        os = md.os().toLowerCase();
    }
    if(os) payload.os = os;
    // else payload.os = 'web'

    const headers = {'x-api-key': brandData.apiKey, 'Accept-Encoding': 'gzip'}
    user = {name : payload.firstName + ' ' + payload.lastName}
    payload.growthToolId = growthToolId;
    return PassService.Create({payload, headers})
}

const submitFunctions = {
    createPass : createPass
}

function LandingPage() {

    const [redemptionType, setRedemptionType] = useState()
      
    const params = useParams()
    const history = useHistory();
    const [brand, setBrand] = useState();
    const [gId, setGid] = useState();
    const [state, setState] = useState({
        loader       : true,
        growthtool   : '',
        apiError     : '',
        brand        : ''
    })

    const agency = useContext(AgencyContext)

    const getCampaign = async (_id) => {
        let query = {_id}
        const {error, response} = await CampaignService.GetPublicCampaign({query})
        if(response && response.data) campaign = response.data
        if(error) return error
        query = {campaignId : response.data._id}
        const tiersData = await TierService.GetPublic({query})
        if(tiersData.response) tiers = tiersData.response.data;
    }

    const getBrand = async (_id) => {
        const query = {_id, delete: false}
        const {error, response} = await BrandService.GetPublic({query})
        if(response && response.data) { brandData = response.data; return response.data;}
        return error
    }

    const getEarningWays = async () => {
        let query = {brandId: campaign.brandId, campaignId: campaign._id, activity: Campaign.Activities.EARNING.CHECKIN}
        const {response, error} = await EarningWayService.GetQueriedEarningWaysPublic({query})
        if(response) earningWays = response.data[0];
        location = {lat: brandData.location.lat, lng: brandData.location.lng, address: brandData.location.address}
        return error;
    }

    const getGrowthtool = async (growthToolType, brandId) => { 
        const growthtoolId = params.growthtoolId
        let query
        if(!growthToolType || growthToolType === GrowthTools.GrowhttoolTypes.QR_CODE || growthToolType === GrowthTools.GrowhttoolTypes.POS) query = {_id: growthtoolId}
        else{
            const campaignData = await CampaignService.GetQueriedCampaign({query:{brandId: brandId, campaignType: 5}})
            query = {campaignId: campaignData.response.data[0]._id, growthtoolType: (growthToolType == GrowthTools.GrowhttoolTypes.QR_CODE || growthToolType == GrowthTools.GrowhttoolTypes.LANDING_PAGE) ? GrowthTools.GrowhttoolTypes.LANDING_PAGE : growthToolType , brandId: brandId}
        }
        const {error, response} = await GrowthtoolService.GetPublicGrowthTool({query})
        let flowId = []
        if(response) { flowId = (!growthToolType || growthToolType === GrowthTools.GrowhttoolTypes.QR_CODE || growthToolType === GrowthTools.GrowhttoolTypes.POS) ? response.data.flowId : response.data[0].flowId }

        if(response && (growthToolType === GrowthTools.GrowhttoolTypes.MANYCHAT || growthToolType === GrowthTools.GrowhttoolTypes.API || growthToolType === GrowthTools.GrowhttoolTypes.APP || growthToolType === GrowthTools.GrowhttoolTypes.UCHAT || growthToolType === GrowthTools.GrowhttoolTypes.QR_CODE || growthToolType === GrowthTools.GrowhttoolTypes.POS )) {
            return flowId;
        }
        query = {_id : flowId[0]}
        const flow = await FlowService.Get({query});
        if(flow.response) setRedemptionType(flow.response.data[0].redemptionType)

        if(response && !growthToolType) return setState({...state, loader: false, growthtool: response.data})
        return setState({...state, loader: false, apiError: 'no landing page found'})

    }

    const getFormJSON = (parent) => {
        const elements = parent.childrens.map(child=>{
            if(child.submitAction) return child
            if(child.childrens) return getFormJSON(child)
        })


        return elements.find(value=>value)
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        const customFields = []
        document.querySelectorAll('input[data-field-id]').forEach(element => {
            customFields.push({
                customFieldId : element.getAttribute('data-field-id'),
                value         : element.value,
                key           : element.name
            })
        })

        const submitBtn = document.querySelector('button[type="submit"]')

        const loader = `<div class="loaders">
            <div class="loader circle-loader-1"></div>
        </div>`
        submitBtn.innerHTML = loader
        submitBtn.classList.add('submit')
        const formField = new FormData(e.target)
        
        const values    = {}
        for (const fields of formField) if(!customFields.some(field => field.key === fields[0])) values[fields[0]] = fields[1]
        
        let email = await localforage.getItem('email');
        if(!email) localforage.setItem('email', values.email);
        if(email && (email !== values.email)){
            localforage.removeItem('deals');
            localforage.removeItem('flowIds');
            localforage.setItem('email', values.email);
        }

        if(customFields.length) values.customFields = customFields
        const submitAction = getFormJSON(state.growthtool.landingPage?state.growthtool.landingPage.json:state.growthtool.pos.json).submitAction
        const result = await submitFunctions[submitAction]({formValues: values, brandData, campaign: campaign, growthToolType: state.growthtool.growthtoolType})
        
        
        
        if(!result || result.error){
            submitBtn.classList.remove('submit')
            submitBtn.innerHTML = 'Continue'
            return
        }
        
        if(brandData.appleWalletShow && (new MobileDetect(window.navigator.userAgent).os() === 'iOS')) {
            submitBtn.classList.remove('submit')
            submitBtn.innerHTML = 'Continue'
            document.getElementById('LandingPage').innerHTML = `<div style='padding: 20px 20px; text-align: center;' class="mt_24 Heading22R2M color-GeneralBlack text-align-center" style={{width: '270px'}}>
            Thanks for connecting with our Business 🥳  <br/>  <br/>  <div class="mt_24 Body14R color-neutral60 text-align-center">Please wait while we are creating your wallet pass</div> </div>
            `
            document.getElementById('LandingPage').style.marginTop = '200px'
            window.location.href = `${env.API_URL}/v4/pass/pkpass?brandId=${result.response.data.brandId}&passCode=${result.response.data.passCode}`
            return;
        }
        else if(brandData.appleWalletShow && (new MobileDetect(window.navigator.userAgent).os() === 'AndroidOS')) {
            submitBtn.classList.remove('submit')
            submitBtn.innerHTML = 'Continue'
            document.getElementById('LandingPage').innerHTML = `<div style='padding: 20px 20px; text-align: center;' class="mt_24 Heading22R2M color-GeneralBlack text-align-center" style={{width: '270px'}}>
            Thanks for connecting with our Business 🥳  <br/>  <br/>  <div class="mt_24 Body14R color-neutral60 text-align-center">Please wait while we are creating your wallet pass</div> </div>
            `
            document.getElementById('LandingPage').style.marginTop = '200px'
            window.location.href = `${env.API_URL}/v4/services/google/loyaltCard/${result.response.data._id}/${result.response.data.passDesignId}/${result.response.data.campaignId}`
            return;
        }
        

        if(result.response) localforage.setItem('pass', result.response.data) 
        if(result.response) localforage.setItem('username', user.name) 


        
        const userData = await UserService.GetByEmail({query:{_id: result.response.data.userId}})
        let pathName = ''
        // if(userData&&userData.response&&userData.response.data.phone) 
        pathName = '/page/'+params.growthtoolId+'/listing'
        // else pathName = '/page/'+params.growthtoolId+'/contact'
        const flowIdArr = await getGrowthtool(result.response.data.growthToolType, brandData._id);
        let flow
        if(flowIdArr&&flowIdArr.length>0){
            flow = (await FlowService.Get({query: {_id: flowIdArr[0]}})).response.data[0]
        }


       
        
            history.push({pathname: pathName, state: {
                earningWays    : earningWays,
                result         : result.response.data,
                brand          : brandData,
                tiers          : tiers,
                disable        : disable,
                flowIdArray    : flowIdArr,
                flow           : flow&&flow,
                redemptionType : redemptionType,
                payload        : { firstName : user.name ,agencyId : agency._id, brandId: brandData._id, passId: result.response.data._id, location : location},
                headers        : {'x-api-key': brandData.apiKey},
                growthTool     : state.growthtool
            }});
        
    }

    const htmlLoaded = async () => {
        setGid(params.growthtoolId)
        localforage.setItem('growthoolId', params.growthtoolId);
        if(!state.growthtool) return
        setState({...state, loader: true});
        const formJSON = getFormJSON(state.growthtool.landingPage ? state.growthtool.landingPage.json : state.growthtool.pos.json)
        const form     = document.getElementById(formJSON.submitAction)

        



        !campaign && await getCampaign(state.growthtool.campaignId);
        !brand && await getBrand(state.growthtool.brandId);
        await getEarningWays();
        form.onsubmit = handleFormSubmit
        
        localforage.removeItem('deals');
        localforage.removeItem('flowIds');
        let pass = await localforage.getItem('pass');
        const username = await localforage.getItem('username');
        const growthToolId = await localforage.getItem('growthoolId');
        if(pass && username && pass.brandId === state.growthtool.brandId  && growthToolId && gId === growthToolId)
        {   
            const {error, response} = await PassService.GetPublicPass({query : {_id: pass._id}});
            if(response) {
                localforage.setItem('pass',response.data[0]);
                pass = response.data[0]
            }

            let flowIdArr = await getGrowthtool(pass.growthToolType, brandData._id);
            let flow
            if(flowIdArr&&flowIdArr.length>0){
                flow = (await FlowService.Get({query: {_id: flowIdArr[0]}})).response.data[0]
            }
          
            history.push({pathname: '/page/'+params.growthtoolId+'/listing', state: {
                earningWays    : earningWays,
                result         : pass,
                brand          : brandData,
                tiers          : tiers,
                disable        : disable,
                flowIdArray    : flowIdArr,
                flow           : flow&&flow,
                redemptionType : redemptionType,
                payload        : { firstName : username , agencyId : agency._id, brandId: brandData._id, passId: pass._id, location : location},
                headers        : {'x-api-key': brandData.apiKey},
                growthTool     : state.growthtool
            }});

        }
        
        setState({...state, loader: false});
    }
    

    const onLoad = () => {
        growthToolId = params.growthtoolId
        if(env.ENVIRONMENT === 'production') window.location.href = 'https://catchly.io/page/' + params.growthtoolId
        // if(env.ENVIRONMENT === 'production') window.location.href = 'https://yonga.app/page/' + params.growthtoolId
        getGrowthtool()
    }

    useEffect(onLoad, [])
    useEffect(htmlLoaded, [state.growthtool])

    return (
        <div id="LandingPage">
            {state.loader && <Loader width={300} height={300} />}
            {state.apiError && !state.growthtool && <div className="apiError"><div>{state.apiError}</div></div>}
            {state.growthtool && (state.growthtool.growthtoolType===GrowthTools.GrowhttoolTypes.POS ? <div dangerouslySetInnerHTML={{__html: state.growthtool.pos.html}}></div> : <div dangerouslySetInnerHTML={{__html: state.growthtool.landingPage.html}}></div>)}
        </div>
    )
}

export default LandingPage
